<template>
  <div class="input-base-email">
    <InputBase
      v-maskito="options"
      :model-value="modelValue"
      v-bind="$attrs"
      @input="onInput"
    />
  </div>
</template>

<script setup>
import { maskito as vMaskito } from '@maskito/vue';
import {
  maskitoAddOnFocusPlugin,
  maskitoPrefixPostprocessorGenerator,
  maskitoRemoveOnBlurPlugin,
} from '@maskito/kit';

import InputBase from './InputBase.vue';

const PREFIX = 'https://';
const LINK = /^(https):\/\//;

defineProps({
  modelValue: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue']);

const onInput = (event) => {
  if (!event.target.value) {
    emit('update:modelValue', PREFIX);
  } else {
    emit('update:modelValue', event.target.value);
  }
}

const options = {
  mask: LINK,
  postprocessors: [ maskitoPrefixPostprocessorGenerator(PREFIX) ],
  plugins: [maskitoAddOnFocusPlugin(PREFIX), maskitoRemoveOnBlurPlugin(PREFIX)]
};

</script>

<style lang="scss">
.input-base-email {
  position: relative;
  width: 100%;
}
</style>
