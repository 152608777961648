import { api } from "@/app/api"

const handleError = async (promise) => {
  try {
    const { data } = await promise
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getMyOrders = async () => handleError(api.get('/v1/orders/my'))

export const getMyClosedOrders = async () => handleError(api.get('/v1/orders/my/closed'))
export const getIncome = async () => {
  try {
    const { data } = (await api.get('/v1/orders/offers'))
    return data
  } catch (error) {

    console.error(error)
  }
}

export const deleteOrder = async ({ id, rating = 0, review = '' }) => {
  try {
    const { data } = (await api.delete('/v1/orders', {
      data: {
        id,
        rating,
        review,
      }
    }))
    return data
  } catch (error) {

    console.error(error)
  }
}
