
import { api } from '@/app/api'

export const fetchChats = async () => {
  try {
    const { data } = (await api.get('/v1/chat'))
    return data
  } catch (error) {

    console.error(error)
  }
}

export const getUser = async () => {
  try {
    const { data } = (await api.get('/v1/profile'))
    return { user: data.profile }
  } catch (error) {
    console.error(error)
  }
}
