<template>
  <HeaderFullpage :title="t('animals.title')" />
  <PageLoader v-if="isLoading" />
  <LayoutBase v-else>
    <div
      v-if="animals.length === 0"
      class="info"
    >
      {{ t('animals.null') }}
    </div>

    <AddEntity to="/pet-create" />

    <div class="pet-list">
      <router-link
        v-for="item in animals"
        data-test-id="petCreate"
        :to="'/animal/' + item.id"
        class="pet"
      >
        <IconBase
          :path="`animals/${getAnimalById(item?.animal_id).icon}.svg`"
          class="pet__img"
        />
        <div class="body">
          <div class="title">
            {{ item.payload?.name }}
          </div>
          <div class="subtitle">
            {{ item.payload?.breed }}
          </div>
        </div>
        <img
          src="@/assets/icons/other/context.svg"
          class="context"
          @click.prevent="openContextAnimal(item.id)"
        >
      </router-link>
    </div>
  </LayoutBase>

  <ContextPane
    ref="ContextPaneREF"
    :actions="['edit', 'delete']"
    @on-delete="onDelete"
    @on-edit="onEdit"
  />
</template>

<script setup>
import { HeaderFullpage, IconBase } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { ContextPane } from '@/components/ContextPane'
import { onMounted, ref, } from 'vue';
import { getAnimals } from './api'
import { useMainEntities } from '@/states/mainEntities'
import { PageLoader } from '@/components/PageLoader'
import { deleteAnimal } from './api';
import { successToast, errorToast } from '@/helpers/showToast'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useConfirm } from '@/composables/useConfirm'
import AddEntity from '@/components/AddEntity.vue';

const { t } = useI18n()

const router = useRouter()

const isLoading = ref(true)
const mainEntities = useMainEntities()
const animals = ref([])
const ContextPaneREF = ref()

const getAnimalById = (id) => mainEntities.animalTypes.find(el => el.id === id)

const init = async () => {
  animals.value = (await getAnimals()).animals
  console.log(animals.value)
  isLoading.value = false
}

const openContextAnimal = (id) => {
  ContextPaneREF.value.open({ id })
}

const onEdit = (id) => {
  router.push(`/animal-edit/${ id}`)
}

const { onConfirm } = useConfirm();
const onDelete = async (id) => {
  const response = await onConfirm({ title: t('animal.toast.removeConfirm') })

  if (response) {
    const { success } = await deleteAnimal({ id })
    if (success) {
      successToast({ description: t('animal.toast.removeSuccess') })

    } else {
      errorToast({ description: t('toast.somethingWrong') })
    }

    animals.value = animals.value.filter(el => el.id !== id)
  }
}

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .pet {

        background: #37363C;
    }

    .pet__img {
        background: #292929;
    }

    .title {
        color: #fff
    }

    .subtitle {
        color: #9A9EB9;
    }
}

.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.pet-list {
    display: grid;
    gap: 10px;
}

.info {
    color: #C6C8DA;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}

.add-pet {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 11;
    border-radius: 50%;
    box-shadow: 0px 5px 20px 0px rgba(70, 85, 196, 0.70);
}

.pet {
    &__img {
        padding: 10px;
        border-radius: 20px;
        background: #F2F8FF;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
    }

    border-radius: 26px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 10px;
}

.title {
    color: #5B5F7C;
    font-size: 15px;
    font-weight: 700;
    line-height: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subtitle {
    color: #8E92AF;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.body {
    display: grid;
    gap: 8px;
}
</style>../../components/ContextPane
