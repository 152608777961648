<template>
  <ButtonBase
    danger
    @click="onDelete"
  >
    {{ t('common.delete') }}
  </ButtonBase>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

import { ButtonBase } from '@/UI';
import { useConfirm } from '@/composables/useConfirm';

const emit = defineEmits(['confirm']);

const { t } = useI18n()
const { onConfirm } = useConfirm();
const onDelete = async () => {
  const response = await onConfirm({ title: t('toast.deleteConfirm') })

  if (response) {
    emit('confirm');
  }
}
</script>
