<template>
  <div
    v-bind="$attrs"
    ref="element"
  >
    <SpinerBase
      v-show="isLoading"
      class="spiner"
    />
    <img
      v-show="!isLoading"
      class="img"
      :class="{ rounded: props.rounded }"
      :src="srcImg"
      @load="onload"
    >
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { SpinerBase } from '@/UI'

const element = ref(null);
const isLoading = ref(true);
const observer = ref(null);
const intersected = ref(false);

const onload = () => {
  isLoading.value = false
}

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  rounded: {
    type: Boolean,
    default: false
  }
})

const srcImg = computed(() => intersected.value ? props.src : '')

const init = () => {
  observer.value = new IntersectionObserver(entries => {
    const image = entries[0];
    if (image.isIntersecting) {
      intersected.value = true;
      observer.value.disconnect();
    }
  });

  observer.value.observe(element.value);
}

onUnmounted(() => {
  observer.value.disconnect()
})

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
.spiner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.rounded {
        border-radius: 50%;
    }
}
</style>
