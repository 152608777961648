import { defineStore } from "pinia";

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    phoneNumber: '',
    delay: 0,
    token: '',
    step: 0,
    timeout: 0,
    authType: 0
  }),
})
