<template>
  <Teleport to="body">
    <div
      :class="props.id"
      class="border-radius-fix"
    >
      <slot v-if="isInit" />
    </div>
  </Teleport>
</template>

<script setup>
import { onMounted, ref, onBeforeUnmount } from "vue";
import { CupertinoPane } from "cupertino-pane";
import { useDevice } from '@/composables/useDevice';

const { isMobileDevice } = useDevice();

const isInit = ref(false)

let popupController

const props = defineProps({
  id: {
    type: String,
    default: 'cupertinoUI'
  },
  settings: {
    type: Object,
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fitContent: {
    type: Boolean,
    default: false,
  },
  persist: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['close']);

let myPane
onMounted(() => {
  myPane = new CupertinoPane((`.${ props.id}`),
    {
      modal: !isMobileDevice.value,
      backdrop: true,
      initialBreak: "top",
      fitScreenHeight: false,
      cssClass: `cupertinoUI ${ isMobileDevice.value ? '' : 'is-desktop'}`,
      lowerThanBottom: false,
      topperOverflow: true,
      fitHeight: props.fitContent,
      preventClicks: isMobileDevice.value,
      bottomClose: false,
      fastSwipeClose: false,
      showDraggable: false,
      buttonDestroy: false,
      maxFitHeight: window.innerHeight * 0.9,

      breaks: {
        top: {
          enabled: true, height: window.innerHeight * 0.8,
        },
        middle: {
          enabled: false, height: 200,
        },
        bottom: {
          enabled: false, height: 100,
        },
      },
      ...props.settings
    })

  myPane.on('onDidDismiss', () => {
    emit('close');
  });
  myPane.on('onBackdropTap', () => {
    if (props.persist) {return}
    emit('close');

    popupController.resolve(false)
    myPane.destroy({ animate: true });
  });
  myPane.on('onWillPresent', () => {
    if (props.disabled) {
      setTimeout(() => {
        myPane.disableDrag()
      }, 0)
    }
  });

})

onBeforeUnmount(() => {
  if (myPane) {
    myPane.destroy({ animate: false });
  }
  // emit('close');
})

const open = async () => {
  await new Promise(res => setTimeout(res, 0))
  isInit.value = true

  let resolve;
  let reject;
  const popupPromise = new Promise((ok, fail) => {
    resolve = ok;
    reject = fail;
  });
  popupController = { resolve, reject };
  myPane.present({ animate: true });
  return popupPromise;
}

defineExpose({
  open,
  close: () => {
    if (myPane) {
      myPane.destroy({ animate: true });
    }
    return popupController.resolve(false)
  }
})
</script>

<style lang="scss">
.cupertinoUI {
  z-index: 150;
}

.is-desktop >.pane {
  width: 500px !important;
  max-width: 500px !important;
  margin: auto !important;
}
</style>

