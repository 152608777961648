<template>
  <HeaderFullpage
    desctop
    :title="t('accountView.title')"
  />
  <PageLoader v-if="isLoading" />

  <LayoutBase
    v-else
    padding="20px"
  >
    <template v-if="status === 'notFound'">
      <div class="title mt">
        {{ t('accountView.null') }}
      </div>
    </template>
    <template v-else-if="status === 'notApproved'">
      <div class="title mt">
        {{ t('accountView.moder') }}
      </div>
    </template>
    <template v-else>
      <div class="header-info">
        <div class="stars hide">
          <img src="@/assets/icons/other/star.svg"> -
        </div>

        <div v-if="myCoords && account.coords" class="distance">
          <img src="@/assets/icons/other/distance.svg">
          {{ calculatedDistance }} {{ t('common.km') }}
        </div>
      </div>
      <div class="title">
        {{ account?.name }}
      </div>
      <div class="subtitle">
        {{ category?.name }}
      </div>

      <div class="action__wrapper">
        <a
          v-if="account.tel"
          class="action"
          :href="'tel:' + account.tel"
        >
          <img
            src="@/assets/icons/other/phone.svg"
            class="action__img"
          >
        </a>
        <button v-if="!isMyAccount" class="action">
          <img
            src="@/assets/icons/other/chat.svg"
            class="action__img"
            @click="handleChatOpen"
          >
        </button>
        <button
          v-if="isAuthenticated"
          class="action"
          :class="{ favorite: account.isFavorite }"
          @click="addFavorite(account.isFavorite)"
        >
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            class="action__img"
          >
            <path
              d="M19.3 5.71002C18.841 5.24601 18.2943 4.87797 17.6917 4.62731C17.0891 4.37666 16.4426 4.2484 15.79 4.25002C15.1373 4.2484 14.4909 4.37666 13.8883 4.62731C13.2857 4.87797 12.739 5.24601 12.28 5.71002L12 6.00002L11.72 5.72001C10.7917 4.79182 9.53273 4.27037 8.22 4.27037C6.90726 4.27037 5.64829 4.79182 4.72 5.72001C3.80386 6.65466 3.29071 7.91125 3.29071 9.22002C3.29071 10.5288 3.80386 11.7854 4.72 12.72L11.49 19.51C11.6306 19.6505 11.8212 19.7294 12.02 19.7294C12.2187 19.7294 12.4094 19.6505 12.55 19.51L19.32 12.72C20.2365 11.7823 20.7479 10.5221 20.7442 9.21092C20.7405 7.89973 20.2218 6.64248 19.3 5.71002Z"
              fill="currentColor"
            />
          </svg>
        </button>
        <button
          class="action"
          @click="handleCopy"
        >
          <img
            src="@/assets/icons/other/share.svg"
            class="action__img"
          >
        </button>

        <!-- <router-link v-if="isARAvailable" :to="`/order/${account.id}/ar`">
          <button
            class="action"
            style="font-weight: 800;"
          >
            AR
          </button>
        </router-link> -->
      </div>
      <div class="address">
        {{ account.address }}
      </div>

      <KeepAlive>
        <ReviewsComponent v-if="viewOfpage === 'reviews'" />
      </KeepAlive>

      <template v-if="viewOfpage === 'info'">
        <div class="gap">
          <template v-if="category.id === 26">
            <ItemBase
              v-if="account.reason"
              :title="t('accountView.ph.reason')"
            >
              <TextareaBase
                fit-content
                :model-value="account.reason"
                disable
              />
            </ItemBase>
            <ItemBase
              v-if="account.animalName"
              :title="t('accountView.ph.petName')"
            >
              <InputSimple
                :model-value="account.animalName"
                disable
              />
            </ItemBase>
            <ItemBase
              v-if="account.pet.payload.breed"
              :title="t('accountView.ph.breed')"
            >
              <InputSimple
                :model-value="account.pet.payload.breed"
                disable
              />
            </ItemBase>

            <ItemBase
              v-if="account.pet?.payload?.animal_id"
              :title="t('accountView.ph.petType')"
            >
              <AnimalTypeSelector
                disabled
                :model-value="account.pet.payload.animal_id"
              />
            </ItemBase>
          </template>
          <template v-else>
            <!-- TODO vet-57 -->
            <tabs-base
              v-if="computedTabs.length > 1"
              v-model="activeTab"
              :options="computedTabs"
              class="account__tabs"
            />

            <keep-alive>
              <General
                v-if="activeTab === 0"
                :account="account"
                :short-days="shortDays"
                :products="products"
                :services="services"
                @show-more="value => activeTab = value"
              />
              <!-- TODO vet-57 -->
              <ShowcasesList
                v-else-if="activeTab === 1"
                :account-id="ID"
                :items="products"
              />
              <ShowcasesList
                v-else-if="activeTab === 2"
                :account-id="ID"
                :items="services"
              />
              <ShowcasesList
                v-else-if="activeTab === 3"
                :account-id="ID"
                :items="prices"
              />
            </keep-alive>

            <div
              v-if="
                account.tel ||
                  account.email ||
                  account.whatsApp ||
                  account.telegram ||
                  account.web
              "
              class="contacts"
            >
              <a
                v-if="account.tel"
                class="contacts__item"
                :href="'tel:' + account.tel"
              >
                <img src="@/assets/icons/contacts/phone.svg">
                {{ account.tel }}
              </a>
              <a
                v-if="account.email"
                class="contacts__item"
                :href="'mailto:' + account.email"
              >
                <img src="@/assets/icons/contacts/mail.svg">
                {{ account.email }}
              </a>
              <a
                v-if="account.whatsApp"
                class="contacts__item"
                :href="'https://wa.me/' + account.whatsApp"
                target="_blank"
              >
                <img src="@/assets/icons/contacts/wa.svg">
                {{ account.whatsApp }}
              </a>
              <a
                v-if="account.telegram"
                class="contacts__item"
                :href="
                  'https://t.me/' +
                    account.telegram.replace('https://t.me/', '').replace('@', '')
                "
                target="_blank"
              >
                <img src="@/assets/icons/contacts/tg.svg">
                @{{
                  account.telegram.replace('https://t.me/', '').replace('@', '')
                }}
              </a>
              <a
                v-if="
                  account.web.replace('https://', '').replace('http://', '')
                "
                class="contacts__item"
                :href="account.web"
                target="_blank"
              >
                <img src="@/assets/icons/contacts/web.svg">
                {{ account.web }}
              </a>
            </div>
          </template>
        </div>
      </template>
    </template>
  </LayoutBase>
  <FollowElements>
    <BackButton />
  </FollowElements>

  <CreateChatPane
    :id="account.account_id"
    ref="CreateChatPaneREF"
  />
</template>

<script setup>
import { HeaderFullpage } from '@/UI'
import { ReviewsComponent } from './components/ReviewsComponent'
import { computed, ref, reactive, watch } from 'vue'
import { LayoutBase } from '@/components/Layouts'
import { FollowElements } from '@/components/PaneMain'
import {
  BackButton,
  TextareaBase,
  ItemBase,
  InputSimple,
  TabsBase,
} from '@/UI'
import { getAccount, addToFavorite, removeFromFavorite, getOrder } from './api'
import { useRoute } from 'vue-router'
import { PageLoader } from '@/components/PageLoader'
import { CreateChatPane } from '@/components/CreateChatPane'
import { useMainEntities } from '@/states/mainEntities'
import { daysOptions } from './contants'
import ShowcasesApi from '@/screens/Account/api/showcases.api'
import { successToast, errorToast } from '@/helpers/showToast'
import { share } from '@/helpers/share'
import { AnimalTypeSelector } from '@/components/AnimalTypeSelector'
import { myCoords } from '@/helpers/getCurrentPosition'
import { getDistance } from '@/helpers'
import { useI18n } from 'vue-i18n'
import { useMapStore } from '@/states/mapStore'

import General from './views/General'
import ShowcasesList from './views/ShowcasesList.vue'

import { useUser } from '@/composables/useUser';

const activeTab = ref(0)

const mapStore = useMapStore()
const { t } = useI18n()

const { isAuthenticated } = useUser();
const viewOfpage = ref('info')

const CreateChatPaneREF = ref()
const status = ref('')

const mainEntities = useMainEntities()

const account = reactive({})
const isLoading = ref(true)

const route = useRoute()
const idPage = computed(() => route.params?.id)

const showcases = ref([]);
const products = ref([]);
const services = ref([]);
const prices = ref([]);

const isMyAccount = Object.keys(route.query).includes('isMy')
const ID = computed(() => isMyAccount ? route.params.id : account.account_id)

const load = async () => {
  const result = await ShowcasesApi.getAll(ID.value, { limit: 10 });

  showcases.value = result.data.showcases;
  products.value = result.products
  services.value = result.services;
  prices.value = result.prices;
}

const computedTabs = computed(() => {
  const tabs = [
    { label: t('showcase.review'), value: 0 },
  ]

  if (products.value?.length) {
    tabs.push({ label: t('showcase.products.title'), value: 1 })
  }

  if (services.value?.length) {
    tabs.push({ label: t('showcase.services.title'), value: 2 })
  }
  if (prices.value?.length) {
    tabs.push({ label: t('showcase.prices.title'), value: 3 })
  }

  return tabs
})

const calculatedDistance = ref('');

const init = async () => {
  isLoading.value = true
  status.value = ''
  const id = idPage.value
  if (!id) {return}

  if (isMyAccount) {
    const { accounts } = await getAccount({ id })
    Object.assign(account, accounts[0])
    calculatedDistance.value = getDistance(account.coords, myCoords.value)
  } else {
    //с токеном и без
    const { orders = [] } = await getOrder({ id })

    if (orders.length === 0) {
      status.value = 'notFound'
      return ;
    }
    isLoading.value = false

    Object.assign(account, {
      ...orders[0]?.payload,
      isFavorite: orders[0]?.is_favourite,
      id: orders[0].id,
      account_id: orders[0].account_id,
      categoryId: orders[0]?.category_id,
      icon: orders[0]?.icon,
      account_type_id: orders[0]?.type_id,
    })
    status.value = account.closed ? 'notApproved' : ''
    calculatedDistance.value = getDistance(account.coords, myCoords.value)
    return;
  }

  isLoading.value = false
}

const category = computed(() =>
  mainEntities.category.find((el) => el.id === account.categoryId),
)

const shortDays = computed(() =>
  daysOptions.value
    .filter((el) => account.shortDay?.includes(el.value))
    .map((el) => el.label)
    .join(', '),
)

const handleChatOpen = () => {
  CreateChatPaneREF.value.open({ id: 3 })
}

const addFavorite = async (isFavorite) => {
  if (!isFavorite) {
    const { success } = await addToFavorite({ id: account.id })
    if (success) {
      successToast({ description: t('accountView.toast.add') })
      account.isFavorite = true
    } else {
      errorToast({ description: t('accountView.toast.error') })
    }
  } else {
    const { success } = await removeFromFavorite({ id: account.id })
    if (success) {
      successToast({ description: t('accountView.toast.remove') })
      account.isFavorite = false
    } else {
      errorToast({ description: t('accountView.toast.error') })
    }
  }
}

const handleCopy = () => {
  const id = account.order_id || account.id
  share({
    title: account.name,
    description: category.value.name,
    url: `${import.meta.env.VITE_SHARE_URL }/order/${id}`
  })
}

const resetData = () => {
  activeTab.value = 0;
  showcases.value = [];
  products.value = [];
  services.value = [];
  account.value = {};
}

watch(idPage, async() => {
  resetData()
  await init()
  if (account?.coords?.length) {
    mapStore.$patch({ openedPlace: account?.coords })
  }
  load()
}, { immediate: true })

const isARAvailable = computed(() => import.meta.env.VITE_VETMAP_AR === 'true' && calculatedDistance.value < 9.85);
</script>

<style lang="scss" scoped>
[theme='dark'] {
  .action {
    background: #37363c;
    border: 1px solid #37363c;
  }

  .distance {
    color: #b0b2c2;
  }

  .title {
    color: #fff;
  }

  .subtitle {
    color: #b0b2c2;
  }

  .itemWrapper {
    background: #37363c;
    border: 1px solid #37363c;
    color: #fff;
  }

  .info__item {
    color: #fff;
  }

  .contacts {
    background: #37363c;

    &__item {
      color: #fff;

      &:not(:last-child) {
        border-bottom: 1px solid #56555c !important;
      }
    }
  }

  .head {
    background: var(--cupertino-pane-background-dark);

    &.top {
      box-shadow: 0 2px 8px #1a1a1a;
    }
  }
}

.header-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0 30px;
}

.hide {
  visibility: hidden;
}

.block {
  display: grid;
  gap: 10px;
}

.gap {
  display: grid;
  gap: 30px;
}

.itemWrapper {
  border-radius: 20px;
  border: 1px solid #e8eafc;
  background: #fff;
  padding: 20px;
}

.mt {
  margin-top: 20px;
}

.stars,
.distance {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 5px;
  justify-content: center;
  color: var(--grey-text);
}

.title {
  text-align: center;
  font-size: 22px;
  font-weight: 700;

  margin-bottom: 10px;
  transition: all 0.3s;

  &.top {
    transform: scale(1.2) translateY(-20px);
  }
}

.subtitle {
  color: #8e92af;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  transition: all 0.3s;

  &.top {
    transform: scale(1.2) translateY(-20px);
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid #e8eafc;
  background: #fff;
  width: 60px;
  height: 60px;
  color: #5b43ef;

  &.favorite {
    color: red;
  }
}

.action__wrapper {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.address {
  color: #8e92af;
  font-size: 14px;
  font-weight: 500;

  text-align: center;
  margin: 20px;
}

.tabs {
  margin-bottom: 30px;
}

.schedule {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  font-size: 15px;
  font-weight: 600;
}

.info {
  display: grid;
  gap: 20px;

  &__item {
    color: #6b7090;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
  }
}

.contacts {
  border-radius: 20px 20px 0px 0px;
  background: #fff;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.05);
  margin: -20px;
  margin-top: 0;
  padding: 0 40px;
  max-width: 100vw;

  &__item {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 30px 0;
    color: #8e92af;
    font-size: 14px;
    font-family: NunitoSemiBold;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:last-child) {
      border-bottom: 1px solid #e6eaf3;
    }
  }
}

.head {
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  margin-left: -20px;
  border-radius: var(--cupertino-pane-border-radius)
    var(--cupertino-pane-border-radius) 0 0;

  display: grid;
  justify-content: space-between;
  grid-auto-flow: column;

  padding: 10px;
  background: var(--cupertino-pane-background-light);
  z-index: 200;

  transition: box-shadow 0.3s;

  box-shadow: none;

  &.top {
    box-shadow: 0 2px 10px #e6e6e6;
  }

  &.desctop {
    position: fixed;
    max-width: 480px;
    top: 74px;
    height: 120px;
    border-radius: 0;
  }
}

.account__tabs {
  margin-bottom: 0;
}

[theme='dark'] {
  .layout-base {
    background-color: var(--cupertino-pane-background-dark);
  }
}

[theme='light'] {
  .layout-base {
    background-color: var(--cupertino-pane-background-light);
  }
}
</style>

<style lang="scss" src="./assets/styles.scss"></style>
