import * as validators from '@vuelidate/validators'

const validationMessages = {
  email: 'Введен неправильный формат e-mail',
  maxValue: 'Максимальное значение поля не должно превышать ',
  minValue: 'Минимальное значение поля должно быть не менее ',
  required: 'Обязательное поле',
  mustEnter: 'Поля обязательны для заполнения',
  onlyLatin: 'Только латинские буквы',
  incorrectFormat: 'Неверный формат',
  minLength: 'Минимальное кол-во символов: ',
  maxLength: 'Максимальная  кол-во символов: ',
  sameAs: ' не совпадают',
  url: 'Пожалуйста, введите корректный URL',
}

export function useValidations() {
  const required = validators.helpers.withMessage(
    () => validationMessages.required,
    validators.required,
  )

  const minLength = (value) => validators.helpers.withMessage(
    () => validationMessages.minLength + value,
    validators.minLength(value),
  )

  const maxLength = (value) => validators.helpers.withMessage(
    () => validationMessages.maxLength + value,
    validators.maxLength(value),
  )

  const minValue = (value) => validators.helpers.withMessage(
    () => validationMessages.minValue + value,
    validators.minValue(value),
  )

  const maxValue = (value) => validators.helpers.withMessage(
    () => validationMessages.maxValue + value,
    validators.maxValue(value),
  )

  const email = validators.helpers.withMessage(
    () => validationMessages.email,
    validators.email,
  )

  const url = validators.helpers.withMessage(
    () => 'Пожалуйста, введите корректный URL',
    validators.url,
  )

  const sameAs = (value, field) => validators.helpers.withMessage(
    () => field + validationMessages.sameAs,
    validators.sameAs(value),
  )

  return {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    email,
    sameAs,
    url
  }
}
