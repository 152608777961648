import { ref } from "vue";
import { useDemoMode } from '@/composables/useDemoMode';

export const isConfirmDialogOpen = ref(false);
let resolveFn = () => {};
const options = ref({});

export const useConfirm = () => {
  const { isDemoMode } = useDemoMode();

  const onConfirm = (incomeOptions = {}) => {
    if (isDemoMode.value) {
      return true;
    }
    Object.assign(options.value, incomeOptions)
    isConfirmDialogOpen.value = true;

    return new Promise((resolve) => {
      resolveFn = resolve;
    })
  }

  const handleResponse = (answer) => {
    isConfirmDialogOpen.value = false;
    resolveFn(answer)
    setTimeout(() => {
      options.value = {};
    }, 200);
  }

  return { isConfirmDialogOpen, options, onConfirm, handleResponse }
}

