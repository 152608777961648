<template>
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_36_1096)">
      <path
        d="M21.6667 11.7091C21.6667 11.7091 21.6667 11.7694 21.66 11.7962C21.66 11.8231 21.6667 11.8432 21.6667 11.87V11.7091ZM21.0867 6.59517H15C14.2667 6.59517 13.6667 5.99195 13.6667 5.25469V2.68096C13.6667 1.17292 12.4267 -0.0469196 10.9133 -2.72656e-06C10.2133 0.0201045 9.66667 0.630024 9.66667 1.34048V4.02145C9.66667 5.92493 7 7.26541 7 8.6059V17.1783C7 17.6743 7.27333 18.13 7.70667 18.3646C7.94667 18.492 8.23333 18.6461 8.53333 18.807C9.98667 19.5912 11.6133 20 13.26 20H18.42C19.4733 20 20.3333 19.1421 20.3333 18.0764V17.9021C20.3333 17.5804 20.2467 17.2855 20.1067 17.0174C20.64 16.6756 21 16.0791 21 15.3954V14.8861C21 14.3432 20.7733 13.8606 20.42 13.5054C21.12 13.244 21.6267 12.5804 21.66 11.7962C21.64 11.3405 21.4667 10.9249 21.1867 10.6032C22.1933 10.5496 23 9.7185 23 8.69303V8.51877C23 7.45978 22.1467 6.59517 21.0867 6.59517Z"
        fill="white"
      />
      <path
        d="M5 7.26541H0.333333C0.146667 7.26541 0 7.41286 0 7.60053V18.3244C0 18.5121 0.146667 18.6595 0.333333 18.6595H5C5.18667 18.6595 5.33333 18.5121 5.33333 18.3244V7.60053C5.33333 7.41286 5.18667 7.26541 5 7.26541ZM2.66667 16.6488C2.11333 16.6488 1.66667 16.1997 1.66667 15.6434C1.66667 15.0871 2.11333 14.6381 2.66667 14.6381C3.22 14.6381 3.66667 15.0871 3.66667 15.6434C3.66667 16.1997 3.22 16.6488 2.66667 16.6488Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_36_1096">
        <rect
          width="23"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
