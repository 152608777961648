<template>
  <HeaderFullpage
    desctop
    :title="t('cluster.title')"
  />
  <LayoutBase>
    <MenuInput
      ref="input"
      v-model="search"
      class="input-menu"
      :readonly="position === 'bottom'"
      type="text"
      @click="setFocus"
    />

    <div class="orderWrapper">
      <OrderItem
        v-for="item in filteredOrders"
        :id="item.id"
        :info="item"
      />
    </div>
  </LayoutBase>
  <FollowElements>
    <BackButton :fixed="position === 'top'" />
  </FollowElements>
</template>

<script setup>
import { HeaderFullpage } from '@/UI'
import { ref, onMounted, watch, computed } from 'vue'
import { MenuInput } from '@/UI/MenuInput';
import { usePane } from '@/components/PaneMain'
import { LayoutBase } from '@/components/Layouts';
import { FollowElements } from '@/components/PaneMain';
import { BackButton } from '@/UI'
import { OrderItem } from './components/OrderItem'
import { useRoute, useRouter } from 'vue-router'
import { useClusterStore } from './model'
import { fetchOrders } from './api'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const clusterStore = useClusterStore()

const search = ref('')
const route = useRoute()
const router = useRouter()
const fetchedOrders = ref([])

const input = ref()
const { myPane, position } = usePane()
const init = async () => {
  // setFocus()
}

watch(() => clusterStore.ids, async (newValue) => {
  if (position.value === 'bottom') {
    myPane.value.moveToBreak('middle')
  }
  if (!newValue) {
    router.back()
    return
  }
  const { orders } = await fetchOrders(newValue)
  fetchedOrders.value = orders

}, { immediate: true })

const filteredOrders = computed(() => {
  if (!search.value) {return fetchedOrders.value}

  return fetchedOrders.value.filter(el => el.title.toUpperCase().includes(search.value.toUpperCase()))
})

const setFocus = async () => {
  if (position.value === 'bottom') {
    myPane.value.moveToBreak('middle')
    return
  }
  input.value?.focus()
}

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
// .input-menu {
//     position: fixed;
//     right: 0;
//     left: 0;
// }

.query {
    text-align: center;
    color: #6B7090;
    font-size: 17px;
    font-weight: 700;
    margin-top: 20px;
}

.orderWrapper {
    display: grid;
    gap: 10px;
    margin-top: 20px;
}
</style>
