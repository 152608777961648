<template>
  <HeaderFullpage :title="t('chats.title')" />
  <LayoutBase gap>
    <!-- <MenuInput />
        <CategorySelector class="category" /> -->
    <SpinerBase
      v-if="isLoading"
      class="spiner"
    />
    <div
      v-else
      class="chats"
    >
      <ChatItem
        v-for="chat in chats"
        :key="chat.id"
        :my-profile="myProfile"
        :chat="chat"
      />
    </div>
  </LayoutBase>
</template>

<script setup>
import { LayoutBase } from '@/components/Layouts';
import { HeaderFullpage, SpinerBase } from '@/UI';
import { ChatItem } from './components/ChatItem'
import { fetchChats, getUser } from './api'
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const isLoading = ref(true)
const chats = ref([])
const myProfile = ref(null)

const init = async () => {
  const { messages } = await fetchChats()
  const { user } = await getUser()

  myProfile.value = user
  chats.value = (messages)
  isLoading.value = false
}

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
.category {
    margin: 0 -20px;
    padding: 0 20px;
}

.spiner {
    margin: 0 auto;
}

.chats {
    display: grid;
    gap: 10px;
}
</style>
