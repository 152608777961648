<template>
  <div class="menu-wrapper">
    <slot />
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.menu-wrapper {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
</style>
