<template>
  <HeaderFullpage :title="t('showcase.products.createNew')" />
  <PageLoader v-if="isLoading" />
  <LayoutBase
    v-else
    gap="40"
  >
    <ProductsForm
      ref="productsForm"
      :handler="create"
    />

    <SuccessModal
      ref="successModal"
      @retry="productsForm.reset()"
      @close="router.back()"
    />
  </LayoutBase>
</template>

<script setup>
import { ref } from 'vue'
import { HeaderFullpage } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { PageLoader } from '@/components/PageLoader'
import { useRouter, useRoute } from 'vue-router'
import showcasesApi from '@/screens/Account/api/showcases.api'
import { errorToast } from '@/helpers/showToast'
import ProductsForm from '../../workspace/ProductsForm.vue'
import SuccessModal from '../../workspace/SuccessModal.vue'

import { uploadPhoto } from '@/states/api'
import { useI18n } from 'vue-i18n'

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const isLoading = ref(false)
const successModal = ref()
const productsForm = ref()

async function create(payload) {
  if (payload.files.length) {
    const { paths } = await uploadPhoto({ photos: payload.files })
    payload.files = paths.map((el) => el.path)
  }

  showcasesApi
    .create(route.params.id, payload)
    .then((res) => {
      console.log(res)
      if (res.data.success) {
        successModal.value.openModal(t('showcase.products.createSuccess'))
      } else {
        errorToast({
          description: t('toast.somethingWrongLong'),
        })
      }
    })
    .catch((err) => {
      console.log('err', err)
      errorToast({
        description: t('toast.somethingWrongLong'),
      })
    })
}
</script>

<style lang="scss" src="../../assets/styles.scss"></style>
