import { Device } from '@capacitor/device';
import { successToast, errorToast } from '@/helpers/showToast'

import { Share } from '@capacitor/share';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
export const share = async ({ title = '', description = '', url = '' }) => {
  const { platform } = await Device.getInfo();
  if (platform === 'web') {
    if (isMobile) {
      try {
        navigator.share({
          title: `Пожалуйста, посмотрите объявление\n${title} - ${description}, по ссылке:\n\n${url}`,
        })
      } catch (error) {
        console.log(error)
        errorToast({ description: 'Не получилось скопировать ссылку' })
      }
    }
    else {
      try {
        await navigator.clipboard.writeText(url)
        successToast({ description: 'Ссылка скопирована' })
      } catch (error) {
        console.log(error)
        errorToast({ description: 'Не получилось скопировать ссылку' })
      }
    }
    return
  }

  try {
    await Share.share({
      text: `Пожалуйста, посмотрите объявление\n${title} - ${description}, по ссылке:\n\n${url}`,
    })
  } catch (error) {
    console.log(error)
    errorToast({ description: 'Не получилось скопировать ссылку' })
  }

}

