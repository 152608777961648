<template>
  <baidu-map
    class="map"
    :ak="API_KEY"
    v="3.0"
    type="API"
    :center="{ lat: currentLocation[0], lng: currentLocation[1] }"
    :zoom="15"
    has-animation
    scroll-wheel-zoom
    @ready="onReady"
  >
    <bm-navigation v-if="isReady" anchor="BMAP_ANCHOR_TOP_RIGHT" />
    <bm-marker
      v-if="myPosition?.length && isReady"
      :position="{ lat: myPosition[0], lng: myPosition[1] }"
      :icon="{url: pointIcon, size: {width: 60, height: 60}}"
    />
    <template v-if="isReady">
      <template v-for="placemark in processedPlacemarks">
        <bm-marker
          :position="{ lat: placemark.lat, lng: placemark.lon }"
          :icon="{url: placemark.img, size: {width: 60, height: 60} }"
          @click="openPoi(placemark)"
        />
      </template>
    </template>
  </baidu-map>
</template>

<script setup>
import { computed, watch, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { BaiduMap, BmNavigation, BmMarker } from 'vue-baidu-map-3x'
import { useMapStore } from '@/states/mapStore'

import pointIcon from '../assets/baloon.svg';

const API_KEY = import.meta.env.VITE_BAIDU_API_KEY;

const props = defineProps({
  myPosition: {
    type: Array,
    default: () => {},
  },
  placemarks: {
    type: Array,
    default: () => {},
  },
})

const router = useRouter()
const mapStore = useMapStore()

function getIconPath(path) {
  return new URL(`../${path}`, import.meta.url).href
}

const processedPlacemarks = computed(() => props.placemarks.map(placemark => {
  let img = '';
  if (placemark.icon) {
    img = placemark.icon
  }

  if (placemark.offer) {
    img = getIconPath(`assets/offers/${placemark.category_id}.svg`)
  } else {
    img = getIconPath(`assets/categories/${placemark.category_id}.svg`)
  }

  return { ...placemark, img }
}))

const openPoi = (payload) => {
  const isBusinessAccount = !payload.offer;
  router.push(`/${isBusinessAccount ? 'order' : 'offer'}/${payload.id}`)
}

const currentLocation = ref(props.myPosition);
const flyTo = (coords) => {
  try {
    if (coords) {
      currentLocation.value = coords;
    }
  } catch (error) {
    console.error(error)
  }
}

const isReady = ref(false);
const onReady = () => {
  setTimeout(() => {
    isReady.value = true;
  }, 0);
}

const getPosition = () => {
  currentLocation.value = [];
  currentLocation.value = props.myPosition;
}

onMounted(async () => {
  flyTo(mapStore.openedPlace)
})
watch(() => mapStore.openedPlace, (coords) => {
  flyTo(coords)
})
watch(() => props.myPosition, getPosition, { immediate: true })

</script>

<style lang="scss" scoped>

:deep(.BMap_noprint.anchorTR) {
  top: 40% !important;
}
</style>
