import { api } from "@/app/api"

export const getAnimalInfo = async ({ id }) => {
  try {
    const { data } = (await api.get(`/v1/animals/${ id}`))
    return data
  } catch (error) {

    console.error(error)
  }
}
