<template>
  <HeaderFullpage :title="t('showcase.title')" />
  <PageLoader v-if="isLoading" />
  <LayoutBase
    v-else
    gap="40"
  >
    <div
      v-if="!userStore.showcases.length && isShowcasesLoading === false"
      class="notfound"
    >
      <h1 class="notfound__title">
        {{ t('showcase.empty') }} <br>
        {{ t('showcase.startToAdd') }}
      </h1>
    </div>

    <template v-else>
      <div
        v-if="userStore.products.length"
        class="showcase__group__container"
      >
        <h2 class="showcase__group__title">
          {{ t('showcase.products.title') }}
        </h2>
        <showcase-product-card
          v-for="item in userStore.products"
          :key="item.id"
          :to="{
            name: 'account-showcases-products-single-update',
            params: {
              productId: item.id,
            },
          }"
          :item="item"
        />
      </div>

      <div
        v-if="userStore.services.length"
        class="showcase__group__container"
      >
        <h2 class="showcase__group__title">
          {{ t('showcase.services.title') }}
        </h2>
        <showcase-product-card
          v-for="item in userStore.services"
          :key="item.id"
          :item="item"
          :to="{
            name: 'account-showcases-services-single',
            params: {
              serviceId: item.id,
            },
          }"
        />
      </div>

      <div
        v-if="userStore.prices.length"
        class="showcase__group__container"
      >
        <h2 class="showcase__group__title">
          {{ t('showcase.prices.title') }}
        </h2>
        <showcase-product-card
          v-for="item in userStore.prices"
          :key="item.id"
          :item="item"
          :to="{
            name: 'account-showcases-prices-single',
            params: {
              serviceId: item.id,
            },
          }"
        />
      </div>
    </template>

    <app-fab-button
      :items="computedActions"
      container-id="layout-base"
    />
  </LayoutBase>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { HeaderFullpage, AppFabButton } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { PageLoader } from '@/components/PageLoader'
import { useRouter, useRoute } from 'vue-router'
import CartIcon from '@/assets/icons/other/CartIcon.vue'
import LikeIcon from '@/assets/icons/other/LikeIcon.vue'
import MenuBook from '@/assets/icons/other/MenuBook.vue'
import { useUserStore } from '@/app/store/user.store'
import ShowcaseProductCard from '@/components/ShowcaseProductCard/ShowcaseProductCard.vue'
import { useI18n } from 'vue-i18n';
import { onBeforeMount } from 'vue'

const route = useRoute()
const router = useRouter()
const isLoading = ref(false)
const userStore = useUserStore()

const { t } = useI18n()

const computedActions = computed(() => [
  {
    tooltip: t('showcase.products.title'),
    icon: CartIcon,
    action: () =>
      router.push({
        name: 'account-showcases-products-create',
      }),
  },
  {
    tooltip: t('showcase.services.title'),
    icon: LikeIcon,
    action: () => {
      router.push({
        name: 'account-showcases-services-create',
      })
    },
  },
  {
    tooltip: t('showcase.prices.title'),
    icon: MenuBook,
    action: () => {
      router.push({
        name: 'account-showcases-prices-create',
      })
    },
  },
])

const isShowcasesLoading = ref(false);
onMounted(async () => {
  isShowcasesLoading.value = true;
  await userStore.getAllShowcases(route.params.id)
  isShowcasesLoading.value = false;
})

onBeforeMount(() => {
  userStore.clearShowcase();
})
</script>

<style lang="scss">
.notfound {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #c6c8da;
    padding: 30px;
  }
}
</style>
