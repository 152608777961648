import { warningToast } from '@/helpers/showToast'

import metadata from 'libphonenumber-js/min/metadata';
import { maskitoGetCountryFromNumber } from '@maskito/phone';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const isPhoneValid = (number) => {
  const countryCode = maskitoGetCountryFromNumber(number, metadata)
  const isValid = isValidPhoneNumber(number, countryCode)

  return isValid;
}

export const isValide = (validateParams, t) => {
  const errors = []

  validateParams.forEach(({ value, label, rule }) => {
    if (rule === 'isPhone') {
      const isValid = isPhoneValid(value)
      if (!isValid) {
        errors.push({
          type: rule,
          label
        })
      }
    }

    if (!String(value)) {
      errors.push({
        type: 'required',
        label
      })
    }
  })

  const phoneError = errors?.length && errors.find(error => error.type === 'isPhone')
  if (phoneError) {
    warningToast({ description: t('toast.wrongPhoneNumber') })
    return false;
  }

  if (errors.length) {
    const fields = errors.map(({ label }) => label).join(', ').toLowerCase();
    warningToast({ description: t('toast.fieldsAreRequired', { fields }) })
    return false
  }

  return true

}
