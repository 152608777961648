<template>
  <div
    class="pane-selector"
    :class="{ 'disabled': props.disabled }"
    @click="handleClick"
  >
    <template v-if="!props.modelValue.length">
      <div class="title">
        {{ t('component.animalSelector.title') }}
      </div>
      <img
        src="@/assets/icons/other/small-arrow.svg"
        class="arrow"
      >
    </template>
    <div
      v-else
      class="petwrap"
    >
      <div
        v-for="item in choosedPets"
        :key="item.id"
        class="item"
      >
        <IconBase
          :path="`animals/${item.icon}.svg`"
          alt=""
          class="item-img"
        />
        <div class="item-title">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>

  <PanePromise
    v-if="!disabled"
    :id="'myAnimalsall'"
    ref="PanePromiseREF"
  >
    <LayoutBase gap>
      <ItemBase :title="t('component.animalSelector.title')">
        <div class="pane-inner">
          <div
            v-for="item in mainEntities.animalTypes"
            :key="item.id"
            class="item"
            @click="handleToggle({ item })"
          >
            <IconBase
              :path="`animals/${item.icon}.svg`"
              alt=""
              class="item-img"
            />
            <div class="item-title">
              {{ item.name }}
            </div>
            <CheckboxBase :is-active="props.modelValue.includes(item.id)" />
          </div>
        </div>
      </ItemBase>
      <ButtonBase
        class="btn-selector"
        @click="handleClose"
      >
        {{ t('common.choose') }}
      </ButtonBase>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { computed, ref } from 'vue'
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ItemBase, CheckboxBase, ButtonBase, IconBase } from '@/UI'
import { useMainEntities } from '@/states/mainEntities'

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const mainEntities = useMainEntities()

const PanePromiseREF = ref()

const handleClick = () => {
  PanePromiseREF.value.open({})
}

const handleClose = () => {
  PanePromiseREF.value.close()
}

const handleToggle = ({ item }) => {
  if (props.modelValue.includes(item.id)) {
    emit('update:modelValue', (props.modelValue.filter(el => el !== item.id)))
  } else {
    emit('update:modelValue', [...props.modelValue, item.id].sort((a, b) => a - b))
  }
}

const choosedPets = computed(() => mainEntities.animalTypes.filter(el => props.modelValue.includes(el.id)))
</script>

<style lang="scss" scoped>
[theme="dark"] {

  .pane-inner {
    background: #37363C;
    border: 1px solid #37363C;
  }
  .pane-selector {
    background: #37363C;
    border: 1px solid #37363C;
    color: #fff;
  }
  .item-title {
    color: #fff
  }

  .item-subtitle {
    color: #9A9EB9;
  }
}

.petwrap {
    display: grid;
    grid-template-columns: minmax(0, auto);
    gap: 30px;
}

.disabled {
    pointer-events: none;
}

.pane-selector {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 15px 20px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 10px;
}

.title {
    padding: 5px 0;
}

.btn-selector {
    position: sticky;
    bottom: 20px;
}

.pane-inner {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 20px 25px;
    display: grid;
    gap: 40px;
    overflow-y: auto;

}

.item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 20px;

    &-title {
        color: #645C56;
        font-size: 15px;
        font-weight: 600;
        line-height: 14px;
    }

    &-img {
        width: 20px;
        height: 20px;
    }
}

.pane-img {
    width: 30px;
    height: 30px;
}

.arrow {
  transform: rotate(-90deg);
  width: 13px;
}
</style>
