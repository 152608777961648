<template>
  <router-link
    draggable="false"
    :to="'/order/' + info.id"
    class="wrapper"
  >
    <IconBase
      class="img"
      :path="`category/${info.category_id}.svg`"
    />
    <div class="info">
      <div class="title">
        {{ info.title }}
      </div>
      <div class="address">
        {{ info.payload.address }}
      </div>
    </div>
    <div class="additional">
      <div class="stars">
        <!-- <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.1117 5.28193L10.4302 4.59758L8.33744 0.330127C8.28028 0.213287 8.18624 0.118702 8.07008 0.0612089C7.77876 -0.0834504 7.42474 0.037099 7.27908 0.330127L5.18632 4.59758L0.504814 5.28193C0.375745 5.30047 0.25774 5.36167 0.167392 5.45441C0.0581659 5.56733 -0.00202252 5.71924 5.18965e-05 5.87678C0.00212631 6.03431 0.0662938 6.18457 0.178455 6.29454L3.56559 9.61614L2.76536 14.3064C2.74659 14.4155 2.7586 14.5278 2.80001 14.6304C2.84142 14.733 2.91058 14.8218 2.99965 14.8869C3.08872 14.952 3.19414 14.9906 3.30394 14.9985C3.41375 15.0064 3.52355 14.9831 3.6209 14.9314L7.80826 12.717L11.9956 14.9314C12.1099 14.9926 12.2427 15.013 12.3699 14.9908C12.6907 14.9352 12.9065 14.6291 12.8512 14.3064L12.0509 9.61614L15.4381 6.29454C15.5303 6.20367 15.5911 6.08497 15.6095 5.95515C15.6593 5.63059 15.4344 5.33015 15.1117 5.28193V5.28193Z"
                        fill="#FF595E" />
                </svg>
                <span>
                    {{ info.rating }}
                </span> -->
      </div>
      <div
        v-if="currentPosition"
        class="distance"
      >
        {{ getDistance(info.payload.coords, currentPosition) }}
        {{ t('common.km') }}
      </div>
    </div>
  </router-link>
</template>

<script setup>
import { getDistance } from '@/helpers';
import { IconBase } from '@/UI';
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

defineProps({
  info: {
    type: Object,
    required: true
  },
  currentPosition: {
    type: Array || null,
    required: true
  }
})
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .wrapper {
        background: #37363C;
    }

    .img {
        background: #292929;
    }
}

.wrapper {
    border-radius: 26px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 15px;
    align-items: center;
}

.img {
    width: 60px;
    height: 60px;
    background: #F2F8FF;
    border-radius: 20px
}

.info {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
}

.title {
    color: #6B7090;
    font-size: 15px;
    font-weight: 700;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.address {
    color: #9A9EB9;
    font-size: 14px;
    font-weight: 500;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.distance {
    color: #BFBFBF;
    font-size: 14px;
    font-weight: 500;

}

.stars {
    color: #BFBFBF;
    font-size: 14px;
    font-weight: 500;

    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    gap: 8px;
    align-items: center;
}

.additional {
    display: grid;
    gap: 8px;
}
</style>
