import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export const daysOptions = computed(() => {
  const { t } = useI18n()
  return ([
    {
      value: 'Mon',
      label: t('days.mon')
    },
    {
      value: 'Tu',
      label: t('days.tu')
    },
    {
      value: 'Wed',
      label: t('days.wed')
    },
    {
      value: 'Th',
      label: t('days.th')
    },
    {
      value: 'Fri',
      label: t('days.fri')
    },
    {
      value: 'Sat',
      label: t('days.sat')
    },
    {
      value: 'Sun',
      label: t('days.sun')
    },
  ])
})

export const viewOfPageOptions = [
  {
    value: 'info',
    label: 'Информация'
  },
  {
    value: 'reviews',
    label: 'Отзывы'
  },
]
