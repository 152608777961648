<template>
  <HeaderFullpage
    desctop
    :title="t('profile.title')"
  />
  <LayoutBase gap="20">
    <div class="profile">
      {{ t('profile.title') }}
    </div>
    <MenuCardsWrapper>
      <!-- <MenuItem
        to="/accounts"
        :title="t('profile.baccs')"
        image="bussiness"
        background="#ded8ff"
      /> -->
      <MenuItem
        to="/settings"
        :title="t('profile.settings')"
        image="settings"
        background="#ffcfd7"
      />
      <MenuItem
        to="/support"
        :title="t('profile.support')"
        image="support"
        background="#d2d2d2"
      />
      <MenuItem
        :title="t('profile.exit')"
        to="/profile"
        image="exit"
        background="#ffe3ba"
        @click="handleExit"
      />
    </MenuCardsWrapper>
  </LayoutBase>

  <FollowElements hide-drag>
    <BackButton />
  </FollowElements>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import { BackButton, HeaderFullpage } from '@/UI'
import { LayoutBase } from '@/components/Layouts';
import { FollowElements } from '@/components/PaneMain';
import { MenuCardsWrapper, MenuItem } from '@/components/MenuCards';
import { useConfirm } from '@/composables/useConfirm'
import { logout } from '@/app/api/auth';

const { t } = useI18n()

const { onConfirm } = useConfirm();
const handleExit = async () => {
  const response = await onConfirm({ title: t('profile.toast.exitConfirm') })

  if (response) {
    logout();
  }
}
</script>

<style lang="scss" scoped>
.profile {
  text-align: center;
  line-height: 17px;
  font-weight: 500;
  font-size: 20px;
  color: #6b7090;
}
</style>
