<template>
  <div
    ref="mapREF"
    class="map"
  />
</template>

<script setup>
import { onMounted, ref, watch, computed, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useClusterStore } from '@/screens/ClusterView'
import { useMainEntities } from '@/states/mainEntities'
import { useMapStore } from '@/states/mapStore'

const mainEntities = useMainEntities()
const clusterStore = useClusterStore()
const mapStore = useMapStore()
const router = useRouter()

const props = defineProps({
  myPosition: {
    type: Array,
    default: () => [0, 0]
  },
  placemarks: {
    type: Array,
    default: () => []
  }
})

const openOrder = ({ id, offer }, coordinates) => {
  const isBusinessAccount = !offer;
  if (isBusinessAccount) {
    router.push(`/order/${ id}`)
  } else {
    router.push(`/offer/${ id}`)
  }

  map.setLocation({ center: coordinates, duration: 600, zoom: map.zoom + 4 });
}

let layer

const toggleTheme = () => {
  if (!layer) {
    return
  }

  if (mainEntities.isDark) {
    layer.update({ theme: 'dark' })

  } else {
    layer.update({ theme: 'light' })
  }

}

watch(() => mainEntities.isDark, toggleTheme)

const mapREF = ref()
let MapObject
let ClusterModule
let map

const initMap = async () => {
  MapObject = await ymaps3.ready;

  ClusterModule = await ymaps3.import('@yandex/ymaps3-clusterer@0.0.1');

  const { YMapZoomControl } = await ymaps3.import('@yandex/ymaps3-controls@0.0.1');

  map = new MapObject.YMap(mapREF.value, {

    location: { center: [37.62, 55.62], zoom: 10, },
    zoomRange: { min: 5, max: 400 },
    mode: 'vector',

    behaviors: ['drag', 'pinchZoom', 'scrollZoom', 'dblClick', 'magnifier', 'oneFingerZoom', 'mouseRotate', 'mouseTilt', 'pinchRotate', 'panTilt']
  })
  layer = (new MapObject.YMapDefaultSchemeLayer())

  map
    .addChild(layer)
    .addChild(new MapObject.YMapDefaultFeaturesLayer())
    .addChild(new MapObject.YMapFeatureDataSource({ id: 'mainSource' }))
    .addChild(new MapObject.YMapLayer({ source: 'mainSource', type: 'markers', zIndex: 1800 }))
    .addChild(new MapObject.YMapControls({ position: 'right' }).addChild(new YMapZoomControl({})))

  getPosition()
  setPoints()
  toggleTheme()
}

function clusterIcon(count) {
  const circle = document.createElement('div');
  circle.style.transform = 'translate(-50%, -50%)'
  circle.classList.add('circle');
  circle.style.transform = `scale(${(100 + (0.5 / (1 / count)))}%)`
  circle.style.zIndex = count
  circle.innerHTML = `
    <div class="circle-content">
        <span class="circle-text">${count}</span>
    </div>
`;
  return circle;
}

function getIconPath(path) {
  return new URL(`../${path}`, import.meta.url).href
}

function orderIcon({ properties }) {
  const circle = document.createElement('div');
  const img = document.createElement('img');

  if (properties.icon) {
    img.src = properties.icon

  } else if (properties.offer) {
    img.src = getIconPath(`assets/offers/${
      properties.category_id
    }.svg`)
  } else {
    img.src = getIconPath(`assets/categories/${
      properties.category_id
    }.svg`)
  }
  img.width = 40
  img.height = 40
  circle.classList.add('circle');
  circle.style.transform = 'translate(-50%, -50%)'
  circle.append(img)

  return circle;
}

let marker = null
const markerElement = document.createElement('img');
markerElement.src = getIconPath('assets/baloon.svg');
markerElement.width = 60
markerElement.height = 60
markerElement.style.transform = 'translate(-50%, -50%)'

const preparePoints = computed(() => {
  const result = props.placemarks.map((el) => ({
    type: 'Feature',
    id: el.id,
    geometry: { coordinates: [el.lat, el.lon].reverse() },
    properties: { ...el }
  }));
  return result
})

const getPosition = () => {
  if (!map) {return}

  let markerPosition = [...props.myPosition];
  markerPosition = markerPosition.reverse();
  map.setLocation({ center: markerPosition, zoom: 18, duration: 300 })

  if (marker) {
    marker.update({ coordinates: markerPosition })
    return
  }

  marker = new MapObject.YMapMarker(
    {
      source: 'mainSource',
      coordinates: markerPosition,
    },
    markerElement
  );
  map.addChild(marker);

}

const openCluster = (features, coordinates) => {
  const ids = features.map(el => el.id)
  map.setLocation({ center: coordinates, duration: 600, zoom: map.zoom + 4 });
  clusterStore.ids = ids
  router.push({ path: '/cluster' })
}

const markerCluster = (coordinates, features) => new MapObject.YMapMarker(
  {
    coordinates,
    source: 'mainSource',
    onClick: () => openCluster(features, coordinates),
    zIndex: features.length
  },
  clusterIcon(features.length).cloneNode(true)
)

const markerT = (feature) => new MapObject.YMapMarker(
  {
    coordinates: feature.geometry.coordinates,
    source: 'mainSource',
    onClick: () => openOrder(feature.properties, feature.geometry.coordinates),
  },
  orderIcon(feature).cloneNode(true)
)

let clusterer
const setPoints = () => {
  if (!map) {return}
  if (clusterer) {
    clusterer.update({
      features: [...preparePoints.value]
    })
    return
  }

  clusterer = new ClusterModule.YMapClusterer({
    method: ClusterModule.clusterByGrid({ gridSize: 64 }),
    features: preparePoints.value,
    marker: markerT,
    cluster: markerCluster
  });

  map.addChild(clusterer);

}

const flyTo = (coords)=>{
  try {
    if (map && coords) {
      const newCoords = [...coords]
      map.setLocation({ center: newCoords.reverse(), zoom: 18, duration: 300 })
    }
  } catch (error) {
    console.error(error)
  }
}

// const emit = defineEmits(['error']);

onMounted(async () => {
  await initMap()
  // try {
  //   await initMap()
  // } catch(err) {
  //   emit('error', err)
  // }
  flyTo(mapStore.openedPlace)
})
watch(() => mapStore.openedPlace, (coords)=>{
  flyTo(coords)
})
watch(() => props.myPosition, getPosition, { immediate: true })
watch(() => props.placemarks, setPoints )

onUnmounted(() => {
  if (!map) {
    return
  }
  map.destroy()

})
</script>
<style lang="scss">
.map {
    height: 100vh;
}
</style>

<style lang="scss">
.ymaps3x0--control.ymaps3x0--zoom-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
}

.ymaps3x0--controls {
    z-index: 1 !important;
}

.circle-content {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 8px solid #4655C4;
    padding: 4px;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-family: "NunitoRegular";
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: inherit;
        transform: scale(1.6);
        background: #fff;
        z-index: -1;
        display: block;
    }
}
</style>
