import { api } from "@/app/api"

export const getAccounts = async () => {
  try {
    const { data } = (await api.get('/v2/accounts'))
    return data
  } catch (error) {

    console.error(error)
    return {}
  }
}
export const createChat = async ({ account_id, from_account_id, offer_id }) => {
  try {
    const { data } = (await api.post('/v1/chat/create', { account_id, from_account_id, offer_id }))
    return data
  } catch (error) {

    console.error(error)
  }
}

export const getUser = async () => {
  try {
    const { data } = (await api.get('/v1/profile'))
    return { user: data.profile }
  } catch (error) {
    console.error(error)
    return {}
  }
}
