
import { api } from "@/app/api"

export const searchAddress = async ({ search }) => {
  try {
    const { data } = (await api.get(`https://geocode-maps.yandex.ru/1.x/?apikey=${import.meta.env.VITE_YANDEX_API_KEY}&format=json&geocode=${search}`))
    return data.response.GeoObjectCollection.featureMember.map((el) => ({
      address: el.GeoObject.metaDataProperty.GeocoderMetaData.text,
      coords: [
        +el.GeoObject.Point.pos.split(" ")[1],
        +el.GeoObject.Point.pos.split(" ")[0],
      ],
    }));
  } catch (error) {

    console.error(error)
  }
}

export const getAddress = async ({ coords }) => {
  try {
    const { data } = (await api.get(`https://geocode-maps.yandex.ru/1.x/?apikey=${import.meta.env.VITE_YANDEX_API_KEY}&format=json&geocode=${coords[1]},${coords[0]}`
    ))
    return data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted;
  } catch (error) {

    console.error(error)
  }
}
