<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="10.8"
      width="2.4"
      height="24"
      rx="1.2"
      fill="white"
    />
    <rect
      x="24"
      y="10.8"
      width="2.4"
      height="24"
      rx="1.2"
      transform="rotate(90 24 10.8)"
      fill="white"
    />
  </svg>
</template>
