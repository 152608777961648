<template>
  <div
    class="showcase__group__container"
    style="display: flex; flex-direction: column"
  >
    <div class="showcase__group__input">
      <menu-input
        v-model="search"
        :placeholder="t('menu.placeholder')"
      />
    </div>

    <div
      v-if="!filteredItems.length"
      class="showcase__group__notfound"
    >
      <p class="showcase__group__notfound__text">
        {{ t('common.notResultLong') }}
      </p>
    </div>

    <template v-else>
      <showcase-product-card
        v-for="item in filteredItems"
        :key="item.id"
        :item="item"
        :to="{
          name: 'account-showcases-products-single',
          params: {
            id: accountId,
            productId: item.id,
          },
        }"
      />
    </template>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import ShowcaseProductCard from '@/components/ShowcaseProductCard/ShowcaseProductCard.vue'
import { MenuInput } from '@/UI'

const props = defineProps({
  items: {
    type: Array,
    default: () => {},
  },
  accountId: {
    type: [Number, String],
    default: '',
  },
})

const search = ref('')
const filteredItems = computed(() => {
  if (!search.value.length) {return props.items}

  return props.items.filter(item => {
    const isNameMatches = item.name?.toLowerCase().includes(search.value.toLocaleLowerCase());
    const isDescriptionMatches = item.description?.toLowerCase().includes(search.value.toLocaleLowerCase());
    return isNameMatches || isDescriptionMatches
  })

})
</script>
