<template>
  <HeaderFullpage :title="t('ambulance.descTitle')" />
  <LayoutBase
    padding="20px"
    :title="t('ambulance.title')"
  >
    <div class="ambulance">
      <div class="gap">
        <AnimalSelector
          v-model="animalIdChoosed"
          :options="myAnimals"
        />
        <InputSimple
          v-model="form.payload.animalName"
          :placeholder="t('ambulance.ph.petName')"
        />
        <TextareaBase
          v-model="form.payload.reason"
          :placeholder="t('ambulance.ph.reason')"
        />
        <SelectAddress
          v-model="form.payload.address"
          :label="t('component.address.label')"
          @update:model-value="onAddress"
        />
      </div>

      <ButtonBase @click="submit">
        {{ t('ambulance.submit') }}
      </ButtonBase>
    </div>
  </LayoutBase>

  <FollowElements hide-drag>
    <BackButton />
  </FollowElements>
</template>

<script setup>
import { HeaderFullpage } from '@/UI'
import { reactive, ref, watch } from 'vue'
import { LayoutBase } from '@/components/Layouts';
import { InputSimple, TextareaBase, BackButton, ButtonBase, SelectAddress } from '@/UI'
import { FollowElements } from '@/components/PaneMain';
import { AnimalSelector } from '@/components/AnimalSelector'
import { onMounted } from 'vue';
import { getAnimals, callAmbulance } from './api';
import { successToast, errorToast } from '@/helpers/showToast'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const router = useRouter()

const form = reactive({
  coords: [],
  payload: {
    address: "",
    animalName: "",
    reason: ""
  }
})

const myAnimals = ref([])
const animalIdChoosed = ref(null)

const init = async () => {
  const { animals } = (await getAnimals())
  myAnimals.value = animals
}

watch(animalIdChoosed, (val) => {
  if (!val) {
    delete form.payload.pet
    form.payload.animalName = ''
    return
  }

  const choosedAnimal = myAnimals.value.find(el => el.id === val)

  form.payload.pet = choosedAnimal
  form.payload.animalName = choosedAnimal.payload.name
})

const onAddress = ({ coords, address }) => {
  form.coords = coords
  form.payload.address = address
}

const submit = async () => {
  if (!form.payload.address) {
    errorToast({ description: t('ambulance.toast.enterAddress') })
    return
  }

  const { success } = await callAmbulance(form)
  if (success) {
    successToast({ title: t('ambulance.toast.applicationCreated'), description: t('ambulance.toast.applicationCreatedDescription') })
    router.back()
  }
}

onMounted(() => init())
</script>

<style lang="scss" scoped>
.fix {
    max-height: 60px;
}

.gap {
    display: grid;
    grid-template-columns: minmax(0, auto);
    gap: 10px;
}

.ambulance {
    display: grid;
    grid-template-columns: minmax(0, auto);
    gap: 50px;
}
</style>
