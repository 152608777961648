<template>
  <HeaderFullpage :title="t('ordersView.title')" />
  <PageLoader v-if="isLoading" />
  <LayoutBase
    v-else
    gap
  >
    <div
      v-if="!orders.length"
      class="info"
    >
      {{ t('ordersView.null') }}
    </div>

    <AddEntity to="/order-create" />

    <TabsBase
      v-model="ordersStatus"
      :options="ordersStatusOptions"
    />
    <div class="pet-list">
      <EntityItem
        v-for="item in orders"
        :key="item.id"
        :item="item"
        :to="`/offer/${item.id}`"
        :is-context-shown="ordersStatus !== 'closed'"
        @show-context="openContext({ id: item.id })"
      />
    </div>
  </LayoutBase>

  <ContextPane
    ref="ContextPaneREF"
    :actions="contextOptions[ordersStatus]"
    @on-chat="onChat"
    @on-edit="onEdit"
    @on-delete="onDelete"
  />
  <CreateChatPane
    :id="choosedOrderForChat.account_id"
    ref="ChatPaneREF"
    :offer-id="choosedOrderForChat?.id"
    :filter-by-category="choosedOrderForChat.category_id"
    is-order
  />
</template>

<script setup>
import { computed, onMounted, ref, watch, } from 'vue';
import { HeaderFullpage, TabsBase } from '@/UI'
import AddEntity from '@/components/AddEntity.vue';
import EntityItem from '@/components/EntityItem.vue';
import { LayoutBase } from '@/components/Layouts'
import { ContextPane } from '@/components/ContextPane'
import { getMyOrders, deleteOrder, getMyClosedOrders, getIncome } from './api'
import { useRouter } from 'vue-router'
import { successToast, errorToast } from '@/helpers/showToast'
import { PageLoader } from '@/components/PageLoader'
import { CreateChatPane } from '@/components/CreateChatPane'
import { useI18n } from 'vue-i18n'
import { useConfirm } from '@/composables/useConfirm';

const { t } = useI18n()

const router = useRouter()

const ordersStatus = ref('open')
const ChatPaneREF = ref()
const choosedOrderForChat = ref({})

const contextOptions = {
  open: ['edit', 'delete'],
  closed: [],
  income: ['chat'],
}

const ordersStatusOptions = computed(() => (
  [
    {
      label: t('ordersView.ordersStatus.open'),
      value: 'open'
    },
    {
      label: t('ordersView.ordersStatus.closed'),
      value: 'closed'
    },
    {
      label: t('ordersView.ordersStatus.income'),
      value: 'income'
    },
  ]
))

const isLoading = ref(true)
const orders = ref([])
const ContextPaneREF = ref()

watch(ordersStatus, async (val) => {
  if (val === 'open') {
    const { orders: ordersMy } = await getMyOrders()
    orders.value = ordersMy
  } if (val === 'closed') {
    const { orders: ordersMy } = await getMyClosedOrders()
    orders.value = ordersMy
  } if (val === 'income') {
    const { orders: ordersMy } = await getIncome()
    orders.value = ordersMy
  }
  isLoading.value = false

}, { immediate: true })

const openContext = ({ id }) => {
  ContextPaneREF.value.open({ id })
}

const onEdit = (id) => {
  router.push(`/order-edit/${ id}`)
}
const onChat = (id) => {
  const choosedOrder = orders.value.find(el => el.id === id)

  choosedOrderForChat.value = choosedOrder
  ChatPaneREF.value.open()
}

const { onConfirm } = useConfirm();
const onDelete = async (id) => {
  const response = await onConfirm({ title: t('ordersView.toast.deleteConfirm') })
  if (response) {
    const { success } = await deleteOrder({ id })
    if (success) {
      successToast({ description: t('ordersView.toast.deleted') })
      const { orders: ordersMy } = (await getMyOrders())
      orders.value = ordersMy
    } else {
      errorToast({ description: t('ordersView.toast.notDeleted') })
    }
  }
}

onMounted(() => {
  isLoading.value = true
})
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .pet {

        background: #37363C;
    }

    .pet__img {
        background: #292929;
    }

    .title {
        color: #fff;
    }

    .subtitle {
        color: #B0B2C2;
    }
}

.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.pet-list {
    display: grid;
    gap: 10px;
}

.info {
    color: #C6C8DA;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}

.add-pet {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 11;
    border-radius: 50%;
    box-shadow: 0px 5px 20px 0px rgba(70, 85, 196, 0.70);

}

.pet {
    &__img {
        border-radius: 20px;
        background: #F2F8FF;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
    }

    border-radius: 26px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 10px;
}

.title {
    color: #5B5F7C;
    font-size: 15px;
    font-weight: 700;
    line-height: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subtitle {
    color: #8E92AF;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.body {
    display: grid;
    gap: 8px;
}
</style>
