
import { api } from '@/app/api'

export const fetchMessages = async ({ id }) => {
  try {
    const { data } = (await api.get('/v1/chat', {
      params: {
        id
      }
    }))
    return data
  } catch (error) {

    console.error(error)
  }
}

export const sendMessage = async ({ chat_id, message, image, reply_id }) => {
  try {
    const { data } = (await api.post('/v1/chat',
      {
        chat_id,
        message,
        image,
        reply_id
      }
    ))
    return data
  } catch (error) {

    console.error(error)
  }
}
export const uploadPhoto = async ({ formData }) => {
  try {
    const { data } = (await api.post('/v1/files/upload',
      formData
    ))
    return data
  } catch (error) {

    console.error(error)
  }
}
