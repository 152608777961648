const sexOptions = [
  {
    value: 'male',
    label: 'Самец',
  },
  {
    value: 'female',
    label: 'Самка',
  },
  {
    value: 'sexless',
    label: 'Бесполый',
  },
]

export const getSex = (value) => (sexOptions.find(el => el.value === value)?.label || 'Не указан')
