import { api } from "@/app/api"

export const getAnimals = async () => {
  try {
    const { data } = (await api.get('/v1/animals'))
    return data
  } catch (error) {

    console.error(error)
  }
}

export const editOrder = async ({ form }) => {
  try {
    const { data } = (await api.put('/v1/orders',
      form
    ))
    return data
  } catch (error) {

    console.error(error)
  }
}
export const getOrder = async ({ id }) => {
  try {
    const { data } = await api.get(`/v2/orders/${ id}`)
    return data
  } catch (error) {

    console.error(error)
  }
}

export const createOrder = async ({ form }) => {
  try {
    const { data } = (await api.post('/v1/orders',
      form
    ))
    return data
  } catch (error) {

    console.error(error)
  }
}
