<template>
  <HeaderFullpage :title="t('delegateView.title')" />
  <LayoutBase gap="30">
    <template v-if="step === 1">
      <ItemBase :title="t('delegateView.delegateToNumber')">
        <InputBasePhone
          v-model="phoneNumber"
          :label="t('common.phoneNumber')"
        />
      </ItemBase>

      <div class="btn-wrap">
        <ButtonBase @click="deligate">
          {{ t('delegateView.delegateAction') }}
        </ButtonBase>
        <ButtonBase
          danger
          @click="cancelDelegate"
        >
          {{ t('common.cancel') }}
        </ButtonBase>
      </div>
    </template>
    <template v-if="step === 2">
      <p class="info">
        Права на аккаунт <span class="strong">{{ choosedName }}</span> были
        успешно делегированы пользователю
        <span class="strong">{{ Number(name) || `"без имени"` }}</span> с
        номером
        <span class="strong">
          {{ phoneNumber }}
        </span>
        <br>
        {{ t('delegateView.userWillBeNotified') }}
      </p>
      <ButtonBase @click="closeDelegate">
        {{ t('common.close') }}
      </ButtonBase>
    </template>
  </LayoutBase>
  <PromisePane
    id="promi33se3"
    ref="promise"
  >
    {{ t('delegateView.toast.confirmToDelegate') }}
  </PromisePane>
</template>

<script setup>
import { onMounted, ref } from "vue";

import { LayoutBase } from '@/components/Layouts'
import { InputBasePhone ,HeaderFullpage,ItemBase,ButtonBase } from '@/UI'
import { deligateToAccount } from './api'
import { useRouter,useRoute } from 'vue-router'
import { PromisePane } from '@/components/PromisePane'
import { errorToast } from '@/helpers/showToast'
import { useI18n } from "vue-i18n";

import { isPhoneValid } from '@/helpers/validate';

const promise = ref()

const router = useRouter()
const route = useRoute()

const phoneNumber = ref("");
const step = ref(1);
const name = ref("");
const { t } = useI18n();

const deligate = async () => {
  const isValid = phoneNumber.value.length && isPhoneValid(phoneNumber.value);
  if (!isValid) {
    errorToast({ description:t('delegateView.toast.wrongFormat') })
    return;
  }
  const response = await deligateToAccount({
    id: route.query.id,
    phoneNumber: phoneNumber.value,
  });
  if (!response.success) {

    errorToast({ description:response.message })

    return;
  }

  name.value = response.name;
  step.value++;
};

const closeDelegate = () => {
  router.back()
};

const cancelDelegate =async ()=>{
  if (!(await promise.value.open())) {
    return
  }

  router.back()
}

onMounted(()=>{
  if (!route.query.id) {
    router.back()
  }
})
</script>

<style lang="scss" scoped>
[theme="dark"] {
  .info {
    color: #9A9EB9;
    .strong {
      color: #fff
    }
  }
}
.info {
  color: #6B7090;
    font-size: 17px;
    font-weight: 700;
    .strong {
      color: #5f5f5f
    }
}
.btn-wrap {
    display: grid;
    gap: 10px;
}
</style>

