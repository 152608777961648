<template>
  <HeaderFullpage
    desctop
    :title="t('support.title')"
  />
  <LayoutBase
    top-gap
    padding="20px"
    :title="t('support.call')"
  >
    <div class="ambulance">
      <div style="display: grid; gap: 6px;">
        <InputBase
          v-model="subject"
          :label="t('support.ph.subject')"
          style="margin-bottom: 5px;"
        />
        <TextareaBase
          v-model="text"
          :label="t('support.ph.desc')"
          :rows="4"
        />
      </div>

      <ButtonBase @click="submit">
        {{ t('support.send') }}
      </ButtonBase>
    </div>
  </LayoutBase>

  <FollowElements hide-drag>
    <BackButton />
  </FollowElements>
</template>

<script setup>
import { HeaderFullpage } from '@/UI'
import { ref } from 'vue'
import { LayoutBase } from '@/components/Layouts';
import { InputBase, TextareaBase, BackButton, ButtonBase } from '@/UI'
import { FollowElements } from '@/components/PaneMain';
import { successToast, errorToast } from '@/helpers/showToast'
import { sendSupport } from './api'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const router = useRouter()
const subject = ref('')
const text = ref('')

const submit = async () => {
  if (!subject.value || !text.value) {
    errorToast({ description: t('support.toast.fields') })
    return
  }

  const { success } = await sendSupport({ subject: subject.value, text: text.value })

  if (!success) {
    errorToast({ description: t('toast.somethingWrong') })
  }

  successToast({ description: t('support.toast.success') })
  router.back()
}
</script>

<style lang="scss" scoped>
.ambulance {
  display: grid;
  grid-template-columns: minmax(0, auto);
  gap: 30px;
}
</style>
