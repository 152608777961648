
import { api } from '@/app/api'

export const fetchFavorites = async ({ offer }) => {
  try {
    const { data } = (await api.get('/v1/favourites', {
      params: {
        offer
      }
    }))
    return data
  } catch (error) {

    console.error(error)
  }
}

export const deleteFromFavorites = async ({ id }) => {
  try {
    const { data } = (await api.delete('/v1/favourites', {
      data: {
        order_id: id
      }
    }))
    return data
  } catch (error) {
    console.log(error)
  }
}
