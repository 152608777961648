import { api } from "@/app/api"

const mapper = (ordersRaw) => {

  const ordersPrepared = ordersRaw.split(',').map(x => x.split('|'))

  const orders = []
  ordersPrepared.map(x => x[0] && +x[2] !== 0 && +x[3] !== 0 && orders.push({
    id: +x[0],
    category_id: +x[1],
    lat: +x[2] / 1000000,
    lon: +x[3] / 1000000,
    offer: x[4] === '1'
  }))
  return orders
}

export const fetchPlacemarks = async () => {
  try {
    const { data } = (await api.get('/v3/orders'))

    return { ...data, orders: mapper(data.orders) }

  } catch (error) {
    console.error(error)
  }
}
export const fetchIcons = async () => {
  try {
    const { data } = (await api.get('/v1/orders/icons'))

    return data

  } catch (error) {
    console.error(error)
  }
}

