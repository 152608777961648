<script setup>
import { ref } from 'vue'
import { ButtonBase } from '@/UI'
import PlusIcon from '@/assets/icons/other/PlusIcon.vue'
import { useDevice } from '@/composables/useDevice';

const props = defineProps({
  items: {
    type: Array,
    default: () => {},
  },
  containerId: {
    type: String,
    default: '',
  },
})

const toggle = ref(false)
const buttonRef = ref(null)

const { isMobileDevice } = useDevice();

function handleAction(action) {
  action()
  toggle.value = false
}
</script>

<template>
  <div
    ref="buttonRef"
    class="app-fab__button"
    :class="{
      'is-mobile': isMobileDevice
    }"
  >
    <div class="app-fab__button__actions">
      <transition
        name="fab-actions-appear"
        enter-active-class="animate__animated quick animate__fadeInUp"
        leave-active-class="animate__animated quick animate__fadeOutDown"
      >
        <ul
          v-show="toggle"
          class="app-fab__button__actions__list"
        >
          <transition-group
            v-if="toggle"
            enter-active-class="animate__animated quick animate__zoomIn"
            leave-active-class="animate__animated quick animate__zoomOut"
          >
            <li
              v-for="action in props.items"
              :key="action.action"
              @click="handleAction(action.action)"
            >
              <div class="app-fab__button__actions__list__item">
                <span class="app-fab__button__actions__list__item__tooltip">
                  {{ action.tooltip }}
                </span>
                <component :is="action.icon" />
              </div>
            </li>
          </transition-group>
        </ul>
      </transition>
    </div>
    <button-base
      class="app-fab__button__activator"
      :class="{
        'app-fab__button__activator--active': toggle,
      }"
      @click="toggle = !toggle"
    >
      <template #prepend-icon>
        <plus-icon />
      </template>
    </button-base>
  </div>
</template>

<style lang="scss" src="./AppFabButton.scss"></style>
