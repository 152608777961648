<template>
  <button
    class="back"
    @click="goBack"
  >
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.414 6.707H3.828L9.121 1.414L7.707 0L0 7.707L7.707 15.414L9.121 14L3.828 8.707H18.414V6.707Z"
        fill="currentColor"
      />
    </svg>
  </button>
</template>

<script setup>
import { useNavigation } from "@/composables/useNavigation";

const { goBack } = useNavigation();
</script>

<style scoped lang="scss">
.back {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    border-radius: 50%;
    box-shadow: 0px 5px 20px 0px #00000040;
    transition: all .3s ease;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    color: #6B7090;

    &.fixed {
        bottom: calc(-50px + -20px); //160
        left: 20px;
        transition: all .3s ease;
    }

    &.top {
        bottom: calc(-50px + -160px); //160
    }
}

body[theme='dark'] .back {
    background: #37363C;
    color: #fff;
}
</style>
