<template>
  <router-link
    :to="'/chat/' + props.chat.id"
    class="wrapper"
  >
    <ImgLazy
      v-if="yourPartner?.photo"
      class="img"
      :src="yourPartner?.photo"
    />
    <!-- <img v-else class="img" src="@/assets/icons/other/no-photo.svg" /> -->
    <img
      v-else
      class="img"
      :src="getIconPath(yourPartner.category_id)"
    >

    <div class="inner">
      <div class="body">
        <div class="name">
          {{ yourPartner?.name }}
        </div>
        <div
          class="msg"
          :class="{ newMsg: props.chat.count > 0 }"
        >
          {{ props.chat.last_message }}&nbsp;
        </div>
      </div>
      <div class="info">
        <div
          class="time"
          :class="{ newMsg: props.chat.count > 0 }"
        >
          {{ formatTime(props.chat.last_message_at) }}
        </div>
        <CounterBase :counter="props.chat.count" />
      </div>
    </div>
  </router-link>
</template>

<script setup>
import { formatTime } from '@/helpers/formatTime'
import { CounterBase, ImgLazy } from '@/UI'
import { computed } from 'vue';

const props = defineProps({
  chat: {
    type: Object,
    required: true,
  },
  myProfile: {
    type: Object,
    required: true
  }
})

const yourPartner = computed(() => {

  const accounts = props.myProfile.accounts.map(String)
  return (Object.entries(props.chat.names).filter(el => !accounts.includes(el[0])))[0]?.[1]
})

function getIconPath(path) {
  return new URL(`../../../../assets/icons/category/${path || 1}.svg`, import.meta.url).href
}
</script>

<style lang="scss" scoped>
[theme=dark] {
    .wrapper {
        background: #37363C;
    }

    .img {
        background: #292929;
    }

    .time {
        color: #8E92AF;

        &.newMsg {
            color: #6B7090;
            font-weight: 700;
        }
    }
}

.wrapper {
    border-radius: 26px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    display: grid;
    grid-template-columns: minmax(60px, auto) minmax(0, 1fr) auto;
    align-items: center;
    padding: 10px;
    gap: 10px;
    padding-right: 20px;
}

.img {
    border-radius: 20px;
    background: #F2F8FF;
    width: 60px;
    height: 60px;
    overflow: hidden;
}

.name {
    color: #5B5F7C;
    font-size: 15px;
    font-family: NunitoBold;
    font-style: normal;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.msg {
    color: #8E92AF;
    font-size: 14px;
    font-weight: 500;

    &.newMsg {
        color: #6B7090;
        font-weight: 700;

    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.body {
    display: grid;
    gap: 7px;
}

.info {
    align-self: stretch;
    display: grid;
    justify-content: space-between;
    justify-items: end;
}

.time {
    color: #6B7090;
    font-size: 14px;
    font-weight: 600;

}

.inner {
    display: flex;
    justify-content: space-between;
}
</style>
