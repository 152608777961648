import { reactive } from 'vue'
import { defineStore } from 'pinia'

export const useCategoryStore = defineStore('categoryStore', () => {
  const categoryChoosed = reactive({
    value: null
  })

  return { categoryChoosed }
})
