import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useMapStore = defineStore('mapStore', () => {
  const placemarks = ref([])
  const openedPlace = ref(null)

  return { placemarks, openedPlace }

})
