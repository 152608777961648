
import { api } from "@/app/api"

export const getAnimalInfo = async ({ id }) => {
  try {
    const { data } = (await api.get(`/v1/animals/${ id}`))
    return data
  } catch (error) {

    console.error(error)
  }
}
export const editAnimal = async (animal) => {
  try {
    const { data } = (await api.put('/v1/animals',
      animal
    ))
    return data
  } catch (error) {

    console.error(error)
  }
}

export const uploadPhoto = async ({ formData }) => {
  try {
    const { data } = (await api.post('/v1/files/upload',
      formData
    ))
    return data
  } catch (error) {

    console.error(error)
  }
}

export const createAnimal = async (animal) => {
  try {
    const { data } = (await api.post('/v1/animals',
      animal
    ))
    return data
  } catch (error) {

    console.error(error)
  }
}
