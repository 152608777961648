<template>
  <div
    id="map"
    class="map__wrap"
  >
    <!-- v-if="isChineseLocale || isYmapsError" -->

    <MapBaidu
      v-if="isChineseLocale"
      :my-position="coordinates"
      :placemarks="filteredPlacemarks"
    />
    <MapYandex
      v-else
      :placemarks="filteredPlacemarks"
      :my-position="coordinates"
    />
    <!-- @error="onYandexError" -->

    <img
      v-if="!isGeoLoading"
      :style="`margin-top: ${SafeAreaView.top}px`"
      src="./assets/navigation.svg"
      class="navigation"
      @click="onAction"
    >
    <div
      v-else
      :style="`margin-top: ${SafeAreaView.top}px`"
      class="navigation"
    >
      <SpinerBase color="#fff" />
    </div>
  </div>
</template>

<script setup>

import { ref, computed, onMounted } from 'vue'

import { getCurrentPosition } from '@/helpers/getCurrentPosition'
import { fetchPlacemarks, fetchIcons } from './api'
import { useMapStore } from '@/states/mapStore'
import { useStore } from '@/states/index'

import { useCategoryStore } from '@/states/categoryStore';
import { SafeAreaView } from '@/helpers/getSafeArea'
import { useUser } from '@/composables/useUser';
import { SpinerBase } from '@/UI'

import { MapYandex } from './MapYandex'
import MapBaidu from './MapBaidu';

const emit = defineEmits(['fetch-position'])

const isGeoLoading = ref(false)

const { categoryChoosed } = useCategoryStore()

const store = useStore()
const mapStore = useMapStore()

const coordinates = ref([55, 33]);
const viewPosition = ref([55, 33]);

const fetchPosition = async () => {
  isGeoLoading.value = true
  const { coords } = await getCurrentPosition()
  coordinates.value = [];
  coordinates.value = coords
  viewPosition.value = coords
  isGeoLoading.value = false

  store.$patch({ coordinates: coords });
}

const onAction = () => {
  fetchPosition()
  emit('fetch-position')
}

const init = async () => {
  fetchPosition()

  navigator.permissions
    ?.query({ name: "geolocation" })
    .then((permissionStatus) => {
      permissionStatus.onchange = () => {
        fetchPosition()
      };
    });

  const { icons } = await fetchIcons()
  const { orders } = await fetchPlacemarks()
  //добавление уникальных иконок
  const objectOrders = {}
  orders.forEach(el => {
    objectOrders[el.id] = el
  })

  icons.forEach(({ icon, id }) => {
    objectOrders[id].icon = icon
  })

  mapStore.placemarks = Object.values(objectOrders)
}

const filteredPlacemarks = computed(() => {
  let preparedPlacemarks = []
  if (categoryChoosed.value) {
    preparedPlacemarks = (mapStore.placemarks.filter(el => categoryChoosed.value === (el.category_id)))
    return preparedPlacemarks
  }
  preparedPlacemarks = mapStore.placemarks

  return preparedPlacemarks
})

const { language } = useUser()

const isChineseLocale = computed(() => language?.value?.startsWith('zh'));
onMounted(async () => {
  init()
})

// const isYmapsError = ref(false);
// const onYandexError = () => {
//   isYmapsError.value = true;
// }

</script>

<style lang="scss">
[theme="dark"] {
  .ymaps-2-1-79-ground-pane {
    filter: invert(100%) hue-rotate(230deg) !important;
  }
}
</style>

<style lang="scss" scoped>
.map {

  &__wrap {
    height: 100vh;
    position: relative;
  }
}

.navigation {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  padding: 10px;
  border-radius: 50%;
  height: 50px;
  background: #5B43EF;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyrights {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  width: 60px;
  pointer-events: none;

}

[theme="dark"] {
  .copyrights {
    filter: invert(1);
  }
}
</style>
