<template>
  <HeaderFullpage
    desctop
    :title="t('search.title')"
  />
  <LayoutBase>
    <MenuInput
      ref="input"
      v-model="searchStore.search"
      class="input-menu"
      :readonly="position === 'bottom'"
      type="text"
      autofocus
      @click="setFocus"
    />
    <div
      v-if="!searchStore.search"
      class="query"
    >
      {{ t('search.null') }}
    </div>

    <div v-if="searchStore.search && fetchedOrders?.length === 0" class="query">
      {{ t('search.notFound') }}
    </div>

    <div class="orderWrapper">
      <OrderItem
        v-for="item in fetchedOrders"
        :id="item.id"
        :current-position="myPosition"
        :info="item"
        @click="chooseId(item)"
      />
    </div>
  </LayoutBase>
  <FollowElements>
    <BackButton />
  </FollowElements>
</template>

<script setup>
import { HeaderFullpage } from '@/UI'
import { ref, onMounted, watch } from 'vue'
import { MenuInput } from '@/UI/MenuInput';
import { usePane } from '@/components/PaneMain'
import { LayoutBase } from '@/components/Layouts';
import { FollowElements } from '@/components/PaneMain';
import { BackButton } from '@/UI'
import { OrderItem } from './components/OrderItem'
import { getOrders } from '@/app/api/orders'
import { getCurrentPosition } from '@/helpers/getCurrentPosition';
import { useDebounceFn } from '@vueuse/core'
import { useSearchStore } from './model'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const searchStore = useSearchStore()

const { myPane, position } = usePane()
const input = ref()
const myPosition = ref(null)
const fetchedOrders = ref(null)

const debouncedFetch = useDebounceFn(getOrders, 1000)

watch(() => searchStore.search, async (text) => {
  const response = await debouncedFetch({ text })
  fetchedOrders.value = response?.orders
})

const init = async () => {
  setFocus()
  if (searchStore.search) {
    const response = await getOrders({ text: searchStore.search })
    fetchedOrders.value = response?.orders
    setTimeout(() => {
      document.getElementById(searchStore.id)?.scrollIntoView()
    }, 0)
  }
  myPosition.value = (await getCurrentPosition()).coords

}

const setFocus = async () => {
  if (position.value === 'bottom') {
    myPane.value.moveToBreak('middle')
    return
  }
  input.value?.focus()
}

const chooseId = (item) => {
  searchStore.id = item.id
}

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
// .input-menu {
//     position: fixed;
//     right: 0;
//     left: 0;
// }

.query {
    text-align: center;
    color: #6B7090;
    font-size: 17px;
    font-weight: 700;
    margin-top: 20px;
}

.orderWrapper {
    display: grid;
    gap: 10px;
    margin-top: 20px;
}
</style>
