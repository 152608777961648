import { defineStore } from "pinia";

export const useStore = defineStore("index-store", {
  state: () => ({
    authToken: null,
    isDemoMode: false,
    isOnboardingDone: false,
    updateAppDialogLastDate: null,
    isMobileDevice: false,
    language: 'ru',
    isLanguageDetected: false,

    agreeVersion: null,
    pushToken: null,

    coordinates: [],
  }),
  persist: true,
})
