<template>
  <SelectFullPage
    id="animalTypesPane"
    v-model="value"
    hidden-placeholder
    label="Выберите животное"
    :items="animalTypes"
  >
    <template #icon>
      <IconBase :path="`animals/${getItemById.icon}.svg`" class="pane-img" />
    </template>
    <template #item-prepend="{ item }">
      <IconBase :path="`animals/${item.icon}.svg`" class="pane-img" />
    </template>
  </SelectFullPage>
</template>

<script setup>
import { SelectFullPage, IconBase } from '@/UI'

import { useMainEntities } from '@/states/mainEntities'
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
})
const emit = defineEmits(['update:modelValue'])

const value = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})

const mainEntities = useMainEntities()
const animalTypes = computed(() => mainEntities.animalTypes);
const getItemById = computed(() => mainEntities.animalTypes.find(el => el.id === value.value))

setTimeout(() => {
  console.log(animalTypes.value);
}, 1000);
</script>
