<template>
  <HeaderFullpage
    class="mb"
    :title="props.customPage.headerTitle"
  />
  <PageLoader v-if="isLoading" />
  <LayoutBase
    v-else
    :gap="40"
  >
    <ItemBase :title="t('order.name')">
      <InputSimple
        v-model="form.payload.name"
        name="Название"
        type="text"
        :placeholder="t('common.name')"
      />
    </ItemBase>
    <ItemBase :title="t('order.desc')">
      <TextareaBase
        v-model="form.payload.description"
        :rows="6"
        :placeholder="t('common.description')"
      />
    </ItemBase>

    <ItemBase :title="t('order.service')">
      <SelectCategory v-model="form.category_id" />
    </ItemBase>

    <ItemBase :title="t('order.choosePet')">
      <SelectAnimal
        v-if="myAnimals"
        v-model="animalIdChoosed"
        :options="myAnimals"
      />
    </ItemBase>

    <template v-if="!animalIdChoosed">
      <ItemBase :title="t('order.kind')">
        <div class="gap">
          <SelectAnimalType v-model="form.payload.animal_id" />
          <InputSimple
            v-model="form.payload.petName"
            name="Кличка"
            :placeholder="t('order.ph.petName')"
          />
          <InputSimple
            v-model="form.payload.breed"
            name="Порода"
            :placeholder="t('order.ph.breed')"
          />
        </div>
      </ItemBase>

      <CheckboxTabs
        v-model="form.payload.sex"
        :options="sexOptions"
      />

      <div class="item-inner">
        <ItemBase :title="t('order.bdate')">
          <InputSimple
            v-model="form.payload.age"
            :name="t('order.bdate')"
            data-maska="##.##.####"
            type="decimal"
            :placeholder="t('order.ph.bdate')"
          />
        </ItemBase>
        <ItemBase :title="t('common.weight')">
          <InputSimple
            v-model="form.payload.weight"
            :name="t('common.weight')"
            type="decimal"
            :placeholder="t('common.weight')"
            data-maska="###"
          />
        </ItemBase>
      </div>

      <ItemBase :title="t('order.castration')">
        <CheckboxTabs
          v-model="form.payload.castration"
          :options="castrationOptions"
        />
      </ItemBase>

      <ItemBase :title="t('order.chip')">
        <CheckboxTabs
          v-model="form.payload.chip"
          :options="chipOptions"
        />
        <InputSimple
          v-if="form.payload.chip"
          v-model="form.payload.chipNumber"
          :placeholder="t('order.ph.chip')"
        />
      </ItemBase>

      <ItemBase :title="t('order.vac')">
        <div
          v-for="( inputVac, key ) in form.payload.vaccinations "
          :key="key"
          class="vac"
        >
          <InputSimple
            v-model="inputVac.type"
            :placeholder="t('order.ph.vacName')"
            class="vac__name"
          />
          <InputDate v-model="inputVac.createdAt" />
          <img
            v-if="form.payload.vaccinations.length - 1 !== key"
            class="vac__delete"
            src="@/assets/icons/other/add.svg"
            alt=""
            @click="form.payload.vaccinations = form.payload.vaccinations.filter((el, idx) => idx !== key)"
          >
        </div>
      </ItemBase>
      <ItemBase :title="t('order.checkup')">
        <div class="vac">
          <InputSimple
            v-model="form.payload.lastCheckupDrug"
            :placeholder="t('order.ph.vacName')"
            class="vac__name"
          />
          <InputDate v-model="form.payload.lastCheckup" />
        </div>
      </ItemBase>
      <PhotoGallery v-model="form.payload.photos" />
      <ItemBase :title="t('order.chronic')">
        <TextareaBase
          v-model="form.payload.chronicIlls"
          :rows="6"
          :placeholder="t('order.ph.chronic')"
        />
      </ItemBase>
    </template>

    <ItemBase :title="t('order.address')">
      <SelectAddress
        v-model="form.payload.address"
        :label="t('component.address.label')"
        @update:model-value="onAddress"
      />
    </ItemBase>

    <ItemBase :title="t('order.doctor')">
      <CheckboxTabs
        v-model="form.payload.callDoctor"
        :options="callDoctorOptions"
      />
    </ItemBase>

    <ButtonBase @click="submit">
      {{ props.customPage.buttonText }}
    </ButtonBase>
  </LayoutBase>
</template>

<script setup>
import { LayoutBase } from '@/components/Layouts'
import { HeaderFullpage, ItemBase, CheckboxTabs, TextareaBase, ButtonBase, InputSimple, SelectCategory, SelectAnimalType, SelectAnimal, SelectAddress } from '@/UI'
import { ref, reactive, watch, onMounted } from 'vue'
import { PhotoGallery } from '@/components/PhotoGallery'
import { getAnimals, getOrder, editOrder, createOrder } from './api'
import { uploadPhoto } from '@/states/api'
import { successToast, errorToast } from '@/helpers/showToast'
import { useRouter, useRoute } from 'vue-router'
import { chipOptions, castrationOptions, sexOptions, callDoctorOptions } from './constants'
import { PageLoader } from '@/components/PageLoader'
import { isValide } from '@/helpers/validate'
import { InputDate } from '@/components/InputDate'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
  customPage: {
    type: Object
  },
  pageType: {
    type: String,
    required: true
  }
})

const animalIdChoosed = ref(null)
const isLoading = ref(true)

const router = useRouter()
const route = useRoute()

const onAddress = ({ address, coords }) => {
  form.payload.address = address
  form.payload.coords = coords
}

watch(animalIdChoosed, (id) => {
  if (id) {
    const chooosedAnimal = JSON.parse(JSON.stringify(myAnimals.value.find(el => el.id == id)))

    chooosedAnimal.payload.petName = chooosedAnimal.payload.name
    delete chooosedAnimal.payload.name

    form.payload = {
      ...form.payload, ...chooosedAnimal.payload
    }
    return
  }
  if (!id) {
    // form.payload
    const resetPet = {
      sex: "male",
      animal_id: 1,
      castration: false,
      chip: false,
      chipNumber: "",
      weight: "",
      age: "",
      breed: "",
      petName: '',
      photos: [],
      chronicIlls: "",
      vaccinations: [
        {
          createdAt: '',
          type: ''
        }
      ],
      lastCheckup: "",
      lastCheckupDrug: "",
    }

    //очищение формы животного
    for (const key in form.payload) {
      if (resetPet[key] !== undefined) {
        form.payload[key] = resetPet[key]
      }
    }

  }
})

const form = reactive({
  category_id: 1,
  payload:
    {
      sex: "male",
      animal_id: 1,
      castration: false,
      chip: false,
      chipNumber: "",
      weight: "",
      age: "",
      breed: "",
      petName: '',
      photos: [],
      chronicIlls: "",
      vaccinations: [
        {
          createdAt: '',
          type: ''
        }
      ],
      lastCheckup: "",
      lastCheckupDrug: "",
      callDoctor: false,
      name: '',
      description: '',
      address: '',
      coords: [],
    },
})

const validateParams = () => [
  {
    value: form.payload.address,
    label: t('common.address'),
  },
  {
    value: form.payload.name,
    label: t('common.name'),
  },
  {
    value: form.payload.description,
    label: t('common.description'),
  },
]
watch(form, () => {
  if (Object.keys(form.payload.vaccinations).length) {
    const { createdAt, type } = form.payload.vaccinations.at(-1)

    if (createdAt && type) {
      form.payload.vaccinations.push({
        createdAt: '',
        type: '',
      })
    }
  }
})

watch(() => form.payload.chip, (val) => {
  if (val === false) { form.payload.chipNumber = '' }
})

const submit = async () => {
  if (!isValide(validateParams(), t)) {
    return
  }

  isLoading.value = true

  const { paths } = await uploadPhoto({ photos: form.payload.photos })

  const doned = ({ ...form, payload: { ...form.payload, photos: [...paths] } })

  if (props.pageType === 'edit') {
    const { success } = await editOrder({ form: doned })

    if (success) {
      successToast({ description: t('order.toast.success') })
      router.back()

    } else {errorToast({ description: t('order.toast.fail') })}
  }
  if (props.pageType === 'create') {
    const { success } = await createOrder({ form: doned })

    if (success) {
      successToast({ description: t('order.toast.success2') })
      router.back()

    } else {errorToast({ description: t('order.toast.fail2') })}
  }

  isLoading.value = false
}

const myAnimals = ref([])

const init = async () => {
  if (props.pageType === 'edit') {
    const { id } = route.params
    const { orders } = await getOrder({ id })

    Object.assign(form, orders[0])
  }
  const { animals } = (await getAnimals())
  myAnimals.value = animals
  isLoading.value = false

}

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
.mb {
    margin-bottom: 10px;
}

.gap {
    display: grid;
    gap: 10px;
}

.item-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.vac {
    display: grid;
    grid-template-columns: 1fr 120px 24px;
    align-items: center;
    gap: 10px;

    &__delete {
        transform: rotate(45deg);
    }
}
</style>
