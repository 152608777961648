<template>
  <div
    v-if="props.modelValue"
    class="animal-type"
    :class="{ disabled: props.disabled }"
    @click="handleClick"
  >
    <IconBase
      :path="`animals/${getItemById(props.modelValue).icon}.svg`"
      class="pane-img"
    />
    <div class="title">
      {{ props.modelValue ? getItemById(props.modelValue).name : 'Выберите животное' }}
    </div>
    <img
      v-if="!props.disabled"
      src="@/assets/icons/other/small-arrow.svg"
      class="arrow"
    >
  </div>

  <PanePromise
    :id="'viewAnimal'"
    ref="PanePromiseREF"
  >
    <LayoutBase
      gap
      :title="t('component.animalType.title')"
    >
      <ItemBase>
        <div class="pane-inner">
          <div
            v-for="item in mainEntities.animalTypes "
            :key="item.id"
            class="item"
            @click="emit('update:modelValue', item.id)"
          >
            <IconBase
              :path="`animals/${item.icon}.svg`"
              alt=""
              class="item-img"
            />
            <div class="item-title">
              {{ item.name }}
            </div>
            <CheckboxBase :is-active="props.modelValue === item.id" />
          </div>
        </div>
      </ItemBase>
      <ButtonBase
        class="btn-selector"
        @click="handleClose"
      >
        {{ t('common.choose') }}
      </ButtonBase>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { ref } from 'vue'
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ItemBase, CheckboxBase, ButtonBase, IconBase } from '@/UI'
import { useMainEntities } from '@/states/mainEntities'

const props = defineProps({
  modelValue: {
    type: Number,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const mainEntities = useMainEntities()

const PanePromiseREF = ref()

const getItemById = (id) => mainEntities.animalTypes.find(el => el.id === id)

const handleClick = () => {
  PanePromiseREF.value.open({})
}

const handleClose = () => {
  PanePromiseREF.value.close()
}

</script>

<style lang="scss">
[theme="dark"] {
    .animal-type {
        background: #37363C;
        border: 1px solid #37363C;
    }
    .pane-selector {
        background: #37363C;
        color: #7A7B87;
        border: 1px solid #37363C;
    }

    .pane-inner {
        background: #37363C;
        border: 1px solid #37363C;
    }

    .item-title {
        color: #fff
    }

    .title {
        color: #fff;
    }
}

.disabled {
    pointer-events: none;
}

.animal-type {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 0 20px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 10px;
    max-height: 60px;
    height: 60px;
}

.btn-selector {
    position: sticky;
    bottom: 20px;
}

.pane-inner {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 20px 25px;
    display: grid;
    gap: 40px;

    overflow-y: auto;

}

.item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 20px;

    &-title {
        color: #645C56;
        font-size: 15px;
        font-weight: 600;
        line-height: 14px;
    }

    &-img {
        width: 20px;
        height: 20px;
    }
}

.pane-img {
    width: 30px;
    height: 30px;
}

.arrow {
  transform: rotate(-90deg);
  width: 13px;
}
</style>
