<template>
  <InputBase
    v-bind="$attrs"
    ref="amountInputRef"
    v-model="computedModelValue"
    inputmode="numeric"
  />
</template>

<script setup>
import { computed, ref } from 'vue'
import InputBase from './InputBase.vue';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
})

const emit = defineEmits(['update:modelValue'])
const amountInputRef = ref(null)

const computedModelValue = computed({
  get() {
    return props.modelValue || ''
  },
  set(newValue) {
    const formattedValueAsNumber = newValue.replace(/\D/g, '')
    amountInputRef.value.$refs.inputRef.value = formattedValueAsNumber

    emit('update:modelValue', Number(formattedValueAsNumber))
  },
})
</script>
