import { api } from "@/app/api";
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { useConfirm } from '@/composables/useConfirm'
import { useStore } from '@/states/index'
import dayjs from 'dayjs';

const openDialogToUpdate = async ({ platform, url }) => {
  const store = useStore()
  const { onConfirm } = useConfirm();

  const yesButtonTitle = (() => {
    if (platform === 'android') {
      return 'Обновить приложение в Google Play'
    }
    if (platform === 'ios') {
      return 'Обновить приложение в AppStore'
    }
    return 'Обновить';
  })();

  const result = await onConfirm({
    title: 'Версия приложения устарела',
    yesButton: {
      danger: false,
      title: yesButtonTitle
    },
    noButton: {
      variant: 'transparent',
      title: 'Пропустить'
    },
  })

  if (result) {
    window.open(url, '_blank').focus();
  } else {
    store.$patch({ updateAppDialogLastDate: new Date() })
  }
}

export const checkAppVersion = async () => {
  const store = useStore()
  const { data } = await api.get('/version');
  const APP_URL = data?.url?.android || data?.url?.ios

  const { platform } = await Device.getInfo();

  try {
    const app = await App.getInfo();

    const isWepAppOutdated = platform !== 'web' && (data.current != app.version || !app);
    const isTimeoutToUpdateLeft = dayjs(new Date()).diff(store.updateAppDialogLastDate, 'day') > 2;

    if (isWepAppOutdated && isTimeoutToUpdateLeft) {
      openDialogToUpdate({ platform, url: APP_URL })
    }
  } catch (error) {
  }
}
