<template>
  <div class="category-wrapper">
    <div
      v-for="item in filteredCategory"
      :id="'category-' + item.id"
      class="item"
      :class="{ active: categoryChoosed.value === item.id }"
      @click="handleToggle(item.id)"
    >
      <IconBase
        class="img"
        :class="{ active: categoryChoosed.value === item.id }"
        :path="'category/' + item.id + '.svg'"
        alt=""
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue'
import { useMainEntities } from '@/states/mainEntities';
import { useCategoryStore } from '@/states/categoryStore';
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { useMapStore } from '@/states/mapStore'
import { IconBase } from '@/UI'

const mapStore = useMapStore()

const router = useRouter()
const { category } = useMainEntities()
const { categoryChoosed } = useCategoryStore()

const filteredCategory = computed(() => {
  //удаляю все категории, которых нет на карте
  if (mapStore.placemarks.length === 0) {
    return category
  }
  const makeObj = {}
  mapStore.placemarks.forEach(el => {
    makeObj[el.category_id] = true
  })
  return (category.filter(el => makeObj[el.id]))

  //если нужны все категории то удалять все что до комментария
  // return category
})

const handleToggle = (id) => {
  Haptics.impact({ style: ImpactStyle.Medium });
  if (categoryChoosed.value === id) {
    categoryChoosed.value = null

    if (!categoryChoosed.value) {
      router.push('/')
    }
  } else {
    categoryChoosed.value = id
    moveToPage()
  }
}
const moveToPage = () => {
  router.push('/category')
}

onMounted(() => {
  const element = document.querySelector(`#category-${ categoryChoosed.value}`)
  if (!element) {return}

  element.scrollIntoView({
    inline: 'center'
  })
})

onBeforeRouteLeave((to, from) => {
  if (to.path !== '/') {
    return
  }
  categoryChoosed.value = null
})
</script>

<style scoped lang="scss">
.category-wrapper {
    display: flex;
    gap: 10px;
    overflow-y: auto;
    margin: 0 -20px;
    padding: 0 20px;

    &::-webkit-scrollbar {
        width: 0;
    }
}

.item {
    min-width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 60px;
    border: 1px solid #E1EBF8;
    background: #FFF;

    &.active {
        background: var(--main-color, linear-gradient(0deg, #5B43EF 0%, #5B43EF 100%), linear-gradient(135deg, #5B43EF 0%, #8443EF 100%));
    }
}

.img {
    &.active {
        filter: brightness(100);
    }
}

body[theme='dark'] .item {
    background: #37363C;
    border: 1px solid #37363C;

    &.active {
        background: var(--main-color, linear-gradient(0deg, #5B43EF 0%, #5B43EF 100%), linear-gradient(135deg, #5B43EF 0%, #8443EF 100%));
    }
}
</style>
