<template>
  <div class="wrap">
    <div
      v-for="item in 10"
      :key="item"
      class="inner"
    >
      <ImgLazy
        class="img"
        :src="yourPartner?.photo || require('@/assets/icons/other/no-photo.svg')"
      />
      <div class="body">
        <div class="name">
          имя
        </div>
        <RaitingBase
          :model-value="3"
          disabled
          size="1"
        />
        <div class="msg">
          сообщение сообщениесообщение сообщениесообщение сообщениесообщение сообщениесообщение
          сообщениесообщение сообщение
        </div>
      </div>

      <div class="time">
        {{ formatTime(new Date) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { RaitingBase } from '@/UI'
import { formatTime } from '@/helpers/formatTime'

const init = async () => {

}

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
.inner {
    border-radius: 26px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    display: grid;
    grid-template-columns: minmax(60px, auto) minmax(0, 1fr) auto;
    align-items: center;
    padding: 10px;
    gap: 10px;
    padding-right: 20px;
}

.wrap {
    display: grid;
    gap: 10px;
}

.img {
    border-radius: 20px;
    background: #F2F8FF;
    width: 60px;
    height: 60px;
    overflow: hidden;
    align-self: flex-start;
}

.name {
    color: #5B5F7C;
    font-size: 15px;
    font-family: "NunitoBold";
    font-style: normal;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.msg {
    color: #8E92AF;
    font-size: 14px;
    font-weight: 500;

    &.newMsg {
        color: #6B7090;
        font-weight: 700;

    }

}

.time {
    color: #6B7090;
    font-size: 14px;
    font-weight: 600;
    align-self: flex-start;

}

.body {
    display: grid;
    gap: 7px;
}
</style>
