import { ref } from 'vue';
import { Device } from '@capacitor/device';

import { useStore } from '@/states/index';
import { languages } from '@/app/common';
import { updateLanguage, getLanguage } from '@/plugins/localization';

export const useSystem = () => {
  const currentLanguage = ref('ru');
  const getDeviceLocale = async () => {
    try {
      const info = await Device.getInfo();

      const locale = info.locale || navigator.language || navigator.languages[0];
      const result = locale.split('-')[0];
      return result;
    } catch (error) {
      console.error(error)
      return 'ru';
    }
  }

  const getDeviceTheme = () => {
    const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
    console.log('isDarkTheme: ', isDarkTheme);
    return isDarkTheme
  }

  const initDeviceLanguage = (deviceLang) => {
    const store = useStore()

    if (deviceLang) {
      currentLanguage.value = deviceLang;

      if (languages.findIndex(lang => lang.id === deviceLang) > -1) {
        updateLanguage(deviceLang)
      } else if (deviceLang === 'br'){
        updateLanguage('ru')
      } else {
        updateLanguage('en')
      }
    } else {
      updateLanguage('ru')
    }

    currentLanguage.value = store.language
  }

  const checkConnection = () => {
    console.log(navigator.onLine);
    if (!navigator.onLine) {
      const currentLang = getLanguage()
      console.log(currentLang);
      window.location.href = `no-connection.html?lang=${currentLang}`;
    }
  }

  const listenConnection = () => {
    console.log("The network connection has been lost.");
    window.addEventListener('offline', checkConnection);
  }

  const registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register(`${import.meta.env.VITE_BASE_URL}service-worker.js`)
          .then((registration) => {
            console.log('Service Worker зарегистрирован с областью:', registration.scope);
          })
          .catch((error) => {
            console.error('Ошибка регистрации Service Worker:', error);
          });
      });
    }
  }

  return {
    currentLanguage,
    getDeviceTheme,
    getDeviceLocale,
    initDeviceLanguage,
    listenConnection,
    registerServiceWorker
  }
}

