<template>
  <img :src="src">
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  path: {
    type: String,
    default: ''
  }
})
function getIconPath(path) {
  return new URL(`../../assets/icons/${path}`, import.meta.url).href
}

const src = computed(() => getIconPath(props.path))
</script>
