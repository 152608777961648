<template>
  <div class="textarea__base">
    <textarea
      ref="textareaREF"
      v-bind="$attrs"
      v-model="computedModelValue"
      :rows="rows"
      class="textarea"
      :class="{
        disable: props.disable,
        'has-value': String(computedModelValue).length > 0
      }"
      @keydown="keypress"
    />
    <span class="input__label">
      {{ label }}
    </span>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'

const textareaREF = ref(null)
const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  fitContent: {
    type: Boolean,
    default: false,
  },
  disable: {
    type: Boolean,
    default: false,
  },
  rows: {
    type: [Number, String],
    default: '4',
  },
})

const emit = defineEmits(['update:modelValue', 'submit'])

const computedModelValue = useVModel(props, 'modelValue', emit)

const init = () => {
  if (!props.fitContent) {return}

  const element = textareaREF.value
  const paddingTopAndBottom =
    parseInt(getComputedStyle(element, null).getPropertyValue('padding'), 10) * 0
  element.style.minHeight =
    `${element.scrollHeight - paddingTopAndBottom + 10 }px`
}

const calculateHeight = () => {
  // TODO

  // const firstHeight = 90
  // const newHeight = textareaREF.value.scrollHeight
  // if (newHeight >= firstHeight && newHeight <= 300) {
  //   textareaREF.value.clientHeight = `${newHeight }px`
  // }
}

const keypress = (e) => {
  const keyCode = e.which || e.keyCode
  if (keyCode === 13 && !e.shiftKey && !e.metaKey) {
    e.preventDefault()
    emit('submit')
  } else if (keyCode === 13 && e.metaKey) {
    computedModelValue.value += '\n'
  }
}

onMounted(() => {
  init()
})

watch(computedModelValue, () => {
  calculateHeight()
})
</script>

<style scoped lang="scss">
[theme='dark'] {
  .textarea {
    background: #37363c;
    border: 1px solid #37363c;
    color: #fff;
  }

  .textarea__base {
    background: #37363c;
    border: 1px solid #37363c;
  }
}

.textarea {
  box-sizing: border-box;
  width: 100%;
  line-height: 24px;
  padding: 26px 20px 10px;
  border: unset;
  background: #fff;
  border-radius: 20px;
  mask-image: linear-gradient(
    to bottom,
    transparent,
    transparent 28px,
    black 32px
  );

  &.disable {
    pointer-events: none;
    mask-image: unset;
  }
}

.textarea__base {
  position: relative;
  border: 1px solid #e8eafc;
  border-radius: 20px;
  transition: border 0.2s;
  background: #fff;

  &:has(.textarea:focus) {
    border: 1px solid #5b43ef;
  }
}

.input__label {
  position: absolute;
  top: 22px;
  left: 20px;
  font-size: 16px;
  pointer-events: none;
  transition: all 0.2s;
  color: rgb(107, 112, 144);
}

.textarea:focus + .input__label,
.textarea.has-value + .input__label{
  top: 10px;
  font-size: 14px;
}
</style>
