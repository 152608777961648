import { createRouter, createWebHistory } from 'vue-router'

import { MapView } from '@/screens/MapView'
import AuthView from '@/screens/AuthView/AuthView'
import { TermsView } from '@/screens/TermsView'
import AR from "@/screens/AR/AR.vue";

import { PaneChildren } from './PaneChildren'

import { Keyboard } from '@capacitor/keyboard'
import { Device } from '@capacitor/device';

import { guards } from './guards';

import { useStore } from '@/states/index';

const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
  },
  {
    path: '/',
    name: 'home',
    component: MapView,
    children: PaneChildren
  },
  {
    path: '/index.html',
    redirect: () => ({ name: "home" }),
  },
  // {
  //   path: "/order/:id/ar",
  //   name: "order-ar",
  //   component: AR,
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'NOT_FOUND',
    redirect: () => ({ name: "home" }),
  },
]

export const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
  scrollBehavior() {
    document.querySelector('#content')?.scrollIntoView()
  },
})

const redirect = async () => {
  const { hash = '' } = location
  if (hash) {
    await router.isReady()
    router.replace(hash.substring(1))
  }
}
redirect()

router.beforeEach(async () => {
  if ((await Device.getInfo()).platform !== 'android') {return}
  await Keyboard.hide()
  await new Promise(res => setTimeout(res, 100))
})

router.beforeEach((to, from) => {
  const store = useStore()
  if (store.isDemoMode) {
    let path = to.fullPath;

    if (to.fullPath !== path) {
      return path;
    }
    return;
  }

  try {
    const {
      isAuthenticated,
      isPublicPage,
      // isArPage,
    } = guards(to, from);

    let path = to.fullPath;

    path = isAuthenticated(path);
    path = isPublicPage(path);
    // path = isArPage(path);

    if (to.fullPath !== path) {
      return path;
    }
  } catch (error) {
    console.error(error);
  }
});

export default router
