<template>
  <button class="wrapper">
    <slot />
  </button>
</template>

<style scoped lang="scss">
.wrapper {
    background: linear-gradient(0deg, #5B43EF, #5B43EF), linear-gradient(92.69deg, #5B43EF 0%, #8443EF 100%);
    border-radius: 25px;
    padding: 17px 21px;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: #FFFFFF;

    @media (max-height: 665px) {
        padding: 14px 18px;
    }
}
</style>
