<template>
  <router-link
    :to="to"
    class="showcase-product__card"
    :class="{
      'showcase-product__card--simple': type === 'simple',
      'showcase-product__card--default': type === 'default',
    }"
  >
    <div class="showcase-product__card__image">
      <img
        ref="imageRef"
        src="../../assets/icons/other/stub.png"
        alt=""
        class="showcase-product__card__image__item"
      >
    </div>
    <div class="showcase-product__card__info">
      <h4 class="showcase-product__card__title">
        {{ title }}
      </h4>

      <div
        v-if="type === 'default'"
        style="font-size: 14px; line-height: 15px; color: #5B5F7C;"
        class="font-medium showcase-product__card__description"
      >
        {{ addDotsToTheEnd(70, item.description) }}
      </div>

      <div style="flex: 1;" />

      <div v-if="item.price" style="display: flex; gap: 6px;">
        <span class="showcase-product__card__price font-bold">{{ price }} {{ currency }}</span>
        <span
          v-if="Number(item.discount)"
          class="showcase-product__card__price font-line-throught"
        >{{ item.price }} {{ currency }}</span>
      </div>
    </div>
  </router-link>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { addDotsToTheEnd } from '@/helpers/helpers'

import { useMainEntities } from '@/states/mainEntities'
import { useI18n } from 'vue-i18n';

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  type: {
    type: String,
    validator(value) {
      return ['simple', 'default'].includes(value)
    },
    default: 'default',
  },
  to: {
    type: Object,
    default: () => {},
  },
})

const imageRef = ref()

onMounted(() => {
  try {
    const imgSrc = props.item?.files && props.item?.files[0]
    if (imgSrc && typeof imgSrc == 'string') {
      imageRef.value.src = imgSrc
    }
  } catch (error) {
    console.error(error)
  }
})

const mainEntities = useMainEntities()
const currency = computed(() => {
  const selectedCurrency = mainEntities.getCurrencyById(props.item?.currency_id);
  if (selectedCurrency) {
    return selectedCurrency.short
  }

  return '₽'
})
const volume_id = computed(() => mainEntities.getVolumeById(props.item?.volume_id))
const price = computed(() => {
  if (Number(props.item?.discount)) {
    return Math.floor(props.item?.price - props.item?.price * props.item?.discount / 100);
  }
  return props.item?.price;
})
const { t } = useI18n()
const title = computed(() => {
  const name = addDotsToTheEnd(40, props.item?.name);
  const volume = volume_id.value?.name ? t(`volume.${volume_id.value?.name}`) : '';
  const secondPart = props.item?.amount ? `${props.item?.amount}${volume}` : '';

  return `${name} ${secondPart}`
})
</script>

<style lang="scss" src="./ShowcaseProductCard.scss"></style>
