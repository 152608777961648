
import { useRouter, useRoute } from 'vue-router'

export const useNavigation = () => {
  const router = useRouter();
  const route = useRoute();

  const goBack = () => {
    const prevPath = route.meta.prevPath;
    if (prevPath) {
      router.replace(prevPath)
      return;
    }

    const hasHistory = window.history.length > 2
    if (hasHistory) {
      router.back()
    } else {
      router.replace('/')
    }
  }

  return { goBack }
}
