export function formatTime(date) {
  date = new Date(date)

  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Добавляем ведущий ноль для часов и минут, если значение меньше 10
  const formattedHours = hours < 10 ? `0${ hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${ minutes}` : minutes;

  // Собираем время в формате hh:mm
  const formattedTime = `${formattedHours }:${ formattedMinutes}`;

  return formattedTime;
}
