
import { api } from "@/app/api"

export const getCounter = async () => {
  try {
    const { data } = (await api.get('/v1/chat/count'))
    return data
  } catch (error) {
    console.error(error)
  }
}
