<template>
  <PanePromise
    ref="basePane"
    fit-content
    @close="$emit('close')"
  >
    <LayoutBase :title="title">
      <slot name="prepend" />
      <div class="pane-inner">
        <slot />
      </div>
      <slot name="append" />
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { ref } from 'vue'
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ItemBase } from '@/UI'

const emit = defineEmits(['close'])

defineProps({
  title: {
    type: String,
    default: '',
  },
});

const basePane = ref(null)

const open = () => {
  basePane.value.open()
}
const close = () => {
  basePane.value.close()
  emit('close')
}

defineExpose({ open, close });
</script>

<style lang="scss" scoped>
[theme="dark"] {
  .pane-inner {
    background: #37363C;
    border: 1px solid #37363C;
  }
}

.pane-inner {
  border-radius: 20px;
  border: 1px solid #E8EAFC;
  background: #FFF;
  padding: 20px 25px;
  overflow-y: scroll;

  display: flex !important;
  flex-direction: column;
  gap: 10px !important;
  display: grid;
  overflow-y: auto;
}

</style>
