<template>
  <div v-if="!isLoading">
    <!-- <CheckLocation /> -->

    <MapMain @fetch-position="onFetchPosition" />
    <PaneMain ref="paneMain">
      <RouterView />
    </PaneMain>
  </div>
  <div
    v-else
    class="spiner"
  >
    <SpinerBase class="large" />
  </div>
</template>

<script setup>
import { PaneMain } from '@/components/PaneMain';
import { MapMain } from '@/components/MapMain'
import { SpinerBase } from '@/UI';
// import { CheckLocation } from '@/app/PreviewScreens/CheckLocation'
import { ref } from 'vue';
import { useMainEntities } from '@/states/mainEntities'

const store = useMainEntities()
const { initMainEntities } = store
const isLoading = ref(true)
const init = async () => {
  await initMainEntities()
  isLoading.value = false
}
init()

const paneMain = ref(null)
const onFetchPosition = () =>{
  console.log('onFetchPosition');
  paneMain.value.movePaneDown();
}

</script>

<style scoped lang="scss">
.spiner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.large {
  transform: scale(1.5);
}
</style>

