<template>
  <div
    class="wrapper"
    v-bind="$attrs"
  >
    <div
      v-if="props.title"
      class="title"
    >
      {{ props.title }}
    </div>
    <slot />
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: ''
  }
})
</script>

<style lang="scss" scoped>
.title {
  color: #000000;
  font-size: 17px;
  @include font-bold;
}

.wrapper {
  display: grid;
  gap: 19px;
}
</style>
