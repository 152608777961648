<template>
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_35_1080)">
      <path
        d="M17.9926 1.54647C17.6771 0.621177 16.8031 0 15.8109 0H7.18914C6.19685 0 5.32285 0.621177 5.00743 1.54647L3.45 6.14706H5.52657L6.87371 2.16118C6.91971 2.03176 7.04457 1.94118 7.18257 1.94118H15.8043C15.9489 1.94118 16.0737 2.03176 16.1131 2.16118L17.4603 6.14706H19.5369L17.9794 1.54647H17.9926Z"
        fill="white"
      />
      <path
        d="M22.3429 7.76471H0.657143C0.295714 7.76471 0 8.05589 0 8.41177V11.6471C0 12.0029 0.295714 12.2941 0.657143 12.2941H1.78743L3.11486 20.9C3.21343 21.5341 3.76543 22 4.416 22H18.584C19.2346 22 19.7866 21.5341 19.8851 20.9L21.2191 12.2941H22.3494C22.7109 12.2941 23.0066 12.0029 23.0066 11.6471V8.41177C23.0066 8.05589 22.7109 7.76471 22.3494 7.76471H22.3429ZM7.88571 18.4412C7.88571 18.9782 7.44543 19.4118 6.9 19.4118C6.35457 19.4118 5.91429 18.9782 5.91429 18.4412V14.2353C5.91429 13.6982 6.35457 13.2647 6.9 13.2647C7.44543 13.2647 7.88571 13.6982 7.88571 14.2353V18.4412ZM12.4857 18.4412C12.4857 18.9782 12.0454 19.4118 11.5 19.4118C10.9546 19.4118 10.5143 18.9782 10.5143 18.4412V14.2353C10.5143 13.6982 10.9546 13.2647 11.5 13.2647C12.0454 13.2647 12.4857 13.6982 12.4857 14.2353V18.4412ZM17.0857 18.4412C17.0857 18.9782 16.6454 19.4118 16.1 19.4118C15.5546 19.4118 15.1143 18.9782 15.1143 18.4412V14.2353C15.1143 13.6982 15.5546 13.2647 16.1 13.2647C16.6454 13.2647 17.0857 13.6982 17.0857 14.2353V18.4412Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_35_1080">
        <rect
          width="23"
          height="22"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
