
import { api } from "@/app/api"

export const getNotifications = async () => {
  try {
    const { data } = (await api.get('/v1/messages/my'))
    return data
  } catch (error) {

    console.error(error)
  }
}
