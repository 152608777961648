import { useUser } from '@/composables/useUser';

export const guards = (to) => {
  const isAuthenticated = (path) => {
    const { isAuthenticated } = useUser();

    if (to.name !== "auth" && !isAuthenticated.value) {
      return '/auth'
    } else if (to.name === "auth" && isAuthenticated.value) {
      return '/'
    }

    return path
  }

  const publicPagesNames = ['order', 'order-ar', 'terms', 'account-showcases-products-single']
  const isPublicPage = (path) => {
    const isPageAvailable = publicPagesNames.includes(to.name);

    if (isPageAvailable) {
      return to.fullPath;
    }

    return path;
  }

  const isArPage = () => {
    const arPage = to.name === 'order-ar';
    const isPageAvailable = import.meta.env.VITE_VETMAP_AR === 'true';

    if (arPage) {
      if (isPageAvailable) {
        return to.fullPath;
      }
      const finalPath = to.fullPath.replace('/ar', '');
      return finalPath;
    }

    return to.fullPath;
  }

  return {
    isAuthenticated,
    isPublicPage,
    isArPage,
  };
};
