import { computed } from "vue"
import { useStore } from '@/states/index'

export const useUser = () => {
  const store = useStore();
  const token = computed(() => store.authToken);
  const language = computed(() => store.language);
  const isAuthenticated = computed(() => Boolean(store.authToken));

  return { isAuthenticated, token, language };
}
