<template>
  <div class="gap">
    <div class="names">
      <div class="name">
        {{ pet.payload.name }}
      </div>
      <div class="breed">
        {{ pet.payload.breed }}
      </div>
    </div>

    <ItemBase :title="t('accountView.animal.info')">
      <div class="info">
        <div
          v-if="pet.payload.sex"
          class="info-item"
        >
          <div class="info-label">
            {{ t('common.sex') }}
          </div>
          <div class="info-value">
            {{ getSex(pet.payload.sex) }}
          </div>
        </div>
        <div
          v-if="pet.payload.age"
          class="info-item"
        >
          <div class="info-label">
            {{ t('common.age') }}
          </div>
          <div class="info-value">
            {{ fromDateToAge(pet.payload.age) }}
          </div>
        </div>
        <div
          v-if="pet.payload.weight"
          class="info-item"
        >
          <div class="info-label">
            {{ t('common.weight') }}
          </div>
          <div class="info-value">
            {{ pet.payload.weight }} {{ t('common.kg') }}
          </div>
        </div>
      </div>
      <div class="block">
        <div class="block-item">
          <div class="block-label">
            {{ t('accountView.animal.castration') }}
          </div>
          <div class="block-value">
            {{ pet.payload.castration ? t('common.yes') :
              t('common.no') }}
          </div>
        </div>
        <div
          v-if="pet.payload.lastCheckup"
          class="block-item"
        >
          <div class="block-label">
            {{ t('accountView.animal.lastCheckup') }}
          </div>
          <div class="block-value">
            {{ pet.payload.lastCheckup }}
          </div>
        </div>
        <div class="block-item">
          <div class="block-label">
            {{ t('accountView.animal.chip') }}
          </div>
          <div class="block-value">
            {{ pet.payload.chip ? t('common.yes') : t('common.no')
            }}
          </div>
        </div>
      </div>
    </ItemBase>

    <ItemBase
      v-if="pet.payload.vaccinations.length"
      :title="t('accountView.animal.vac')"
    >
      <div class="block">
        <div
          v-for=" vac in pet.payload.vaccinations "
          class="block-item"
        >
          <div class="block-label">
            {{ vac.type }}
          </div>
          <div class="block-value">
            {{ vac.createdAt }}
          </div>
        </div>
      </div>
    </ItemBase>

    <ItemBase
      v-if="pet.payload.photos.length"
      :title="t('accountView.animal.photo')"
    >
      <PhotoGallery
        v-model="pet.payload.photos"
        only-view
      />
    </ItemBase>

    <ItemBase
      v-if="pet.payload.chronicIlls"
      :title="t('accountView.animal.chronic')"
    >
      <TextareaBase
        v-model="pet.payload.chronicIlls"
        :rows="6"
        disable
      />
    </ItemBase>
  </div>
</template>

<script setup>
import { fromDateToAge } from '@/helpers/fromDateToAge'
import { getSex } from '@/helpers/getSex'
import { ItemBase, TextareaBase } from '@/UI';
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

defineProps({
  pet: {
    type: Object
  }
})
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .info-item {
        background: #37363C;
    }

    .info-value {
        color: #B0B2C2;
    }

    .info-label {
        color: #fff;
    }

    .block {
        background: #37363C;
    }

    .block-item {
        border-bottom: 1px solid #56555C;

        &:last-child {
            border-bottom: none;
        }
    }

    .block-label {
        color: #B0B2C2;
    }

    .block-value {
        color: #B0B2C2;
    }

    .name {
        color: #fff;
    }

    .breed {
        color: #B0B2C2;
    }
}

.gap {
    display: grid;
    gap: 20px;
}

.block {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.05);
    padding: 0 20px;

    &-item {
        border-bottom: 1px solid #E6EAF3;

        &:last-child {
            border-bottom: none;
        }

        align-items: center;
        padding: 25px 0;
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        gap: 5px;
    }

    &-label {
        color: #5B5F7C;
        font-size: 15px;
        font-weight: 700;
        line-height: 14px;
    }

    &-value {
        color: #8E92AF;
        font-size: 15px;
        font-weight: 700;
        line-height: 14px;
    }
}

.name {
    color: #5B5F7C;
    font-size: 22px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
}

.breed {
    color: #8E92AF;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
}

.names {
    display: grid;
    grid-template-columns: minmax(0, auto);
    gap: 10px;
}

.info-item {
    padding: 18px 0;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.05);
    display: grid;
    grid-template-columns: minmax(0, auto);
    gap: 6px;
}

.info-label {
    color: #8E92AF;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
}

.info-value {
    color: #5B5F7C;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    line-height: 14px;
}

.info {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}
</style>
