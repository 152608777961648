<template>
  <PanePromise
    id="dsfsdf"
    ref="PanePromiseREF"
    fit-content
  >
    <LayoutBase gap="10">
      <ButtonBase
        danger
        @click="del"
      >
        {{ t('favorites.delete') }}
      </ButtonBase>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { ref } from 'vue';
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ButtonBase } from '@/UI'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const emit = defineEmits(['onDelete'])

const PanePromiseREF = ref()

const idValue = ref(0)
const typeValue = ref(0)

const del = () => {
  emit('onDelete', { id: idValue.value, type: typeValue.value })
  PanePromiseREF.value.close()
}

const openContext = async ({ id, type }) => {
  idValue.value = id
  typeValue.value = type

  await PanePromiseREF.value.open()
}

defineExpose({
  open: openContext
})
</script>
