import io from 'socket.io-client'

import { useStore } from '@/states/index'

export const useSocket = () => {
  const store = useStore();
  const socket = io(
    import.meta.env.VITE_WSS_HOST, {
      path: '/wss',
      auth: {
        token: store.authToken
      },
      transports: ['polling'],
    })

  socket.on('connect', () => {
    console.log('Connected socket: ', socket.id)
  })

  return { socket }
}
