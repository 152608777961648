import { Device } from '@capacitor/device';
import { useStore } from '@/states/index';
import { computed } from 'vue';

export const useDevice = () => {
  const store = useStore();

  const setDeviceType = async () => {
    let isMobileDevice = false;
    const deviceInfo = await Device.getInfo();
    isMobileDevice = deviceInfo.platform !== 'web';

    // Дополнительная проверка по userAgent
    if (isMobileDevice === false) {
      const userAgent = navigator.userAgent.toLowerCase();
      const isAndroid = userAgent.includes('android')
      const isApple = userAgent.includes('iphone')
      const isOtherMobile = userAgent.includes('touch')
        || userAgent.includes('iemobile')
        || userAgent.includes('webos')
        || userAgent.includes('blackberry')
        || userAgent.includes('windows phone')

      isMobileDevice = isAndroid || isApple || isOtherMobile
    }
    store.$patch({ isMobileDevice })
  }

  const isMobileDevice = computed(() => {
    if (import.meta.env?.VITE_BASE_DEVELOPMENT) {
      // return true;
    }
    return store.isMobileDevice;
  })

  return { setDeviceType, isMobileDevice }
}
