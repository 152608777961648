<template>
  <div
    class="pane-selector"
    :class="{ choosedPet: 'two' }"
    @click="handleClick"
  >
    <IconBase
      v-if="choosedPet"
      :path="`animals/${getItemById(choosedPet?.animal_id)?.icon}.svg`"
      class="pane-img"
    />

    <div
      class="title"
      :class="{
        'is-placeholder': !props.modelValue
      }"
    >
      {{ props.modelValue ? choosedPet.payload?.name : t('component.animalSelector.title') }}
    </div>
    <img
      src="@/assets/icons/other/small-arrow.svg"
      class="arrow"
    >
  </div>

  <PanePromise
    id="myAnimals"
    ref="PanePromiseREF"
    fit-content
  >
    <LayoutBase
      gap
      padding="20px"
      :title="t('component.animalSelector.title')"
    >
      <div class="pane-inner">
        <div
          class="item not"
          @click="emit('update:modelValue', null)"
        >
          <div class="item-title">
            {{ t('component.animalSelector.chooseNew') }}
          </div>
          <CheckboxBase :is-active="props.modelValue === null" />
        </div>
        <div
          v-for=" item in props.options "
          :key="item.id"
          class="item"
          @click="emit('update:modelValue', item.id)"
        >
          <IconBase
            :path="`animals/${getItemById(item.animal_id).icon}.svg`"
            alt=""
            class="item-img"
          />
          <div class="item-title">
            {{ item.payload?.name }}
          </div>
          <CheckboxBase :is-active="props.modelValue === item.id" />
        </div>
      </div>
      <ButtonBase
        class="btn-selector"
        @click="handleClose"
      >
        {{ t('common.choose') }}
      </ButtonBase>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { computed, ref } from 'vue'
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, CheckboxBase, ButtonBase, IconBase } from '@/UI'
import { useMainEntities } from '@/states/mainEntities'

const props = defineProps({
  modelValue: {
    type: [Number, null],
    default: null,
    required: true
  },
  options: {
    type: Array,
    default: () => [],
    required: true,
  }
})

const emit = defineEmits(['update:modelValue'])

const mainEntities = useMainEntities()

const PanePromiseREF = ref()

const getItemById = (id) => mainEntities.animalTypes.find(el => el.id === id)

const choosedPet = computed(() => props.options?.find(el => el.id === props.modelValue))

const handleClick = () => {
  PanePromiseREF.value.open({})
}

const handleClose = () => {
  PanePromiseREF.value.close()
}
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .pane-selector {
        background: #37363C;
        color: #7A7B87;
        border: 1px solid #37363C;
    }

    .pane-inner {
        background: #37363C;
        border: 1px solid #37363C;

    }

    .item-title {
        color: #fff;
    }
}

.pane-selector {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 15px 20px;
    display: flex;
    gap: 10px;

}

.title {
    padding: 5px 0;
    width: 100%;
    &.is-placeholder {
      @include font-semibold();
      color: #5B5F7C;
    }
}

.btn-selector {
    position: sticky;
    bottom: 20px;
}

.pane-inner {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 20px 25px;
    display: grid;
    gap: 40px;
    overflow-y: auto;

}

.item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 20px;

    &-title {
        color: #645C56;
        font-size: 15px;
        font-weight: 600;
        line-height: 14px;
    }

    &-img {
        width: 20px;
        height: 20px;
    }

    &.not {
        grid-template-columns: 1fr auto;
    }
}

.pane-img {
    width: 30px;
    height: 30px;
}

.arrow {
    transform: rotate(-90deg);
}
</style>
