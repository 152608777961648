<template>
  <div
    class="messageWrapper"
    @click="scrollToReply"
  >
    <div
      class="message"
      :class="{ isMe: !props.isMe, isPressed: isPressed }"
      :style="`transform: translateX(-${x}px)`"
      @touchmove.passive="touchmove"
      @touchend.passive="touchend"
    >
      <div
        class="body"
        :class="{ isMe: !props.isMe }"
      >
        <!-- <div class="header">
                    <div class="name">{{ user.name }}</div>
                    <div class="time">{{ format(props.message.created_at, 'ru') }}</div>
                </div> -->
        <ImgLazy
          v-if="props.message.image"
          class="image"
          :src="props.message.image"
        />
        {{ props.message.message }}
        <div class="time">
          {{ format(props.message.created_at, 'ru') }}
        </div>
        <div
          v-if="props.replyMessage"
          class="replyMsg"
        >
          <div class="replyName">
            {{ userReply.name }}
          </div>
          <ImgLazy
            v-if="props.replyMessage.image"
            class="image"
            :src="props.replyMessage.image"
          />
          {{ props.replyMessage.message }}
        </div>
      </div>
      <ImgLazy
        v-if="user?.photo"
        rounded
        class="img"
        :class="{ isMe: !props.isMe }"
        :src="user?.photo"
      />
      <img
        v-else
        :class="{ isMe: !props.isMe }"
        src="@/assets/icons/other/no-photo.svg"
      >
    </div>
    <img
      src="@/assets/icons/other/reply.svg"
      :class="{ show: isPressed && moreThan }"
      class="reply"
    >
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { format, register } from 'timeago.js';
import { ImgLazy } from '@/UI'
import { ruLocale } from './locale'
const changedX = ref(0)
const x = computed(() => changedX.value - (changedX.value / 2))
const moreThan = computed(() => x.value > 60)
const isPressed = ref(false)

let startPosition = null

register('ru', ruLocale);

const touchmove = ({ targetTouches }) => {
  if (props.draggble) {return}
  isPressed.value = true
  const { clientX, clientY } = targetTouches[0]
  if (!startPosition) {
    startPosition = { x: clientX, y: clientY }
  }
  const xDiff = clientX - startPosition.x
  const yDiff = clientY - startPosition.y

  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    changedX.value = Math.abs(xDiff < 0 ? xDiff : 0)
  }
}

const touchend = () => {
  if (x.value > 60) {emit('reply', props.message.id)}

  isPressed.value = false
  startPosition = 0
  changedX.value = 0
}

const props = defineProps({
  message: {
    type: Object,
    require: true
  },
  replyMessage: {
    type: Object || null,
    default: null
  },
  user: {
    type: Object,
    require: true
  },
  userReply: {
    type: Object || null,
    default: null
  },
  isMe: {
    type: Boolean,
    require: true
  },
  draggble: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['scrollToReply', 'reply'])
const scrollToReply = () => {
  if (!props?.replyMessage?.id) {return}
  emit('scrollToReply', props.replyMessage.id)
}
</script>

<style lang="scss" scoped>
[theme=dark] {
    .body {
        background: #37363C;
        border: 1px solid #37363C;
        color: #fff;
    }

    .reply {
        background: #37363C;
    }
}

.messageWrapper {
    display: grid;
    position: relative;
}

.time {
    font-family: "NunitoLight";
    font-size: 14px;
    // position: absolute;
    right: 8px;
    bottom: 15px;
    text-align: right;
}

.replyMsg {
    border-radius: 10px;
    background: #5B43EF;
    padding: 7px;
    display: grid;
    gap: 10px;

    color: #FFF;
    font-size: 14px;
    font-weight: 500;
}

.image {
    width: 100%;
    height: 100px;
}

.replyName {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.reply {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50px, -50%);

    border-radius: 40px;
    border: 1px solid #F2F4FC;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    transition: all .3s ease;
    opacity: 0;

    &.show {
        transform: translate(0, -50%);
        opacity: 1;
    }
}

.message {
    display: grid;
    align-items: end;
    grid-template-columns: 1fr auto;
    gap: 10px;
    width: fit-content;
    justify-self: end;
    transition: all .3s ease;

    &.isPressed {
        transition: none;
    }

    &.isMe {
        grid-template-columns: auto 1fr;
        justify-self: start;
    }
}

.img {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    box-shadow: 0px 5px 20px 0px #00000040;

    &.isMe {

        filter: hue-rotate(80deg);
    }
}

.name {
    color: #4655C4;
    font-size: 15px;
    font-weight: 700;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.body {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 15px;
    display: grid;
    // gap: 10px;
    position: relative;
    min-width: 200px;

    &.isMe {
        order: 10;
    }

}

.header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 5px;
}
</style>
