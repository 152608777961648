<template>
  <HeaderFullpage
    desctop
    :title="t('category.title')"
  />
  <LayoutBase gap="20">
    <MenuInput
      ref="input"
      v-model="query"
      class="input-menu"
      :placeholder="t('category.title')"
      :readonly="position === 'bottom'"
      type="text"
      @click="setFocus"
    />
    <CategorySelector />
    <div class="orderWrapper">
      <OrderItem
        v-for=" item in fetchedOrders "
        :current-position="myPosition"
        :info="item"
      />
    </div>
  </LayoutBase>
  <FollowElements>
    <BackButton />
  </FollowElements>
</template>

<script setup>
import { HeaderFullpage } from '@/UI'
import { ref, onMounted, watchEffect } from 'vue'
import { MenuInput } from '@/UI/MenuInput';
import { usePane } from '@/components/PaneMain'
import { LayoutBase } from '@/components/Layouts';
import { FollowElements } from '@/components/PaneMain';
import { BackButton } from '@/UI'
import { CategorySelector } from '@/components/CategorySelector'
import { useCategoryStore } from '@/states/categoryStore';
import { getOrders } from '@/app/api/orders'
import { OrderItem } from './components/OrderItem'
import { getCurrentPosition } from '@/helpers/getCurrentPosition';
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { categoryChoosed } = useCategoryStore()
const { myPane, position } = usePane()
const input = ref()
const query = ref('')
const myPosition = ref(null)
const fetchedOrders = ref(null)

watchEffect(async () => {
  const { orders } = await getOrders({ categoryId: categoryChoosed.value, text: query.value })
  fetchedOrders.value = orders
})

const init = async () => {
  myPosition.value = (await getCurrentPosition()).coords
}

const setFocus = async () => {
  if (position.value === 'bottom') {
    myPane.value.moveToBreak('middle')
    return
  }
  input.value?.focus()
}

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
// .input-menu {
//     position: fixed;
//     right: 0;
//     left: 0;
// }

.query {
    text-align: center;
    color: #6B7090;
    font-size: 17px;
    font-weight: 700;
    margin-top: 20px;
}

.orderWrapper {
    display: grid;
    gap: 10px;
    margin-top: 20px;
}
</style>
