import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export const sexOptions = computed(() => {
  const { t } = useI18n()
  return ([
    {
      value: 'male',
      label: t('common.male'),
    },
    {
      value: 'female',
      label: t('common.female'),
    },
    {
      value: 'sexless',
      label: t('common.sexless'),
    },
  ])

})

export const castrationOptions = computed(() => {
  const { t } = useI18n()
  return ([
    {
      value: true,
      label: t('common.yes'),
    },
    {
      value: false,
      label: t('common.no'),
    },
  ])
})

export const chipOptions = computed(() => {
  const { t } = useI18n()
  return ([
    {
      value: true,
      label: t('common.has'),
    },
    {
      value: false,
      label: t('common.notHas'),
    },
  ])
})
