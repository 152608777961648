<template>
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4999 13.5C12.5421 13.5 14.1976 11.933 14.1976 10C14.1976 8.067 12.5421 6.5 10.4999 6.5C8.45766 6.5 6.80212 8.067 6.80212 10C6.80212 11.933 8.45766 13.5 10.4999 13.5Z"
      fill="currentColor"
    />
    <path
      d="M7.48892 0.5L5.65218 2.38889H2.4705C1.36645 2.38889 0.463135 3.23889 0.463135 4.27778V15.6111C0.463135 16.65 1.36645 17.5 2.4705 17.5H18.5294C19.6335 17.5 20.5368 16.65 20.5368 15.6111V4.27778C20.5368 3.23889 19.6335 2.38889 18.5294 2.38889H15.3478L13.511 0.5H7.48892ZM10.5 14.6667C7.72981 14.6667 5.48155 12.5511 5.48155 9.94444C5.48155 7.33778 7.72981 5.22222 10.5 5.22222C13.2701 5.22222 15.5184 7.33778 15.5184 9.94444C15.5184 12.5511 13.2701 14.6667 10.5 14.6667Z"
      fill="currentColor"
    />
  </svg>
</template>
