
import { api } from "@/app/api"

export const callPhoneV1 = async ({ info, phone, token, version }) => {
  try {
    const { data } = (await api.post('/v1/auth/phone', { info, phone, token, version }))
    return data
  } catch (error) {
    console.error(error)
  }
}
export const callPhoneV2 = async ({ info, phone, token, version }) => {
  try {
    const { data } = (await api.post('/v2/auth/phone', { info, phone, token, version }))
    return data
  } catch (error) {
    console.error(error)
  }
}
export const auth = async ({ auth, pincode }) => {
  try {
    const { data } = (await api.post('/v1/auth/sms', { auth, pincode }))
    return data
  } catch (error) {
    console.error(error)
  }
}
export const sendSms = async ({ auth }) => {
  try {
    const { data } = (await api.post('/v1/auth/sms-send', { auth }))
    return data
  } catch (error) {
    console.error(error)
  }
}
export const sendPhone = async ({ auth }) => {
  try {
    const { data } = (await api.post('/v1/auth/call-send', { auth }))
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getVersion = async () => {
  try {
    const { data } = (await api.get('/version'))
    return data
  } catch (error) {
    console.error(error)
  }
}

export const sendMessageToSupport = async ({ subject, text, phone }) => {
  try {
    const { data } = await api.post('/v1/support', { subject, text, phone } )
    return data
  } catch (error) {
    console.error(error)
  }
}
