<template>
  <div class="loader">
    <SpinerBase :class="props.size" />
  </div>
</template>

<script setup>
import { SpinerBase } from '@/UI'

const props = defineProps({
  size: {
    type: String,
    default: 'l'
  }
})
</script>

<style lang="scss" scoped>
.loader {
    color: #C6C8DA;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;

    .l {
        transform: scale(2.5);

    }
}
</style>
