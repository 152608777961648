<template>
  <AccountTemplate
    :custom-page="customPage"
    page-type="create"
  />
</template>

<script setup>
import { AccountTemplate } from '../../AccountTemplate/'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
const { t } = useI18n()

const customPage = computed(() => ({
  headerTitle: t('accounts.accountCreate'),
  buttonText: t('accounts.accountCreate'),
}))
</script>
