<template>
  <div class="showcases__form">
    <div class="showcases__form__row">
      <InputBase
        v-model="payload.name"
        :label="t('showcase.prices.priceName')"
        class="showcases__form__row__field"
      />
    </div>
    <div class="showcases__form__row">
      <TextareaBase
        v-model="payload.description"
        :label="t('showcase.prices.priceDescription')"
        class="showcases__form__row__field"
      />
    </div>
  </div>

  <div class="showcases__form__row" style="margin-bottom: 10px;">
    <div class="price-description">
      {{ t('showcase.prices.uploadDescription', {value: AVAILABLE_FORMATS}) }}
    </div>
  </div>

  <div class="showcases__form__row" style="display: flex; flex-direction: column;">
    <div class="price-description">
      <div style="display: flex; align-items: center; gap: 8px;">
        {{ fileName }}

        <img
          v-if="fileName"
          class="delete-icon"
          src="@/assets/icons/other/add.svg"
          @click="onReset"
        >
      </div>
    </div>
    <ButtonBase
      class="add-characteristics__btn"
      variant="text"
      @click="openDialogToUpload"
    >
      <template #prepend-icon>
        <PlusIconOutlined />
      </template>
      {{ t('showcase.prices.addPrices') }}
    </ButtonBase>
  </div>

  <slot
    name="actions"
    :item="payload"
  />
  <ButtonBase :loading="isButtonLoading" @click="submit">
    <template v-if="type === 'update'">
      {{ t('common.save') }}
    </template>
    <template v-else>
      {{ t('common.add') }}
    </template>
  </ButtonBase>
</template>

<script setup>
import { ref, computed } from 'vue'
import { ButtonBase, InputBase, TextareaBase } from '@/UI'
import { useFileDialog } from '@vueuse/core';
import PlusIconOutlined from '@/assets/icons/other/PlusIconOutlined.vue'

import { ShowcasesItemTypes } from '@/screens/Account/constants/showcases'

import { api } from "@/app/api"

const props = defineProps({
  predefinedData: {
    type: Object,
    default: () => {},
  },
  handler: {
    type: Function,
    default: () => {},
  },
  type: {
    type: String,
    validate(value) {
      return ['create', 'update'].includes(value)
    },
    default: 'create',
  },
})

const payload = ref({
  name: props.predefinedData?.name ?? '',
  description: props.predefinedData?.description ?? '',
  // filePath: props.predefinedData?.filePath ?? null,
  // fileName: props.predefinedData?.fileName ??'',
  files: props.predefinedData?.files ?? [],
  type: ShowcasesItemTypes.prices,
})

const uploadFile = async () => {
  const file = files.value[0];
  const formData = new FormData()
  formData.append('file', file)

  try {
    const { data } = await api.post('/v1/files/upload', formData )
    const isSuccess = data.success;
    if (isSuccess) {
      payload.value.files = [{
        path: data.paths[0].path,
        name: data.paths[0].name,
      }]
    }
    return { isSuccess }
  } catch (error) {
    console.log(error);
  }
}

const AVAILABLE_FORMATS = 'doc/docx/pdf/xls/xlsx';

const { open: openDialogToUpload, files, reset } = useFileDialog({
  multiple: false,
  reset: false,
  accept: `file/*, ${AVAILABLE_FORMATS}`
})

const onReset = () => {
  if (files.value?.length) {
    reset();
  }
  if (payload.value.files?.length) {
    payload.value.files = [];
  }
}

const fileName = computed(() => {
  if(payload.value.files?.length) {
    return payload.value.files[0].name
  }

  if (files.value?.length) {
    return files.value[0].name
  }

  return '';
})

const isButtonLoading = ref(false);
const submit = async () => {
  isButtonLoading.value = true

  if (files.value?.length) {
    await uploadFile()
  }

  props.handler(payload.value).finally(() => {
    isButtonLoading.value = false
  })
}
</script>
