<template>
  <Teleport to="body">
    <div
      :class="props.id"
      class="border-radius-fix"
    >
      <layout-base gap="30">
        <div class="title2">
          <slot> Вы уверены что хотите это сделать? </slot>
        </div>
        <div class="actions">
          <button-base
            class="actions__item"
            danger
            @click="yes"
          >
            Да, уверен
          </button-base>
          <button-base
            class="actions__item"
            @click="no"
          >
            Нет
          </button-base>
        </div>
      </layout-base>
    </div>
  </Teleport>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { CupertinoPane } from 'cupertino-pane'
import { useDevice } from '@/composables/useDevice';
import { LayoutBase } from '@/components/Layouts'
import { ButtonBase } from '@/UI'

const isInit = ref(false)

let popupController

const props = defineProps({
  id: {
    type: String,
    default: 'cupertinoUI',
  },
  settings: {
    type: Object,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fitContent: {
    type: Boolean,
    default: false,
  },
  persist: {
    type: Boolean,
    default: false,
  },
})

let myPane
const { isMobileDevice } = useDevice();
onMounted(() => {
  myPane = new CupertinoPane(`.${ props.id}`, {
    backdrop: true,
    initialBreak: 'top',
    fitScreenHeight: false,
    cssClass: `cupertinoUI ${ isMobileDevice.value ? '' : 'desctop'}`,
    lowerThanBottom: false,
    topperOverflow: true,
    fitHeight: true,
    preventClicks: isMobileDevice.value,
    bottomClose: false,
    fastSwipeClose: false,
    showDraggable: false,
    buttonDestroy: false,
    maxFitHeight: window.innerHeight * 0.9,

    breaks: {
      top: {
        enabled: true,
        height: window.innerHeight * 0.8,
      },
      middle: {
        enabled: false,
        height: 200,
      },
      bottom: {
        enabled: false,
        height: 100,
      },
    },
    ...props.settings,
  })

  myPane.on('onDidDismiss', () => {
    // myPane.destroy({ animate: true });
  })
  myPane.on('onBackdropTap', () => {
    if (props.persist) {return}

    popupController.resolve(false)
    myPane.destroy({ animate: true })
  })
  myPane.on('onWillPresent', () => {
    if (props.disabled) {
      setTimeout(() => {
        myPane.disableDrag()
      }, 0)
    }
  })
})

onUnmounted(() => {
  // myPane.destroy({ animate: false });
})

const open = async () => {
  isInit.value = true
  await new Promise((res) => setTimeout(res, 300))

  let resolve
  let reject
  const popupPromise = new Promise((ok, fail) => {
    resolve = ok
    reject = fail
  })
  popupController = { resolve, reject }
  myPane.present({ animate: true })
  return popupPromise
}

const yes = () => {
  popupController.resolve(true)
  myPane.hide({ animate: true })
}
const no = () => {
  popupController.resolve(false)
  myPane.hide({ animate: true })
}

defineExpose({
  open,
  close: () => {
    myPane.hide({ animate: true })
    return popupController.resolve(false)
  },
})
</script>

<style lang="scss" scoped>
.cupertinoUI {
  z-index: 150;
}

.desctop > .pane {
  width: 500px !important;
  max-width: 500px !important;
}

.title2 {
  color: #6b7090;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.actions {
  display: grid;
  gap: 10px;

  &__item {
    width: 100%;
    height: 60px;
  }
}
</style>
