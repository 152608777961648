<template>
  <div
    v-if="!props.desctop || !isMobileDevice"
    class="headerWrapper"
  >
    <div
      class="safeArea"
      :style="`height:${SafeAreaView.top}px`"
    />
    <div class="inner">
      <slot>
        <div class="button" @click="event => $emit('back', event)">
          <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="navigateBack"
          >
            <path
              d="M18.414 6.707H3.828L9.121 1.414L7.707 0L0 7.707L7.707 15.414L9.121 14L3.828 8.707H18.414V6.707Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <div
          v-show="title"
          class="title"
        >
          {{ props.title }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { SafeAreaView } from '@/helpers/getSafeArea'
import { useDevice } from '@/composables/useDevice';
import { useNavigation } from "@/composables/useNavigation";

const { isMobileDevice } = useDevice();

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  desctop: {
    type: Boolean,
    default: false,
  },
  hasCustomNavigation: {
    type: Boolean,
    default: false,
  }
})
defineEmits(['back']);

const { goBack } = useNavigation();

const navigateBack = () => {
  if (!props.hasCustomNavigation) {
    goBack();
  }
}
</script>

<style lang="scss" scoped>
[theme="dark"] {
  .headerWrapper {
    background: #37363C;
    border-bottom: 1px solid #37363C;
  }

  .button {
    color: #fff;
  }
}

.inner {
  display: grid;
  grid-template-columns: 38px 1fr;
  padding: 25px 20px;
  align-items: center;
  box-sizing: border-box;
}

.button {
  display: flex;
  cursor: pointer;
}

.headerWrapper {
  border-bottom: 1px solid #E7E7E7;
  background: #FFF;
  position: sticky;
  top: 0;
  z-index: 12;
}

.safeArea {
  background: inherit;
}

.title {
  color: #000000;
  font-size: 20px;
  font-weight: 600;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
