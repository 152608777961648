<template>
  <div class="input-base-phone">
    <InputBase
      v-maskito="options"
      :model-value="modelValue"
      v-bind="$attrs"
      :maxlength="21"
      type="tel"
      inputmode="numeric"
      @input="onInput"
      @enter="$emit('enter')"
    />

    <flag
      class="country-flag"
      :iso="isoCode"
      :squared="false"
    />
  </div>
</template>

<script setup>
import metadata from 'libphonenumber-js/min/metadata';
import { getCountryCallingCode } from 'libphonenumber-js';
import { maskito as vMaskito } from '@maskito/vue';
import { maskitoPhoneOptionsGenerator, maskitoGetCountryFromNumber } from '@maskito/phone';

import InputBase from './InputBase.vue';
import { computed, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  countryCode: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue', 'update:countryCode', 'enter']);

const onInput = (event) => {
  emit('update:modelValue', event.target.value);
}

const maskitoCountryCode = computed(() => {
  const res = maskitoGetCountryFromNumber(props.modelValue, metadata)
  return res;
})
const isoCode = computed(() => props.code || maskitoCountryCode.value)
const options = computed(() => maskitoPhoneOptionsGenerator({ countryIsoCode: isoCode.value, metadata, strict: false }));

watch(computed(() => props.modelValue), () => {
  if (maskitoCountryCode.value === undefined) {
    emit('update:countryCode', maskitoCountryCode.value)
  }
  if (props.modelValue === '+7') {
    emit('update:countryCode', 'RU')
  }
  if (maskitoCountryCode.value !== props.countryCode && maskitoCountryCode.value) {
    emit('update:countryCode', maskitoCountryCode.value)
  }
})

watch(computed(() => props.countryCode), () => {
  if (props.countryCode && props.countryCode !== maskitoCountryCode.value) {
    const incomeParsedCode = getCountryCallingCode(props.countryCode)
    if (incomeParsedCode) {
      emit('update:modelValue', `+${incomeParsedCode}`)
    }
  }
})

</script>

<style lang="scss">
.input-base-phone {
  position: relative;
  width: 100%;
  .country-flag {
    position: absolute;
    top: 28px;
    right: 20px;
  }
}
</style>
