import { SafeArea } from 'capacitor-plugin-safe-area';
import { Device } from '@capacitor/device';
import { reactive } from 'vue'

export const SafeAreaView = reactive({ top: 0, left: 0, right: 0, bottom: 0 })

const init = async () => {
  try {

    if ((await Device.getInfo()).platform === 'web') {
      return
    }
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      const { ...areas } = insets;
      Object.assign(SafeAreaView, areas)
    });
  } catch (error) {
    console.log("Device not found")
  }

}

export const safeAreaPromise = async () => {
  try {
    if ((await Device.getInfo()).platform === 'web') {
      return { top: 0, left: 0, right: 0, bottom: 0 }
    }

    const { insets } = await SafeArea.getSafeAreaInsets()
    return insets
  } catch (error) {
    console.log("Device not found")
  }
}
init()
