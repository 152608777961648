<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8929 12.6086L18.7348 17.4505C18.905 17.6208 19.0005 17.8518 19.0004 18.0925C19.0004 18.3333 18.9046 18.5641 18.7343 18.7343C18.564 18.9045 18.3331 19.0001 18.0923 19C17.8516 18.9999 17.6207 18.9042 17.4505 18.7339L12.6086 13.892C11.1612 15.0131 9.34104 15.5406 7.51844 15.3674C5.69585 15.1941 4.00771 14.333 2.79746 12.9593C1.58722 11.5855 0.945763 9.80231 1.0036 7.97241C1.06143 6.14251 1.81421 4.40337 3.10879 3.10879C4.40337 1.81421 6.14251 1.06143 7.97241 1.0036C9.80231 0.945763 11.5855 1.58722 12.9593 2.79746C14.333 4.00771 15.1941 5.69585 15.3674 7.51844C15.5406 9.34104 15.0131 11.1612 13.892 12.6086H13.8929ZM8.20051 13.5995C9.63265 13.5995 11.0061 13.0306 12.0188 12.0179C13.0315 11.0052 13.6004 9.63175 13.6004 8.19961C13.6004 6.76748 13.0315 5.39399 12.0188 4.38132C11.0061 3.36865 9.63265 2.79973 8.20051 2.79973C6.76838 2.79973 5.3949 3.36865 4.38222 4.38132C3.36955 5.39399 2.80063 6.76748 2.80063 8.19961C2.80063 9.63175 3.36955 11.0052 4.38222 12.0179C5.3949 13.0306 6.76838 13.5995 8.20051 13.5995Z"
      fill="currentColor"
      stroke="#555555"
      stroke-width="0.5"
    />
  </svg>
</template>
