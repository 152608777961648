<template>
  <div
    ref="cupertino"
    class="cupertino"
    :class="{ 'is-desktop': !isMobileDevice }"
  >
    <div id="content">
      <slot />
    </div>
    <div class="followerElement">
      <div class="wrapper">
        <div id="followElement" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount } from 'vue';
import { usePane } from './usePane'
import { useDevice } from '@/composables/useDevice';

const { isMobileDevice } = useDevice();
const { myPane, isInit } = usePane()

onBeforeUnmount(() => {
  if (myPane.value) {
    myPane.value.destroy({ animate: false });
  }
  isInit.value = false;
})

const movePaneDown = () => {
  if (myPane.value) {
    myPane.value.moveToBreak('bottom');
  }
};

defineExpose({ movePaneDown })
</script>

<style lang="scss" scoped>
[theme="dark"] {
  .is-desktop {
    background: #292929;
  }
}

.cupertino {
  overflow-y: auto !important;
}

.followerElement {
  position: absolute;
  bottom: 100%;
  width: 100%;
}

.is-desktop {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--desktop-pane-width);
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 0px 18px 4px rgb(119, 119, 119);
  border-radius: 0;

  :deep(.add-entity-button) {
    position: fixed;
    right: auto;
    left: 320px;
  }
}

.backBtn {
  position: absolute;
  top: 0;
  left: 500px;
}
</style>
