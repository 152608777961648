<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.70596e-07 8.47314L12.5973 8.47314L7.89244 13.624L9.14933 15L16 7.5L9.14933 -5.98904e-07L7.89244 1.37602L12.5973 6.52686L7.40745e-07 6.52686L5.70596e-07 8.47314Z"
      fill="#5B5F7C"
    />
  </svg>
</template>
