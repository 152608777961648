export const terms = [
  {
    title: '1. Введение',
    text: `Благодарим вас за то, что вы выбираете VetMap! Мы ценим оказанное нам доверие и стремимся защищать конфиденциальность и безопасность переданной нам персональной информации. Предоставляемая информация помогает нам оказывать качественные услуги. Для защиты вашей персональной информации, мы внедрили достаточные технические и организационные меры от несанкционированного, случайного или незаконного уничтожения, потери, изменения, недобросовестного использования, раскрытия или доступа, а также иных незаконных форм обработки.

        Настоящая политика конфиденциальности, описывает наш подход к обеспечению конфиденциальности на всех веб-сайтах, платформах и сервисах, связанных с деятельностью VetMap. Просим вас ознакомиться с политикой конфиденциальности на нашем сайте https://vetmap.ru/privacy/
        
        VetMap собирает, использует, обрабатывает и передает ваши персональные данные и информацию (далее – «персональные данные» или «персональная информация») только с вашего согласия, и/или согласия вашего законного представителя, путем совершения конклюдентных действий при использовании любых веб-сайтов, принадлежащих VetMap, в том числе в процессе передачи информации, при совершении любых операций и/или использования, платформ, программ, приложений, продуктов, при оплате товаров и услуг, в наших магазинах на кассах и аппаратах самообслуживания, в процессе общения с операторами нашей горячей линии, оформления заказов, регистрации в личном кабинете и подписки на рассылку (далее – Сервисы).
        
        Предоставляемая нам персональная информация может быть передана нашим партнерам (ранее и далее — «Партнеры»), в целях исполнениям нами своих обязательств в связи с использованием вами наших Сервисов. В таких случаях передача персональной информации возможна только в случаях, установленных действующим законодательством РФ.
        
        Пожалуйста, обратите внимание, что использование любого Сервиса может регулироваться дополнительными условиями, положениями применимого законодательства и VetMap могут вносить в настоящую Политику изменения и/или дополнения в любое время.
        
        Как мы используем вашу информацию?
        
        Мы используем вашу информацию для предоставления Вам услуг в Сервисах VetMap. Ваша информация используется для настройки учетной записи, упрощения обмена сообщениями, заполнения заявок на услуги, обеспечения поддержки, предоставления доступа к функциям приложений и иных платформ, а также усовершенствования наших сервисов. VetMap использует предоставленные вами каналы связи, такие как номер мобильного телефона или адрес электронной почты, для проверки и защиты вашей учетной записи, а также для администрирования ресурса и рассылки Вам рекламных материалов.
        
        Кто может получать доступ к вашей информации?
        
        Поскольку мы используем дополнительные услуги третьих лиц, такие как услуги передачи SMS-сообщений для проверки учетных записей, для осуществления эквайринга операций, мапирования данных и обслуживания точек интереса, а также услуг по переводу и поддержке.
        
        Эти услуги третьих лиц используются исключительно для обработки или хранения вашей информации в целях, описанных в настоящих правилах. Помимо вышеприведенных целей, VetMap не передает ваши данные третьим лицам без вашего предварительного согласия. Любое третье лицо (выбранное нами), с кем мы обмениваемся вашими данными, будет предоставлять идентичную или аналогичную защиту этих данных.`
  },
  {
    title: '2. Термины и определения',
    text: `Если определение термина не указано в настоящей Политике (например, для терминов «персональные данные» или «аффилированные лица»), то подобные определения используются в значении, указанном в применимом законодательстве.

        2.1. Термины «VetMap», «мы», «нас» или «наш» обозначают компанию ООО «ФК ДЕВЕЛОПМЕНТ» (ОРГН: 1217700430903), юридическое лицо, созданное по законодательству Российской Федерации и зарегистрированное по адресу: 117556, г. Москва, Варшавское ш., д. 77, к. 2, помещ. II, ком. 10, которая несет ответственность за данные пользователей согласно настоящей Политике конфиденциальности.
        
        2.2. Термины «Пользователь», «вы», «ваш», или «вам» обозначает физическое лицо, подтверждающее свою дееспособность и дающее согласие на обработку своих персональных данных.`
  },
  {
    title: '3. Политика конфиденциальности',
    text: `Настоящая политика конфиденциальности (ранее и далее – «Политика») разработана ООО «ФК ДЕВЕЛОПМЕНТ» и определяет условия передачи, сбора, хранения и обработки персональной информации пользователя при использовании им Сервисов. Политика распространяются также на отношения, связанные с правами и интересами третьих лиц, не являющимися Пользователями Сервисов, но чьи права и интересы могут быть затронуты в результате действий пользователей.

        3.1. Настоящая Политика является юридически обязательным соглашением между вами и VetMap, предметом которого является обеспечение сохранности, Вашей персональной информации.
        
        3.2. Пользователь обязан полностью ознакомиться с настоящей политикой до момента регистрации и начала использования Сервисов. Использование Сервисов означает полное и безоговорочное принятие Пользователем настоящей политики, а также то, что Пользователь подтверждает свою дееспособность и дает согласие на обработку своих персональных данных (152-ФЗ «О персональных данных») и получение рекламных материалов (ст.18 п.1. 38-ФЗ "О рекламе").
        
        3.3. Защита ваших персональных данных и вашей конфиденциальности важны для VetMap. Поэтому при использовании вами Сервисов, VetMap защищает и обрабатывает Вашу Персональную информацию в строгом соответствии с действующим законодательством.
        
        Обработка персональных данных означает:
        · Сбор
        · Запись
        · Систематизация
        · Накопление
        · Хранение
        · Уточнение (обновление, изменение)
        · Извлечение
        · Использование
        · Передача (распространение, предоставление, доступ)
        · Обезличивание
        · Блокирование
        · Удаление
        · Уничтожение персональных данных.
        
        3.4. Настоящая Политика может быть изменена и/или дополнена VetMap в одностороннем порядке без какого-либо специального уведомления. Настоящая Политика являюется открытым и общедоступным документом. Действующая редакция Политики располагается в сети Интернет по адресу: https://vetmap.ru/privacy/. VetMap рекомендует Пользователям регулярно проверять условия настоящей Политики на предмет ее изменения и/или дополнения. Продолжение использования Сервисов Пользователем после внесения изменений и/или дополнений в настоящую Политику означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.
        
        3.5. Политика содержит сведения, подлежащие раскрытию в соответствии с ч. 1 ст. 14 ФЗ «О персональных данных», и является общедоступным документом.
        
        3.6. Использование некоторых функций наших сервисов, возможно только с Вашего согласия на обработку вашей информации.`
  },
  {
    title: '4. Какую информацию мы собираем',
    text: `Мы собираем следующую информацию:

        4.1 Информация, которую вы предоставляете нам.
        
        4.1.1 Информация, необходимая для использования Сервисов.
        
        Мы запрашиваем и собираем следующие персональные данные, когда вы используете Сервисы. Эта информация необходима нам для надлежащего исполнения договорных и/или иных обязательств перед вами, а также для соответствия требованиям законодательства. Без этой информации предоставление некоторых услуг может оказаться невозможным.
        
        · Регистрационная информация. При регистрации в наших Сервисах мы запрашиваем определенную информацию, в том числе ваше имя, фамилию, адрес электронной почты и дату рождения.
        · Информация профиля и Заявок. Для использования некоторых функций Сервисов, вам может потребоваться предоставить дополнительную информацию, в том числе адрес, номер телефона и фотографию профиля, а главное – информацию о Ваших питомцах.
        · Информация для проверки личности. Чтобы обеспечить безопасность и взаимное доверие, мы можем собирать информацию для подтверждения личности (в том числе изображение удостоверения личности государственного образца, паспорта, национального удостоверения личности или водительских прав, если это разрешено действующим законодательством) или иную идентификационную информацию.
        · Платежная информация. Когда вы используете некоторые функции Сервисов (в том числе при оплате товаров или услуг), мы можем потребовать предоставления определенной платежной информации (например, информацию о банковском счете или карте), чтобы обеспечить обработку платежей.
        · Взаимодействие с другими Пользователями. Когда вы общаетесь с администрацией сервисов или используете Сервисы для общения с другими Пользователями, мы собираем информацию о вашем общении, а также иную информацию, которую вы предоставляете.
        
        4.1.2 Информация, которую вы предоставляете нам по собственной инициативе.
        
        Вы можете по собственному желанию предоставить нам другие персональные данные, чтобы улучшить взаимодействие с Сервисами. Обработка таких данных осуществляется в соответствии с действующим законодательством.
        
        · Дополнительная информация профиля. Вы можете по собственному желанию предоставить нам другую информацию для размещения в своем профиле в Сервисах, в том числе пол, предпочтительные языки общения, город и рассказ о себе. В зависимости от настроек и/или особенностей вашего профиля в Сервисах, некоторая информация может отображаться на общедоступной странице профиля.
        · Другая информация. Вы можете по собственному желанию предоставить нам другую информацию при заполнении формуляров и опросов, обновлении или добавлении информации в Сервисах, отправке сообщений в форумы сообщества, участии в рекламных акциях, при взаимодействии с командой технической поддержки, когда вы делитесь впечатлениями с нами (например, с помощью контакт - центра), а также при использовании других функций Сервисов.
        
        4.1.3 Информация, необходимая для использования Платежных услуг.
        
        Указанная ниже информация необходима Оператору платежных данных для надлежащего исполнения договорных обязательств перед вами, а также для соответствия требованиям законодательства. Непредоставление этой информации делает использование некоторых Сервисов невозможным.
        
        · Платежная информация. Платежная информация. При использовании вами Платежных услуг, для обработки платежей и выполнения законодательных требований Оператору платежных данных необходима определенная платежная информация (например, информация о банковском счете или карте).
        · Проверка удостоверения личности и другая информация. В случае, если вы являетесь лицом совершающим операции связанные с внесением денежных средств, то VetMap (его представители) может запросить информацию для подтверждения личности (в том числе изображение удостоверения личности государственного образца, паспорта, национального удостоверения личности или водительских прав), а также иную идентификационную информацию: вашу дату рождения, адрес, адрес электронной почты, номер телефона и другую информацию, чтобы идентифицировать вашу личность, предоставить Платежные услуги и обеспечить выполнение требований законодательства
        
        4.1.4 Информация, которую мы автоматически собираем при использовании вами Сервисов.
        
        Когда вы используете Сервисы, мы автоматически собираем персональную информацию об используемых вами сервисах и способах их использования. Эта информация необходима нам для надлежащего исполнения договорных обязательств перед вами, для соответствия требованиям законодательства, а также для улучшений функции Сервисов.
        
        · Информация о местоположении. Когда вы пользуетесь определенными функциями Сервисов, мы можем собирать информацию о вашем точном или примерном местоположении, в том числе по IP-адресу или по данным GPS-датчика на вашем мобильном устройстве, чтобы улучшать качество обслуживания. Большинство мобильных устройств позволяют вам контролировать или отключать функции определения местоположения для приложений в меню настроек.
        · Статистика использования. Мы собираем информацию о вашем взаимодействии с Сервисами, в частности информацию о просмотренных вами страницах или контенте, выполненных запросах при поиске, созданных переходах и других действиях через Сервисы.
        · Данные журнала и Информация об устройстве. Мы автоматически собираем данные журнала событий и информацию об устройстве при использовании вами Сервисов или доступе к ним, даже если вы не зарегистрировали и не выполнили вход в Сервисы. В частности, мы собираем следующую информацию: информацию о том, как вы использовали Сервисы (в том числе факты переходов по ссылкам на сторонние приложения), IP-адрес, время осуществления доступа, информацию о программном и аппаратном обеспечении, информацию об устройстве, информацию о событиях устройства, уникальные идентификаторы, информацию о сбоях, данные cookie-файлов, а также информацию о страницах, которые вы просматривали до или после использования Сервисов.
        · Cookie-файлы и схожие технологии. При использовании наших сервисов, мобильного приложения и взаимодействии с нашей рекламой в Интернете и электронной почте, мы используем файлы «cookie» и подобные технологии. Для сбора определенной информации мы используем автоматизированные средства, включая cookie-файлы, веб-маяки, пиксели отслеживания, средства анализа браузера, журналы сервера и мобильные идентификаторы. Во многих случаях информация, которую мы собираем с использованием файлов cookie и прочих средств, используется без возможности идентификации персональной информации. Например, мы можем использовать собираемую информацию, чтобы лучше понять шаблоны трафика на веб-сайте и для оптимизации его использования. В некоторых случаях мы связываем информацию, собираемую при использовании файлов cookie и других технологий, с вашей персональной информацией.
        · Пиксели и SDK. Третьи стороны, могут использовать файлы cookie, веб-маяки и другие технологии хранения, для сбора или получения информации с наших веб-сайтов и из Интернета и использовать эту информацию для показа целевой рекламы. В случае с приложениями, указанные третьи стороны, могут получать или собирать информацию от вашего приложения и/или других приложений и использовать эту информацию для оказания услуг по показу целевой рекламы. Пользователи могут отказаться от сбора и использования информации для показа целевой рекламы, изменив настройки рекламы в учетной записи наших сервисов.
        · Информация о платежных операциях. VetMap собирает информацию о ваших платежных операциях в Сервисах, в том числе информацию об использованном средстве, дате, времени и сумме платежа, срок действия платежного средства, индекс платежного адреса, адрес электронной почты и другую информацию. Эта информация необходима нам для надлежащего исполнения договорных обязательств перед вами, а также для предоставления Платежных услуг.
        · «Информация неличного характера» – это любая информация, по которой обоснованно невозможно прямо или косвенно установить вашу личность.
        · «Личная информация» – любая информация или комбинация информации о вас, которую можно использовать (прямо или косвенно), чтобы установить вашу личность.
        · «Общая информация» – это информация о вас или касающаяся вас, которой вы добровольно делитесь, оставляя в сервисах VetMap. Общая информация может включать в себя ваши публикации и сообщения (в том числе в профилях, созданные вами закладках, а также фотографии, видео и звуковые записи, полученные с вашего предварительного согласия с помощью камеры и/или микрофонного датчика вашего устройства), любые сообщения от других лиц, распространяемые вами, а также данные о местонахождении и данные журнала, связанные с такими публикациями. К Общей информации также относится информация о вас (в том числе данные о местонахождении и данные журнала), распространяемая другими пользователями сервисов VetMap.
        
        4.1.5 Информация, которую мы получаем от третьих лиц.
        
        Сервисы могут собирать информацию, включая персональные данные, предоставляемую третьими лицами при использовании ими наших Сервисов, а также получать информацию из других источников и объединять ее с информацией, которые мы собираем через Сервисы. Мы не контролируем то, как третьи стороны, предоставляющие информацию о вас, обрабатывают ваши Персональные данные, а также не несем за это никакой ответственности. Все запросы касательно передачи ваших персональных данных компании VetMap необходимо направлять соответствующим третьим лицам.
        
        · Сторонние сервисы. Если вы связываете свои аккаунты в сервисах VetMap со сторонними сервисами или входите в них через сторонние сервисы (например, через Google, Facebook или WeChat), то данные сторонние сервисы могут передавать нам информацию о вас, в том числе ваши регистрационные данные, список друзей и данные профиля на этих сервисах. Набор передаваемой информации определяется данными сервисами или вашими настройками конфиденциальности на данных сервисах.
        · Приглашения. Если вас приглашают в сервисы VetMap, то пригласившее вас лицо может предоставить персональную информацию о вас, например, ваш адрес электронной почты или другую контактную информацию.
        · Другие источники. С учетом требований действующего законодательства мы имеем право получать от сторонних поставщиков услуг и/или партнеров дополнительную информацию о вас, в том числе демографические данные и информацию, которая помогает выявлять мошеннические действия и обеспечивать безопасность, а также объединять полученную информацию с имеющейся у нас информацией о вас. Например, в рамках наших мероприятий по оценке рисков и предотвращению мошенничества мы с вашего согласия (если оно необходимо) можем получать результаты проверки благонадежности или предупреждения о возможности мошенничества от других поставщиков, в том числе из сервисов проверки идентификационной информации. Кроме того, мы можем получать от партнеров информацию о вас и ваших действиях на в сервисах VetMap и вне их, а также получать от партнерских рекламных сетей информацию о ваших взаимодействиях с рекламой.`
  },
  {
    title: '5. Цели получения Ваших персональных данных',
    text: `5.1. Оператор обрабатывает персональные данные клиентов в целях соблюдения норм законодательства РФ, а также с целью:

        · — Идентификации Пользователя, зарегистрированного в Сервисах для его дальнейшей авторизации и доступа к персонализированным данным Сервисов и для настройки учетных записей.
        · — Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сервисов, обработки запросов и заявок от Пользователя.
        · — Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
        · — Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
        · — Предоставления Пользователю эффективной технической поддержки при возникновении проблем, связанных с использованием Сервисов.
        · — Создания учетной записи в тех сервисах где это необходимо, если Пользователь дал согласие на создание учетной записи.
        · — Уведомления Пользователя по электронной почте, смс, а также предоставления персональной помощи и инструкций.
        · — Предоставления Пользователю специальных предложений, новостной рассылки и иных сведений.
        · — Приема обращений и заявок от Пользователя;
        · — Информирования Пользователя о новых товарах, специальных акциях и предложениях;
        · — Разработки новых и совершенствования существующих услуг;
        · — Для администрирования и внутренних операций, включая устранение неполадок, анализ данных, тестирование, исследования, безопасность, обнаружение мошенничества, управление учетными записями и проведения опросов.
        
        5.2. VetMap обрабатывает Ваши персональные данные в разумный срок, необходимый для обработки персональных данных, если иное не предусмотрено требованиями законодательства РФ.
        `
  },
  {
    title: '6. Сведения об обеспечении безопасности персональных данных',
    text: `6.1. VetMap при обработке персональных данных принимает необходимые правовые, организационные и технические меры или обеспечивает их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.

        6.2. Меры по обеспечению безопасности персональных данных при их обработке, применяемые VetMap, планируются и реализуются в целях обеспечения соответствия требованиям, приведенным в статье 19 ФЗ-152 «О персональных данных».
        
        6.3. В соответствии со статьей 18.1 ФЗ-152 «О персональных данных» VetMap самостоятельно определяет состав и перечень мер, необходимых и достаточных для обеспечения выполнения требований законодательства.
        
        6.4. В дополнение к требованиям 152-ФЗ «О персональных данных», мы осуществляем комплекс мероприятий, направленных на защиту информации о клиентах, работниках и контрагентах.`,
  },
  {
    title: '7. Ваши права',
    text: `7.1. Вы имеете определенные права в отношении вашей Личной информации, которой мы располагаем. Некоторые из них применяются только в определенных обстоятельствах, более подробно описанных ниже.

        7.2. Мы изложили процедуру реализации этих прав. Обратите внимание, что мы можем попросить вас подтвердить вашу личность, прежде чем ответить на любой запрос о применении ваших прав.
        
        7.2.1. Для реализации своих прав и законных интересов вы имеете право обратиться к нам либо направить запрос лично или с помощью представителя. Запрос должен содержать сведения, указанные в ч. 3 ст. 14 152-ФЗ «О персональных данных», а также ваше сообщение.
        
        7.3. Доступ и исправление
        
        7.3.1. По запросу VetMap предоставит вам информацию о том, храним ли мы вашу личную информацию и в каком объёме. Вы также имеете право на доступ к вашей личной информации, которой мы располагаем, способам ее использования и распространения. Вы также имеете право вносить исправления в эту информацию.
        
        7.3.2. Вы можете получить доступ к своей личной информации и внести в нее исправления, войдя в учетную запись соответствующего сервиса VetMap в любое время.
        
        7.3.3. Что касается запросов на исправление, то, если мы согласны с тем, что Личная информация неточна или неполна, мы попытаемся сообщить об этом любой третьей стороне, которой мы раскрыли соответствующую Личную информацию, и попросить исправить такую Личную информацию.
        
        7.4. Стирание
        
        7.4.1. Вы можете удалить свою учетную запись или удалить определенную личную информацию, войдя в свою учетную запись VetMap и следуя инструкциям по удалению учетной записи. Если имеется какая-либо другая личная информация, которую, по вашему мнению, мы обрабатываем, и вы хотите стереть ее, вы можете направить нам соответствующий запрос.
        
        7.4.2. Вы можете подать запрос на удаление вашей Личной информации в следующих случаях:
        
        • вы считаете, что нам больше не нужна ваша Личная информация;
        • мы получили ваше согласие на обработку Личной информации, и вы отозвали это согласие (и у нас нет других оснований для обработки Личной информации);
        • вы считаете, что мы незаконно обрабатываем вашу Личную информацию.
        
        7.4.3. Если вы попросили удалить вашу Личную информацию, раскрытую нами, и при этом имеются основания для удаления, мы предпримем обоснованные шаги, чтобы сообщить другим лицам, отображающим Личную информацию или предоставляющим ссылки на Личную информацию, о необходимости ее удаления.
        
        7.5. Возражение
        
        7.5.1. Вы можете возражать против использования нами вашей Личной информации, если мы используем вашу информацию в наших законных интересах (например, если мы используем вашу личную информацию для обеспечения безопасности вашей учетной записи, к примеру, чтобы предотвратить незаконный вход в учетную запись). Если вы возражаете против такой обработки, отправьте нам соответствующий запрос, подробно указав в нем все причины.
        
        7.5.2. В случаях, предусмотренных действующими законами и иными нормативными актами, вы можете отозвать любое согласие, предоставленное нам ранее, следуя порядку, указанному в настоящем соглашении.
        
        7.6. В случае отзыва вами персональных данных или вашим представителем согласия на обработку персональных данных VetMap вправе продолжить обработку персональных данных без вашего согласия при наличии оснований, указанных в пунктах 2 — 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 152-ФЗ «О персональных данных» от 27.07.2006 г.`
  },
  {
    title: '8. Дополнительные условия и предложения',
    text: `8.1. VetMap приветствует Ваши вопросы и предложения, касающиеся исполнения или изменения настоящей Политики. Все предложения или вопросы касательно настоящей Политики конфиденциальности следует сообщать по адресу: info@vetmap.ru

        8.2. Пожалуйста, воспользуйтесь нашей почтой, размещенной по адресу https://vetmap.ru/#contacts Вы также можете воспользоваться этим адресом для направления запросов о реализации Ваших прав или жалоб относительно некорректности Вашей Персональной информации или незаконности ее обработки.
        
        8.3. Действующая Политика конфиденциальности размещена на странице по адресу https://vetmap.ru/privacy/
        
        Обновлено: 16 ноября 2021
        г. Москва, ОГРН 1217700430903
        `
  }
]
