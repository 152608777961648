<template>
  <teleport to="#map">
    <div
      class="img-wrap"
      :class="{
        'is-desktop': !isMobileDevice
      }"
    >
      <img :src="StabIMG" class="stab-img">
      <img :src="StabIMG" class="stab-bg">

      <div
        class="entity-picture"
        :class="{
          'is-visible': isImageVisible
        }"
      >
        <img :src="path" class="img" @load="onLoad">
        <img :src="path" class="bg">
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { useDevice } from '@/composables/useDevice';
import StabIMG from '@/assets/icons/other/stub.svg'
import { ref } from 'vue';
import { computed } from 'vue';

const { isMobileDevice } = useDevice();

const props = defineProps({
  path: {
    type: String,
    default: ''
  }
})

const isReady = ref(false);
const isImageVisible = computed(() => props.path && isReady.value)

const onLoad = () => {
  setTimeout(() => {
    isReady.value = true;
  }, 0);
}
</script>

<style lang="scss" scoped>
.img-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  position: absolute;
  z-index: 1;

  .stab-img {
    height: 55%;
  }

  .stab-bg {
    transform: scale(1.4);
    filter: blur(30px);
  }

  .entity-picture {
    position: absolute;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s;

    &.is-visible {
      opacity: 1;
    }

    .img {
      width: 100%;
      height: 55%;
      object-fit: cover;
      position: relative;
      z-index: 10;
    }

    .bg {
      transform: scale(1.4);
      filter: blur(30px);
    }
  }
}

.img-wrap.is-desktop {
  left: var(--desktop-pane-width);
  height: 100%;
  width: calc(100% - var(--desktop-pane-width));

  .stab-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .entity-picture {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    width: 90%;
    height: 90%;
    border-radius: 70px;
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1.8);
    filter: blur(40px);
  }
}
</style>
