import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useSearchStore = defineStore('searchStore', () => {
  const search = ref('')
  const id = ref(null)

  return { search, id }

})
