import { MenuView } from "@/screens/MenuView";
import { ChatsView } from "@/screens/ChatsView";
import { SearchView } from "@/screens/SeachView";
import { AccountView } from "@/screens/AccountView";
import { AnimalsView } from "@/screens/AnimalsView";
import { ChatView } from "@/screens/ChatView";
import { AnimalCreateView } from "@/screens/Animal/pages/AnimalCreateView";
import { AnimalEditView } from "@/screens/Animal/pages/AnimalEditView";
import { AnimalView } from "@/screens/AnimalView";
import { OrdersView } from "@/screens/OrdersView";
import { OrderCreateView } from "@/screens/Order/pages/OrderCreateView";
import { OrderEditView } from "@/screens/Order/pages/OrderEditView";
import { OrderView } from "@/screens/OrderView";
import { AmbulanceView } from "@/screens/AmbulanceView";
import { AccountsView } from "@/screens/AccountsView";
import { SettingsView } from "@/screens/SettingsView";
import { NotificationsView } from "@/screens/NotificationsView";
import { FavoritesView } from "@/screens/FavoritesView";
import { CategoryView } from "@/screens/CategoryView";
import { ProfileView } from "@/screens/ProfileView";
import { SupportView } from "@/screens/SupportView";
import { ClusterView } from "@/screens/ClusterView";
import { DelegateView } from "@/screens/DelegateView";

import { AccountCreate } from "@/screens/Account/pages/AccountCreate";
import { AccountEdit } from "@/screens/Account/pages/AccountEdit";
import AccountShowcases from '@/screens/Account/pages/AccountShowcases/index.vue'
import ServicesCreate from '@/screens/Account/pages/AccountShowcases/ServicesCreate.vue'
import ProductsCreate from '@/screens/Account/pages/AccountShowcases/ProductsCreate.vue'
import PricesCreate from '@/screens/Account/pages/AccountShowcases/PricesCreate.vue'

import ServicesSingle from '@/screens/Account/pages/AccountShowcases/ServicesSingleUpdate.vue'
import PricesSingle from '@/screens/Account/pages/AccountShowcases/PricesSingle.vue'
import ProductsSingleUpdate from '@/screens/Account/pages/AccountShowcases/ProductsSingleUpdate.vue'
import ProductsSingle from '@/screens/Account/pages/AccountShowcases/ProductsSingle.vue'

export const PaneChildren = [
  {
    path: "/",
    name: "home",
    component: MenuView,
    meta: {
      pane: "menuPage",
    },
  },
  {
    path: "/ambulance",
    name: "ambulance",
    component: AmbulanceView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      pane: "fitContent",
    },
  },
  {
    path: "/support",
    name: "support",
    component: SupportView,
    meta: {
      pane: "fitContent",
    },
  },
  {
    path: "/chats",
    name: "chats",
    component: ChatsView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/delegate",
    name: "delegate",
    component: DelegateView,
    meta: {
      pane: "fullpage",
    },
  },
  // TODO should component to be removed ?????
  // {
  //   path: "/events",
  //   name: "events",
  //   component: EventsScreen,
  //   meta: {
  //     pane: "fullpage",
  //   },
  // },
  {
    path: "/chat/:id",
    name: "chat",
    component: ChatView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/animal/:id",
    name: "animal",
    component: AnimalView,
    meta: {
      pane: "animal",
    },
  },
  {
    path: "/order/:id",
    name: "order",
    component: AccountView,
    meta: {
      pane: "ordinary",
    },
  },
  // TODO нужно для SEO-оптимизации. Убрать позже
  {
    path: "/order-map/:id",
    redirect: (to) => ({ name: "order", params: to.params }),
  },
  {
    path: "/offer/:id",
    name: "offer",
    component: OrderView,
    meta: {
      pane: "ordinary",
    },
  },
  {
    path: "/search",
    name: "search",
    component: SearchView,
    meta: {
      pane: "ordinary",
    },
  },
  {
    path: "/cluster",
    name: "cluster",
    component: ClusterView,
    meta: {
      pane: "ordinary",
    },
  },
  {
    path: "/category",
    name: "category",
    component: CategoryView,
    meta: {
      pane: "ordinary",
    },
  },
  // {
  //   path: "/order-map/:id",
  //   name: "order-map",
  //   component: AccountView,
  //   meta: {
  //     pane: "ordinary",
  //   },
  // },
  {
    path: "/pet-create",
    name: "pet-create",
    component: AnimalCreateView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/order-create",
    name: "order-create",
    component: OrderCreateView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/order-edit/:id",
    name: "order-edit",
    component: OrderEditView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/offers",
    name: "offers",
    component: OrdersView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/accounts",
    name: "accounts",
    component: AccountsView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/account/:id",
    name: "account",
    component: AccountView,
    meta: {
      pane: "ordinary",
    },
  },
  {
    path: "/animals",
    name: "animals",
    component: AnimalsView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: NotificationsView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/favorites",
    name: "favorites",
    component: FavoritesView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/animal-edit/:id",
    name: "animal-edit",
    component: AnimalEditView,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/account-create",
    name: "account-create",
    component: AccountCreate,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
    meta: {
      pane: "fullpage",
      prevPath: '/'
    },
  },
  {
    path: "/account-edit/:id",
    name: "account-edit",
    component: AccountEdit,
    meta: {
      pane: "fullpage",
    },
  },

  // TODO vet-57
  {
    path: "/account-showcases/:id",
    name: "account-showcases",
    component: AccountShowcases,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/account-showcases/:id/products/create",
    name: "account-showcases-products-create",
    component: ProductsCreate,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/account-showcases/:id/products/:productId/edit",
    name: "account-showcases-products-single-update",
    component: ProductsSingleUpdate,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/account-showcases/:id/products/:productId",
    name: "account-showcases-products-single",
    component: ProductsSingle,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/account-showcases/:id/services/create",
    name: "account-showcases-services-create",
    component: ServicesCreate,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/account-showcases/:id/prices/create",
    name: "account-showcases-prices-create",
    component: PricesCreate,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/account-showcases/:id/services/:serviceId",
    name: "account-showcases-services-single",
    component: ServicesSingle,
    meta: {
      pane: "fullpage",
    },
  },
  {
    path: "/account-showcases/:id/prices/:serviceId",
    name: "account-showcases-prices-single",
    component: PricesSingle,
    meta: {
      pane: "fullpage",
    },
  },
];
