import showcasesApi from "@/screens/Account/api/showcases.api";
import { defineStore } from "pinia";
import { ShowcasesItemTypes } from "@/screens/Account/constants/showcases";

export const useUserStore = defineStore("user-store", {
  state: () => ({
    showcases: []
  }),

  getters: {
    services() {
      return this.showcases.filter((item) => item.type === ShowcasesItemTypes.services)
    },
    products() {
      return this.showcases.filter((item) => item.type === ShowcasesItemTypes.products)
    },
    prices() {
      return this.showcases.filter((item) => item.type === ShowcasesItemTypes.prices)
    },
  },

  actions: {
    async getAllShowcases(accountId, params = {}) {
      const { data } = await showcasesApi.getAll(accountId, params)

      this.showcases = data.showcases
    },

    getShowcaseItemById(id) {
      return this.showcases.find((item) => item.id == id)
    },
    clearShowcase() {
      this.showcases = [];
    }
  }
})
