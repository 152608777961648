<template>
  <!-- <PanePromise fit-content id="ksdsd" ref="PanePromiseREF"> -->
  <PanePromise
    id="ksdsd"
    ref="PanePromiseREF"
  >
    <LayoutBase gap="10">
      <ItemBase :title="t('chat.selectAccount')">
        <div
          v-if="myProfile"
          class="gap"
        >
          <div
            v-if="!isOrder"
            class="pet"
            @click="startChat(myProfile.account_id)"
          >
            <img
              v-if="myProfile.photo"
              :src="myProfile.photo"
              class=" pet__img"
            >
            <img
              v-else
              src="@/assets/icons/other/no-photo.svg"
              class=" pet__img"
            >

            <div class="body">
              <div class="title">
                {{ myProfile.name }}
              </div>
              <div class="subtitle">
                {{ t('chat.personalAccount') }}
              </div>
            </div>
          </div>
          <div
            v-for="item in filteredAccs"
            :key="item.id"
            class="pet"
            @click="startChat(item.id)"
          >
            <IconBase
              :path="`category/${item.categoryId}.svg`"
              class=" pet__img"
            />

            <div class="body">
              <div class="title">
                {{ item.name }}
              </div>
              <div class="subtitle">
                {{ getCategoryById(item.categoryId).name }}
              </div>
            </div>
          </div>
        </div>
      </ItemBase>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ItemBase, IconBase } from '@/UI'
import { getAccounts, createChat, getUser } from './api'
import { useMainEntities } from '@/states/mainEntities';
import { errorToast } from '@/helpers/showToast'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n';
const router = useRouter()
const mainEntities = useMainEntities()
const PanePromiseREF = ref()
const accs = ref([])

const { t } = useI18n();

const myProfile = ref(null)

const openContext = async () => {
  const { accounts } = await getAccounts()
  const { user } = await getUser()

  myProfile.value = user
  accs.value = accounts

  if (filteredAccs.value?.length === 0) {
    startChat(myProfile.value.account_id, false)
  } else {
    await PanePromiseREF.value?.open()
  }

}

const props = defineProps({
  id: {
    type: [String, Number],
  },
  isOrder: {
    type: Boolean,
    default: false
  },
  filterByCategory: {
    type: Number
  },
  offerId: {
    type: [String, Number]
  }
})

const init = async () => {

}

const filteredAccs = computed(() => {
  if (!props.isOrder) {return accs.value}
  return accs.value.filter(el => el.categoryId == props.filterByCategory)
})

const getCategoryById = (id) => mainEntities.category.find(el => el.id == id)

const startChat = async (youId, isOpenedPane = true) => {
  const id = Number(props.id)

  const { chat_id, success } = await createChat({ account_id: id, from_account_id: Number(youId), offer_id: props.offerId })
  if (!success) {
    errorToast({ description: t('toast.somethingWrong') })
    return
  }
  if (isOpenedPane) {
    PanePromiseREF.value.close()
  }
  router.push(`/chat/${ chat_id}`)

}

onMounted(() => {
  init()
})

defineExpose({
  open: openContext
})
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .pet {
        background: #37363C;
    }

    .pet__img {
        background: #292929;
    }

    .title {
        color: #fff
    }

    .subtitle {
        color: #9A9EB9;
    }
}

.gap {
    display: grid;
    gap: 10px;
}

.pet {
    &__img {
        border-radius: 20px;
        background: #F2F8FF;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        object-fit: cover;
    }

    border-radius: 26px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 10px;
}

.title {
    color: #5B5F7C;
    font-size: 15px;
    font-weight: 700;
    line-height: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subtitle {
    color: #8E92AF;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.body {
    display: grid;
    gap: 8px;
}
</style>
