<template>
  <SelectFullPage
    id="animalSelectPane"
    v-model="value"
    :hidden-placeholder="value"
    :label="t('component.animalSelector.title')"
    :items="options"
  >
    <template #pane-inner-prepend>
      <SelectFullPageItem
        :value="value"
        :item="{title: 'Использовать нового', id: null}"
        @click="emit('update:modelValue', null)"
      />
    </template>
    <template v-if="choosedPet" #icon>
      <IconBase :path="`animals/${getItemById(choosedPet?.animal_id)?.icon}.svg`" class="pane-img" />
      {{ choosedPet?.payload?.name }}
    </template>
    <template #item-prepend="{ item }">
      <IconBase :path="`animals/${getItemById(item.animal_id)?.icon}.svg`" class="pane-img" />
    </template>
  </SelectFullPage>
</template>

<script setup>
import { SelectFullPage, IconBase } from '@/UI'

import { useMainEntities } from '@/states/mainEntities'
import { computed } from 'vue';

import SelectFullPageItem from './SelectFullPageItem.vue';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  }
})
const emit = defineEmits(['update:modelValue'])

const value = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})

const mainEntities = useMainEntities()
const getItemById = (id) => mainEntities.animalTypes.find(el => el.id === id)
const choosedPet = computed(() => props.options?.find(el => el.id === props.modelValue))

setTimeout(() => {
}, 1000);
</script>
