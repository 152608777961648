<template>
  <div
    v-if="props.modelValue.length"
    :key="props.modelValue.length"
    class="wrap"
    :class="{ 'is-desktop': !isMobileDevice }"
  >
    <div
      :id="props.id"
      class="gallery"
    >
      <a
        v-for="(photo, idx) in props.modelValue"
        :key="idx"
        class="gallery__item"
        :data-pswp-src="getPhotoURL(photo)"
        target="_blank"
        rel="noreferrer"
      >
        <img
          id="image"
          class="gallery__img"
          :src="getPhotoURL(photo)"
        >

        <div
          v-if="photo?.name"
          class="name"
        >
          {{ photo.name.replace('Blob', '') }}
        </div>

        <img
          v-if="!onlyView"
          class="delete delete-icon"
          src="@/assets/icons/other/add.svg"
          @click.stop="deleteImg(idx)"
        >
      </a>
    </div>
  </div>
  <ButtonBase
    v-if="!onlyView"
    :variant="buttonType"
    :class="activatorClassName"
    @click="openFiles"
  >
    <template #default>
      {{ label || t('component.photoGallery.add') }}
    </template>
    <template #prepend-icon>
      <CameraIcon />
    </template>
  </ButtonBase>
</template>

<script setup>
import { useFileDialog } from '@vueuse/core'
import { ButtonBase } from '@/UI'
import { warningToast } from '@/helpers/showToast'
import CameraIcon from '@/assets/icons/other/CameraIcon.vue'

import { onMounted, onUnmounted } from 'vue'

import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
import { useI18n } from 'vue-i18n'
import { useDevice } from '@/composables/useDevice';

const { isMobileDevice } = useDevice();

const MAX_PHOTO_UPLOAD = 10

const createUrl = (photo) => {
  try {
    return URL.createObjectURL(photo)
  } catch (error) {
    return photo;
  }
}

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  onlyView: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: 'photo',
  },
  activatorClassName: {
    type: String,
    default: '',
  },
  buttonType: {
    type: String,
    validate(value) {
      return ['default', 'text'].includes(value)
    },
    default: 'default',
  },
})

const emit = defineEmits(['update:modelValue'])

const {
  files: photoPicked,
  open: openFiles,
  reset,
  onChange,
} = useFileDialog({
  multiple: true,
  reset: false,
  accept: 'image/*, .heic, .heif',
})

const isOnlyPhoto = (arrayOfPhotos) => {
  let isPhoto = true
  arrayOfPhotos.forEach(({ type }) => {
    if (!type.startsWith('image/')) {
      isPhoto = false
      return isPhoto
    }
  })
  return isPhoto
}
const { t } = useI18n();
onChange((val) => {
  if (val.length > MAX_PHOTO_UPLOAD - props.modelValue.length)
  {return warningToast({
    description: t('component.photoGallery.photoLimit'),
  })}

  if (!isOnlyPhoto(Array.from(val))) {
    warningToast({ description: t('component.photoGallery.imageOnly') })
    return
  }

  emit('update:modelValue', [...props.modelValue, ...Array.from(val)])
})

const deleteImg = (id) => {
  emit(
    'update:modelValue',
    [...props.modelValue].filter((el, idx) => idx !== id),
  )
}

let gallery

const getPhotoURL = (photo) => {
  const photoLink =
    photo?.path ||
    (String(photo)?.includes('https://img') ? photo : createUrl(photo))

  return photoLink
}

onMounted(async () => {
  if (!document.getElementById(props.id)) {return}

  gallery = new PhotoSwipeLightbox({
    gallery: `#${ props.id}`,
    children: 'a',
    showHideAnimationType: 'zoom',
    tapAction: 'close',
    pswpModule: () => import('photoswipe'),
  })
  gallery.addFilter('itemData', (itemData) => {
    const img = itemData.element.querySelector('img')
    const width = img.naturalWidth
    const height = img.naturalHeight

    itemData.h = height
    itemData.w = width
    return itemData
  })

  gallery.init()
})

onUnmounted(() => {
  console.log(gallery, 'destroy')
  gallery?.destroy()
})
</script>

<style lang="scss" scoped>
@import url('photoswipe/style.css');

.wrap {
  width: calc(100vw - 40px);
  overflow: auto;
}

.is-desktop {
  .wrap {
    width: calc(var(--desktop-pane-width) - 60px);
  }
}

.gallery-wrap {
  width: 100%;
  overflow: hidden;
  display: grid;
  gap: 6px;
}

.counter {
  display: flex;
  justify-content: end;
}

.gallery {
  overflow: auto;
  height: 140px;
  width: fit-content;
  display: flex;
  gap: 4px;

  &__item {
    position: relative;
    width: 115px;
    height: 115px;
    border-radius: 14px;
    border: 1px solid #E8EAFC;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
  }

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  z-index: 4;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  padding: 10px;

  height: 40px;
  width: 40px;

  color: #fff;
  z-index: 4;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}
</style>
