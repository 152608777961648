const earthRadius = 6371
const radiansToDegrees = radians => radians * (180 / Math.PI);
const degreesToRadians = degrees => degrees * (Math.PI / 180);

const centralSubtendedAngle = (locationX, locationY) => {
  const locationXLatRadians = degreesToRadians(locationX[0]);
  const locationYLatRadians = degreesToRadians(locationY[0]);
  return radiansToDegrees(
    Math.acos(
      Math.sin(locationXLatRadians) * Math.sin(locationYLatRadians) +
            Math.cos(locationXLatRadians) *
            Math.cos(locationYLatRadians) *
            Math.cos(
              degreesToRadians(
                Math.abs(locationX[1] - locationY[1])
              )
            )
    )
  );
}

const greatCircleDistance = angle => 2 * Math.PI * earthRadius * (angle / 360);

export const getDistance = (locationX, locationY, toFixed = 2) => {
  if (!(locationX && locationY)) {return}
  return greatCircleDistance(centralSubtendedAngle(locationX, locationY)).toFixed(toFixed)

}
