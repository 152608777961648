<template>
  <div
    class="pane-selector "
    @click="handleClick"
  >
    <template v-if="!props.modelValue.length">
      <div class="title">
        {{ props.placeholder }}
      </div>
      <img
        src="@/assets/icons/other/small-arrow.svg"
        class="arrow"
      >
    </template>
    <div
      v-else
      class="petwrap"
    >
      <div class="item ">
        <div class="item-title">
          {{ props.suggest }}: {{ (choosedItems.map(el => el.label.toLowerCase())).join(', ')
          }}
        </div>
      </div>
    </div>
  </div>

  <PanePromise
    :id="props.id"
    ref="PanePromiseREF"
  >
    <LayoutBase gap>
      <ItemBase :title="props.placeholder">
        <div class="pane-inner">
          <div
            v-for="item in props.options"
            :key="item.value"
            class="item"
            @click="handleToggle({ item })"
          >
            <div class="item-title">
              {{ item.label }}
            </div>
            <CheckboxBase :is-active="modelValue.includes(item.value)" />
          </div>
        </div>
      </ItemBase>
      <ButtonBase
        class="btn-selector"
        @click="handleClose"
      >
        {{ t('common.choose') }}
      </ButtonBase>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { computed, ref } from 'vue'
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ItemBase, CheckboxBase, ButtonBase } from '@/UI'

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true
  },
  suggest: {
    type: String,
    required: true
  },
  id: {
    type: String
  }
})

const emit = defineEmits(['update:modelValue'])

const PanePromiseREF = ref()

const handleClick = () => {
  PanePromiseREF.value.open({})
}

const handleClose = () => {
  PanePromiseREF.value.close()
}

const handleToggle = ({ item }) => {
  if (props.modelValue.includes(item.value)) {
    emit('update:modelValue', (props.modelValue.filter(el => el !== item.value)))
  } else {
    emit('update:modelValue', [...props.modelValue, item.value].sort())
  }
}

const choosedItems = computed(() => props.options.filter(el => props.modelValue.includes(el.value)))
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .pane-selector {
        background: #37363C;
        color: #7A7B87;
        border: 1px solid #37363C;
    }

    .pane-inner {
        background: #37363C;
        border: 1px solid #37363C;

    }

    .item-title {
        color: #fff;
    }
}

.petwrap {
    display: grid;
    grid-template-columns: minmax(0, auto);
    gap: 30px;
}

.item-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0;
}

.pane-selector {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 15px 20px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 10px;
}

.title {
    padding: 5px 0;
}

.btn-selector {
    position: sticky;
    bottom: 20px;
}

.pane-inner {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 20px 25px;
    display: grid;
    gap: 40px;
    overflow-y: auto;

}

.item {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 20px;

    &-title {
        color: #645C56;
        font-size: 15px;
        font-weight: 600;
        line-height: 14px;
    }

    &-img {
        width: 20px;
        height: 20px;
    }
}

.pane-img {
    width: 30px;
    height: 30px;
}

.arrow {
  transform: rotate(-90deg);
  width: 13px;
}
</style>
