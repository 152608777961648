<template>
  <PanePromise
    id="checkVesrion"
    ref="PanePromiseREF"
    persist
    fit-content
    disabled
  >
    <LayoutBase>
      <div class="title">
        Доступна новая версия
      </div>
      <p class="text">
        {{ text }}
      </p>
      <div class="btnWrap">
        <ButtonBase @click="update">
          Обновить
        </ButtonBase>
      </div>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { PanePromise, ButtonBase } from "@/UI";
import { LayoutBase } from '@/components/Layouts'
import { useSocket } from '@/composables/useSocket';
import { useStore } from '@/states/index';

const store = useStore();
const PanePromiseREF = ref()
const text = ref('')

let versionForClient
const checkVersion = async ({ description, force, version }) => {

  const localVersion = store.agreeVersion;

  if (!localVersion) {
    store.$patch({ agreeVersion: version })
    return
  }

  if (!force) {
    return
  }

  if (localVersion === version) {
    return
  }

  versionForClient = version

  text.value = description
  PanePromiseREF.value.open()

}

const update = () => {
  store.$patch({ agreeVersion: versionForClient })
  location.reload()
}

const { socket } = useSocket()
onMounted(() => {
  socket.on('version', (v) => {
    checkVersion(v)
  })
  socket.emit('version', {});
})
</script>

<style lang="scss" scoped>
.title {
    color: #6B7090;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    text-align: center;
}

.text {
    color: #5B5F7C;
    font-size: 15px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
}

.btnWrap {
    display: grid;
    gap: 10px;
}
</style>
