import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { vMaska } from "maska"
import * as Sentry from "@sentry/vue";

import App from './app/App.vue';
import FlagIcon from 'vue-flag-icon';
import router from './router'

import 'animate.css';
import '@/app/styles/main.scss'
import { useDevice } from '@/composables/useDevice';
import { i18n, initLocalization } from './plugins/localization';

export const app = createApp(App)

export { i18n }

const pinia = createPinia()
pinia.use(createPersistedState({
  storage: window.Telegram?.WebApp?.platform === 'telegram' ? window.Telegram.WebApp.CloudStorage : localStorage,
}));

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
});

app
  .use(i18n)
  .directive("maska", vMaska)
  .use(router)
  .use(pinia)
  .use(FlagIcon)
  .mount('#app')

const { setDeviceType } = useDevice();
setDeviceType();
initLocalization();
