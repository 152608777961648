import { api } from "@/app/api"

export const getAnimals = async () => {
  try {
    const { data } = (await api.get('/v1/animals'))
    return data
  } catch (error) {

    console.error(error)
  }
}

export const deleteAnimal = async ({ id }) => {
  try {
    const { data } = (await api.delete('/v1/animals', { data: { id } }))
    return data
  } catch (error) {
    console.error(error)
  }
}
