<template>
  <InputSimple
    v-model="internalValue"
    data-maska="##.##.####"
    type="decimal"
    :placeholder="t('common.date')"
    class="vac__date "
    :is-error="isError"
    v-bind="$attrs"
    @blur="handleBlur"
  />
</template>

<script setup>
import { InputSimple } from '@/UI'
import { ref, watchEffect, watch } from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  }
})

const isDateValid = (maybeDate) => {
  const dateRegex = /^(\d{2})\.(\d{2})\.(\d{4})$/;
  const [, day, month, year] = maybeDate.match(dateRegex) || [];

  if (!day || !month || !year) {
    return false; // Некорректный формат даты
  }

  const parsedDay = parseInt(day, 10);
  const parsedMonth = parseInt(month, 10);
  const parsedYear = parseInt(year, 10);

  // Проверка на существование даты
  const isValidDate = !isNaN(parsedYear) && !isNaN(parsedMonth) && !isNaN(parsedDay);
  if (!isValidDate) {
    return false;
  }

  // Проверка валидности дня, месяца и года
  const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate();
  const isValidDay = parsedDay >= 1 && parsedDay <= daysInMonth;
  const isValidMonth = parsedMonth >= 1 && parsedMonth <= 12;
  const isValidYear = parsedYear >= 1950 && parsedYear <= new Date().getFullYear();

  return isValidDay && isValidMonth && isValidYear;
};

const internalValue = ref(props.modelValue);
const isError = ref(false);

const handleInput = () => {
  isError.value = false;
};

const handleBlur = () => {
  if (isDateValid(internalValue.value)) {
    return
  }

  if (internalValue.value.length === 0) {
    return
  }
  isError.value = true;
};

watch(() => internalValue.value.length, (val) => {
  if (val === 10) {
    handleBlur()
  } else {
    isError.value = false;
  }
})

// Следующий блок можно использовать для эмита события при валидном изменении
watchEffect(() => {
  if (isDateValid(internalValue.value)) {
    emit('update:modelValue', internalValue.value);
    isError.value = false;
  } else {
    emit('update:modelValue', '');
  }
});
</script>

<style lang="scss" scoped>
.error {
    border: 1px solid red;
}
</style>
