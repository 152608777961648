<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2.087C0 1.53349 0.223883 1.00266 0.622398 0.611269C1.02091 0.21988 1.56141 0 2.125 0L14.875 0C15.4386 0 15.9791 0.21988 16.3776 0.611269C16.7761 1.00266 17 1.53349 17 2.087V10.435C17 10.9885 16.7761 11.5194 16.3776 11.9107C15.9791 12.3021 15.4386 12.522 14.875 12.522H12.2188C12.0538 12.522 11.8911 12.5597 11.7436 12.6322C11.5961 12.7046 11.4677 12.8098 11.3687 12.9394L9.35 15.5826C9.25103 15.7122 9.1227 15.8174 8.97516 15.8898C8.82763 15.9623 8.66495 16 8.5 16C8.33505 16 8.17237 15.9623 8.02484 15.8898C7.8773 15.8174 7.74897 15.7122 7.65 15.5826L5.63125 12.9394C5.53228 12.8098 5.40395 12.7046 5.25641 12.6322C5.10888 12.5597 4.9462 12.522 4.78125 12.522H2.125C1.56141 12.522 1.02091 12.3021 0.622398 11.9107C0.223883 11.5194 0 10.9885 0 10.435V2.087ZM3.71875 3.1305C3.57785 3.1305 3.44273 3.18547 3.3431 3.28332C3.24347 3.38117 3.1875 3.51388 3.1875 3.65225C3.1875 3.79063 3.24347 3.92334 3.3431 4.02119C3.44273 4.11903 3.57785 4.174 3.71875 4.174H13.2812C13.4221 4.174 13.5573 4.11903 13.6569 4.02119C13.7565 3.92334 13.8125 3.79063 13.8125 3.65225C13.8125 3.51388 13.7565 3.38117 13.6569 3.28332C13.5573 3.18547 13.4221 3.1305 13.2812 3.1305H3.71875ZM3.71875 5.73925C3.57785 5.73925 3.44273 5.79423 3.3431 5.89207C3.24347 5.98992 3.1875 6.12263 3.1875 6.26101C3.1875 6.39938 3.24347 6.53209 3.3431 6.62994C3.44273 6.72779 3.57785 6.78276 3.71875 6.78276H13.2812C13.4221 6.78276 13.5573 6.72779 13.6569 6.62994C13.7565 6.53209 13.8125 6.39938 13.8125 6.26101C13.8125 6.12263 13.7565 5.98992 13.6569 5.89207C13.5573 5.79423 13.4221 5.73925 13.2812 5.73925H3.71875ZM3.71875 8.34801C3.57785 8.34801 3.44273 8.40298 3.3431 8.50082C3.24347 8.59867 3.1875 8.73138 3.1875 8.86976C3.1875 9.00813 3.24347 9.14084 3.3431 9.23869C3.44273 9.33654 3.57785 9.39151 3.71875 9.39151H9.03125C9.17215 9.39151 9.30727 9.33654 9.4069 9.23869C9.50653 9.14084 9.5625 9.00813 9.5625 8.86976C9.5625 8.73138 9.50653 8.59867 9.4069 8.50082C9.30727 8.40298 9.17215 8.34801 9.03125 8.34801H3.71875Z"
      fill="currentColor"
    />
  </svg>
</template>
