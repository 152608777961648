import { api } from "@/app/api"

export const getAnimals = async () => {
  try {
    const { data } = (await api.get('/v1/animals'))
    return data
  } catch (error) {

    console.error(error)
  }
}

export const callAmbulance = async (form) => {
  try {
    const { data } = (await api.post('/v1/ambulance', form))
    return data
  } catch (error) {

    console.error(error)
  }
}
