import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { useStore } from '@/states/index';

export const registerNotifications = async () => {
  if ((await Device.getInfo()).platform === 'web') {return}

  let permStatus

  permStatus = await PushNotifications.checkPermissions()

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions()
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!')
  }

  await PushNotifications.register()

  const store = useStore();
  PushNotifications.addListener('registration', token => {
    store.$patch({ pushToken: token.value })
  })

  await PushNotifications.register()
}
