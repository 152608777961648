<template>
  <AnimalTemplate
    :custom-page="customPage"
    page-type="create"
  />
</template>

<script setup>
import { AnimalTemplate } from '../../AnimalTemplate/'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
const { t } = useI18n()

const customPage = computed(() => ({
  headerTitle: t('animalCreate.title'),
  buttonText: t('common.send'),
}))
</script>
