import axios from 'axios';
// import { warningToast } from '@/helpers/showToast'

import { processNonAuthError } from './auth';
import { useUser } from '@/composables/useUser';
// import { useI18n } from 'vue-i18n';
// import { sequalize } from '@/helpers/sequalize';

// const sequalizedErrorNetwork = sequalize(warningToast({ description: 'toast.networkError' }), 500)

const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_API,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
  timeout: 30000,

});

api.interceptors.response.use(null, (error) => {
  // TODO fix i18n here
  // const { t } = useI18n();
  // console.log(error.code);
  if (error.code === 'ERR_NETWORK') {
    // warningToast({
    //   description: t('toast.networkError')
    // })
    // sequalizedErrorNetwork()
  }

  if (error?.response?.status === 401) {
    processNonAuthError();
  }
  return Promise.reject(error)
})

api.interceptors.request.use(
  (config) => {
    const { token } = useUser()
    const headers = {}
    if (token) {
      headers.Authorization = `Bearer ${token.value}`
    }

    config.headers = {
      ...headers
    };
    // Do something before request is sent
    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)

)

export { api }
