<template>
  <SelectFullPage
    id="countryCodesPane"
    v-model="countryShortName"
    :label="t('auth.country')"
    :items="filteredCountries"
    class="select-counties"
    @close="clearSearchState"
  >
    <template #prepend>
      <InputBase v-model="searchBy" :label="t('common.search')" />
    </template>
    <template #item-prepend="{item}">
      <flag
        v-if="item.shortName"
        :iso="item.shortName"
        :squared="false"
      />
    </template>
  </SelectFullPage>
</template>

<script setup>
import { getCountries, getCountryCallingCode } from 'libphonenumber-js'
import SelectFullPage from './SelectFullPage.vue';
import { useVModel } from '@vueuse/core'
import { InputBase } from '@/UI';
import { ref } from 'vue';
import { computed } from 'vue';
import moment from 'moment-timezone';
import { useStore } from '@/states/index';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:modelValue'])

const countryShortName = useVModel(props, 'modelValue', emit)

const preferredCountries = [undefined, 'RU', 'BY', 'KZ'];
const extraCountries = ['AC', 'TA'];

const store = useStore()
const { t } = useI18n()

let regionNames = computed(() => store.language && new Intl.DisplayNames([store.language], { type: 'region' }));
const countries = computed(() => store.language && Array
  .from(new Set([...preferredCountries, ...getCountries()]))
  .filter(shortName => !extraCountries.includes(shortName))
  .map(shortName => {
    if (shortName === undefined) {
      return { shortName: undefined, id: undefined, name: t('common.notChosen') }
    }
    const code = getCountryCallingCode(shortName)
    const name = regionNames.value.of(shortName)

    return { shortName, code, id: shortName, name }
  }))

const searchBy = ref('');
const filteredCountries = computed(() => {
  if (!searchBy.value) {
    return countries.value
  }
  return countries.value
    .filter(country =>
      country.shortName?.toLowerCase().includes(searchBy.value?.toLowerCase())
      || country.name?.toLowerCase().includes(searchBy.value?.toLowerCase())
      || country.code === searchBy.value
    )
})
const clearSearchState = () => {
  setTimeout(() => {
    searchBy.value = ''
  }, 0);
};

const initDefaultTimezone = () => {
  // eslint-disable-next-line
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const countryName = moment.tz.zone(localTimezone).countries()[0];
  if (countryName) {
    emit('update:modelValue', countryName)
  }
}

initDefaultTimezone();
</script>

<style lang="scss">
.select-counties {
  width: 100%;
  margin-bottom: 10px;
}
</style>
