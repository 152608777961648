<template>
  <PanePromise
    id="sl"
    ref="PanePromiseREF"
    fit-content
  >
    <LayoutBase gap="10">
      <ButtonBase
        v-for="(item, key) in props.contactTypes"
        @click="addContact({ type: key })"
      >
        {{ item.placeholder }}
      </ButtonBase>

      <ButtonBase
        danger
        @click="cancel"
      >
        {{ t('settings.cancel') }}
      </ButtonBase>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { ref } from 'vue';
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ButtonBase } from '@/UI'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const emit = defineEmits(['addContact'])

const props = defineProps({
  contactTypes: {
    type: Object,
    required: true
  }
})

const PanePromiseREF = ref()

const idValue = ref(0)
const addContact = ({ type }) => {
  emit('addContact', { type })
  PanePromiseREF.value.close()
}

const cancel = () => {
  PanePromiseREF.value.close()
}

const openContext = async ({ id }) => {
  idValue.value = id
  await PanePromiseREF.value.open()
}

defineExpose({
  open: openContext
})
</script>
