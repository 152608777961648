<template>
  <div
    class="cb-wrap"
    @click="emit('update:modelValue', !modelValue)"
  >
    <div class="cb-title">
      {{ props.title }}
    </div>
    <div class="checkbox">
      <div
        :class="{ 'isActive': modelValue }"
        class="select"
      />
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  title: {
    type: String,
    required: true,
  }
})
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .cb-wrap {
        background: #37363C;
        border: 1px solid  #37363C;
    }

    .checkbox {
        background: #292929;
    }
    .cb-title {
        color: #fff;
    }

}

.checkbox {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background: #E9ECF5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cb-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 0 20px;
    height: 60px;
}

.cb-title {
    color: #6b7090;
    font-size: 15px;
    font-weight: 600;
}

.select {
    width: 16px;
    height: 16px;
    border-radius: 6px;
    background: #5B43EF;
    opacity: 0;
    transition: all .3s ease;

    &.isActive {
        opacity: 1;
    }
}
</style>
