<script setup>
import { computed } from 'vue'
import TrashIcon from '@/assets/icons/other/TrashIcon.vue'

const props = defineProps({
  modelValue: String,
  value: String,
})

const emit = defineEmits(['update:modelValue', 'update:value', 'delete'])

const computedKey = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})

const computedValue = computed({
  get() {
    return props.value
  },
  set(newValue) {
    emit('update:value', newValue)
  },
})
</script>

<template>
  <div class="product-characteristics-input">
    <input
      v-model="computedKey"
      type="text"
      class="product-characteristics-input__field"
      placeholder="Заголовок"
    >
    <input
      v-model="computedValue"
      type="text"
      class="product-characteristics-input__field"
      placeholder="Значение"
    >

    <button
      class="product-characteristics-input__action"
      @click="emit('delete')"
    >
      <trash-icon /> Удалить
    </button>
  </div>
</template>

<style lang="scss" scoped>
.product-characteristics-input {
  height: 60px;
  border: 1px solid var(--defaultInputBorder);
  background: var(--defaultInputBackground);
  border-radius: 20px;
  transition: border 0.3s;
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  position: relative;

  &__field {
    height: 100%;
    width: 50%;
    padding: 0 10px;

    &:first-of-type {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-right: 1px solid var(--defaultInputBorder);
    }

    &:last-of-type {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &:focus {
      border: 1px solid #5b43ef;
    }

    &::placeholder {
      color: #8e92af;
    }
  }

  &__action {
    height: 30px;
    color: #fff;
    color: #ff6b6b;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    font-size: 14px;
    font-weight: 500;

    position: absolute;
    bottom: -35px;
    right: 0;

    svg {
      font-size: 12px;
    }
  }
}
</style>
