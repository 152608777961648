import { api } from "@/app/api"
import { useStore } from '@/states/index'

export const getOrders = async ({ text = '', categoryId = null }) => {
  const store = useStore()
  const lat = (store.coordinates && store.coordinates[0]) || 55;
  const lon = (store.coordinates && store.coordinates[1]) || 33;

  try {
    const params = { text, lat, lon }
    if (categoryId) {
      params.category_id = categoryId
    }

    const { data } = (await api.get('/v1/orders/search', { params }))
    return data
  } catch (error) {
    console.error(error)
  }
}
