<template>
  <HeaderFullpage :title="t('settings.title') + ' ' + (userPhone || '')" />
  <PageLoader v-if="isLoading" />
  <LayoutBase
    v-else
    gap="30"
  >
    <ItemBase :title="t('settings.photo')">
      <div
        class="user-photo"
        @click="openContextPhoto"
      >
        <img
          class="user-img"
          src="@/assets/icons/other/no-photo.svg"
          alt=""
        >
        <img
          v-if="user.photo"
          class="user-img"
          :src="user.photo"
          alt=""
        >
      </div>
    </ItemBase>

    <ItemBase :title="t('settings.theme')">
      <InputCheckbox
        v-model="user.dark"
        :title="t('settings.onTheme')"
      />
    </ItemBase>

    <ItemBase :title="t('common.name')">
      <InputSimple
        v-model="user.name"
        :placeholder="t('settings.inputName')"
      />
    </ItemBase>
    <ItemBase :title="t('settings.address')">
      <SelectAddress
        v-model="user.address"
        :label="t('settings.address')"
        @update:model-value="onAddress"
      />
    </ItemBase>

    <ItemBase :title="t('settings.language')">
      <SelectFullPage
        id="languagePane"
        v-model="profileLanguage"
        has-short-value
        fit-content
        :label="t('settings.language')"
        :items="languages"
      />
    </ItemBase>

    <ItemBase :title="t('settings.contacts')">
      <ButtonBase
        small
        variant="text"
        @click="openContactModal"
      >
        {{ t('settings.addContact') }}
      </ButtonBase>
      <div class="contacts">
        <div
          v-for="contact in contacts"
          :key="contact.id"
          class="contact"
        >
          <InputBasePhone
            v-if="contactTypes[contact.contact_type_id].type === 'PHONE'"
            v-model="contact.contact"
            :label="contactTypes[contact.contact_type_id].placeholder"
            @change="contact.isChanged = true"
          />
          <InputBaseWebsite
            v-else-if="contactTypes[contact.contact_type_id].type === 'WEBSITE'"
            v-model="contact.contact"
            :label="contactTypes[contact.contact_type_id].placeholder"
            @change="contact.isChanged = true"
          />
          <InputBase
            v-else
            v-model="contact.contact"
            :label="contactTypes[contact.contact_type_id].placeholder"
            @change="contact.isChanged = true"
          />
          <img
            class="delete-icon"
            src="@/assets/icons/other/add.svg"
            alt=""
            @click="handleDeleteContact(contact)"
          >
        </div>
      </div>
    </ItemBase>

    <div class="btn-wrapper">
      <ButtonBase @click="submit">
        {{ t('common.save') }}
      </ButtonBase>
      <ButtonBase
        danger
        @click="handleLogout"
      >
        {{ t('settings.exit') }}
      </ButtonBase>
    </div>

    <div class="profiledelete">
      <ButtonBase
        small
        danger
        variant="text"
        @click="handleDelete"
      >
        {{ t('settings.delete') }}
      </ButtonBase>
    </div>
  </LayoutBase>

  <ContactPane
    ref="ContactPaneREF"
    :contact-types="contactTypes"
    @add-contact="addContact"
  />
  <ContextPane
    ref="ContextPaneREF"
    :actions="['edit', 'delete']"
    @on-delete="onDelete"
    @on-edit="open"
  />
</template>

<script setup>
import { HeaderFullpage, ItemBase, InputSimple, InputBase, InputBaseWebsite, ButtonBase, InputCheckbox,
  SelectAddress, SelectFullPage } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { ContextPane } from '@/components/ContextPane'
import { ContactPane } from './components/ContactPane'
import { computed, onMounted, reactive, ref } from 'vue';
import { PageLoader } from '@/components/PageLoader'
import { successToast, errorToast } from '@/helpers/showToast'
import { useRouter } from 'vue-router'
import { getUser, changeUser, getContacts, deleteContact, changeContact, sendContact } from './api';
import { useFileDialog } from '@vueuse/core'
import { uploadPhoto } from '@/states/api'
import { useMainEntities } from '@/states/mainEntities'
import { useI18n } from 'vue-i18n'

import { useConfirm } from '@/composables/useConfirm';

import { getMaskedPhone } from '@/helpers/phone';
import { logout } from '@/app/api/auth';
import InputBasePhone from '@/UI/InputBase/InputBasePhone.vue';

import { changeLanguage } from '@/plugins/localization';
import { languages } from '@/app/common';

const { t } = useI18n()

const mainEntitites = useMainEntities()

const router = useRouter()

const isLoading = ref(true)
const ContextPaneREF = ref()
const ContactPaneREF = ref()

const user = reactive({})
const contacts = ref([])

const userPhone = computed(() => getMaskedPhone(user?.phone))
const contactTypes = {
  1: {
    placeholder: t('settings.contact.number'),
    type: 'PHONE',
  },
  2: {
    placeholder: t('settings.contact.tg'),
    type: 'DEFAULT',
  },
  3: {
    placeholder: t('settings.contact.wa'),
    type: 'PHONE',
  },
  4: {
    placeholder: t('settings.contact.mail'),
    type: 'EMAIL',
  },
  5: {
    placeholder: t('settings.contact.site'),
    type: 'WEBSITE',
  },
}

const openContactModal = () => {
  ContactPaneREF.value.open({ id: 0 })
}

const profileLanguage = ref('');
const init = async () => {
  const { user: u } = await getUser()
  Object.assign(user, u)
  if (!user.language) {
    user.language = 'ru';
  }

  profileLanguage.value = user.language;

  const { contacts: c } = await getContacts()
  contacts.value = c.map(el => ({ ...el, isChanged: false, isNew: false }))

  isLoading.value = false
}

const handleDeleteContact = async ({ id, isNew }) => {
  if (isNew) {
    contacts.value = contacts.value.filter(el => el.id !== id)
    return
  }

  const { success } = await deleteContact({ id })
  if (success) {
    contacts.value = contacts.value.filter(el => el.id !== id)
  }
}

const changeContacts = async () => {
  const promises = []
  contacts.value.forEach(el => {
    if (el.isNew) {
      promises.push(
        sendContact({
          contact_type_id: el.contact_type_id,
          contact: el.contact
        })
      )
      return
    }

    if (el.isChanged) {
      promises.push(
        changeContact(el)
      )

    }
  })
  await Promise.all(promises)
}

const addContact = ({ type }) => {
  contacts.value.push({
    "id": (new Date()).getTime(),
    "contact_type_id": type,
    "contact": "",
    "isNew": true
  })
}

const onAddress = ({ address, coords }) => {
  user.address = address
  user.coords = coords
}

const { open, onChange, files } = useFileDialog({
  multiple: false,
  reset: false,
  accept: import.meta.env.VITE_ACCEPT_PHOTO
})

const onDelete = () => {
  if (user.photo) {
    user.photo = ''
  }
}

const submit = async () => {

  if (!user.name) {
    errorToast({
      description: t('settings.toast.name')
    })
    return
  }

  await changeContacts()

  if (files.value) {
    const { paths } = await uploadPhoto({ photos: Array.from(files.value) })
    user.photo = paths[0].path
  }

  const { initMainEntities } = mainEntitites;

  const { success } = await changeUser({ user, language: profileLanguage.value })

  if (success) {
    successToast({
      description: t('settings.toast.change')
    })
    router.push('/')
    changeLanguage(profileLanguage.value, user.language)
    if (profileLanguage.value !== user.language) {
      initMainEntities()
    }
    mainEntitites.isDark = user.dark;
  } else {
    errorToast({
      description: t('toast.somethingWrong')
    })
  }
}

const openContextPhoto = () => {
  ContextPaneREF.value.open({ id: 0 })
}
onChange(([file]) => {
  user.photo = URL.createObjectURL(file)
})

const { onConfirm } = useConfirm();
const handleLogout = async () => {
  const response = await onConfirm({ title: t('profile.toast.exitConfirm') })
  if (response) {
    logout();
  }
}
const handleDelete = async () => {
  const response = await onConfirm({ title: t('settings.toast.deleteConfirm') })
  if (response) {
    logout()
  }
}

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
.user-photo {
    border: 3px solid #5B43EF;
    max-width: 80px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.user-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
}

.contact {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 10px;
}

.contacts {
    display: grid;
    gap: 10px;
}

.profiledelete {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-wrapper {
  display: grid;
  gap: 20px;
}
</style>
