import { createToast } from 'mosha-vue-toastify';

export const warningToast = ({ title = 'Внимание', description }) => createToast({
  title,
  description,
},
{
  position: 'top-center',
  type: 'warning',
  transition: 'bounce',
  hideProgressBar: true,
})

export const successToast = ({ title = 'Успешно', description }) => createToast({
  title,
  description,
},
{
  position: 'top-center',
  type: 'success',
  transition: 'bounce',
  hideProgressBar: true,
})
export const errorToast = ({ title = 'Ошибка', description }) => createToast({
  title,
  description,
},
{
  position: 'top-center',
  type: 'danger',
  transition: 'bounce',
  hideProgressBar: true,
})

