<template>
  <div
    v-bind="$attrs"
    class="select-full-page"
    :class="{
      disabled: disabled,
      'no-label': hiddenPlaceholder,
      'has-value': modelValue,
      'is-tiny': isTiny,
    }"
    @click="handleClick"
  >
    <slot name="icon" />

    <div v-if="!hiddenPlaceholder" class="label">
      {{ label }}
    </div>
    <div
      v-if="modelValue"
      class="modelValue"
    >
      <slot name="modelValue">
        {{ getItemById(modelValue) }}
      </slot>
    </div>

    <div style="flex: 1;" />
    <img
      v-if="!disabled"
      src="@/assets/icons/other/small-arrow.svg"
      class="arrow"
    >
  </div>

  <PanePromise
    :id="id"
    ref="SelectRef"
    :fit-content="fitContent"
    @close="onClose"
  >
    <LayoutBase
      style="display: flex; flex-direction: column; height: 100%;"
      gap
      :title="label"
    >
      <ItemBase>
        <slot name="prepend" />
        <slot name="data">
          <div class="pane-inner">
            <slot name="pane-inner-prepend" />
            <div v-if="!items.length" style="text-align: center; padding: 20px;">
              <template v-if="isLoading === false">
                {{ t('component.address.noResults') }}
              </template>
            </div>
            <SelectFullPageItem
              v-for="item in items"
              :key="item.id"
              :item="item"
              :value="modelValue"
              @click="onChoose(returnObject ? item : item.id)"
            >
              <template #item-prepend>
                <slot name="item-prepend" :item="item" />
              </template>
            </SelectFullPageItem>
          </div>
        </slot>
      </ItemBase>
      <slot name="append" />
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { ref } from 'vue'
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ItemBase } from '@/UI'

import SelectFullPageItem from './SelectFullPageItem.vue';

const props = defineProps({
  modelValue: {
    type: [Number, String, Boolean],
    default: '',
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  hiddenPlaceholder: {
    type: Boolean,
    default: false,
  },
  hasShortValue: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  isTiny: {
    type: Boolean,
    default: false
  },
  fitContent: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    deafult: '',
  },
  id: {
    type: String,
    deafult: '',
    required: true
  },
  items: {
    type: Array,
    deafult: () => [],
  },
  returnObject: {
    type: Boolean,
    default: false,
  }
})
const emit = defineEmits(['update:modelValue', 'close', 'open'])

const getItemById = (id) => {
  const selectedItem = props.items.find(el => el.id === id);
  if (!selectedItem) {
    return null;
  }
  if (selectedItem?.short) {
    return selectedItem.short;
  }

  return selectedItem.title || selectedItem.name
}

const SelectRef = ref()

const handleClick = () => {
  setTimeout(() => {
    SelectRef.value.open({})
    emit('open');
  }, 100);
}

const handleClose = () => {
  if (SelectRef.value) {
    SelectRef.value.close()
  }
  emit('close')
}

const onChoose = (payload) => {
  emit('update:modelValue', payload)
  handleClose();
}

const onClose = () => {
  handleClose()
}

defineExpose({ onChoose, handleClose })

</script>

<style lang="scss" scoped>
[theme="dark"] {
  .select-full-page {
    background: #37363C;
    border: 1px solid #37363C;
  }
  .pane-selector {
    background: #37363C;
    color: #7A7B87;
    border: 1px solid #37363C;
  }

  .pane-inner {
    background: #37363C;
    border: 1px solid #37363C;
  }

  .pane-item-title, .title, .modelValue {
    color: #fff;
  }
}

.disabled {
  pointer-events: none;
}

.select-full-page {
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid #E8EAFC;
  background: #FFF;
  padding: 26px 20px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  max-height: 60px;
  height: 60px;

  position: relative;
  &.has-value .label{
    top: 10px;
    font-size: 14px;
  }
}

.btn-selector {
  position: sticky;
  bottom: 20px;
}

.pane-inner {
  border-radius: 20px;
  border: 1px solid #E8EAFC;
  background: #FFF;
  padding: 20px 25px;
  max-height: 66vh;
  overflow-y: scroll;

  display: flex !important;
  flex-direction: column;
  gap: 10px !important;
  // padding-top: 0 !important;
  display: grid;
  overflow-y: auto;
}

.label {
  color: rgb(107, 112, 144);
  position: absolute;
  top: 20px;
  left: 20px;
  pointer-events: none;
  transition: all 0.2s;
}

.no-label {
  padding: 0px 20px;

  .arrow {
    top: 0;
    width: 13px;
  }
}

.pane-img {
  width: 30px;
  height: 30px;
}

.arrow {
  position: relative;
  top: -6px;
  width: 13px;
  transform: rotate(-90deg);
}

.is-tiny {
  background: transparent;
  padding: initial;
  border: unset;

  .arrow {
    top: auto;
  }
}
</style>
