import { Geolocation } from '@capacitor/geolocation';
import { Device } from '@capacitor/device';
import { ref } from 'vue'

export const myCoords = ref(null)
const state = { init: false, id: 0 }

const INITIAL_COORDS = {
  latitude: 55.741492,
  longitude: 37.615489
}

export const getCurrentPosition = async () => {

  let latitude = INITIAL_COORDS.latitude
  let longitude = INITIAL_COORDS.longitude

  const { platform } = await Device.getInfo()

  try {
    if (platform !== 'web') {
      const out = await Geolocation.requestPermissions()
      console.log(out)
    }

    const { location } = await Geolocation.checkPermissions()

    if (platform === 'web' && ['granted', 'prompt'].includes(location)) {
      const { coords } = await getWebPosition()
      latitude = coords.latitude
      longitude = coords.longitude
    }

    if (location == 'granted') {
      if (platform === 'android') {
        const { coords } = await getDevicePosition();
        latitude = coords.latitude
        longitude = coords.longitude
      }

      if (platform === 'ios') {
        const { coords } = await Geolocation.getCurrentPosition();
        latitude = coords.latitude
        longitude = coords.longitude
      }
    }

  } catch (e) {
    console.log('getCurrentPosition error', e);
    latitude = INITIAL_COORDS.latitude
    longitude = INITIAL_COORDS.longitude
  }

  return { coords: [latitude, longitude] }
}
const init = async () => {
  myCoords.value = (await getCurrentPosition()).coords
}
init()

function getDevicePosition() {
  return new Promise(async (resolve, reject) => {
    try {
      if (state.init === false) {
        state.init = true;
        state.id = await Geolocation.watchPosition(
          {
            enableHighAccuracy: true,
            maximumAge: 0
          },
          async (data) => {
            try {
              await Geolocation.clearWatch({ id: state.id });
              resolve(data);
            } catch (clearError) {
              reject(clearError);
            }
          }
        );
      } else {
        const data = await Geolocation.getCurrentPosition();
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

function getWebPosition() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      (error) => {
        reject(error);
      }
    );
  }).catch((error) => {
    console.error('Geolocation error:', error.code);
  });
}
