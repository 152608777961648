import { api } from "@/app/api"

export const sendSupport = async ({ subject, text }) => {
  try {
    const { data } = (await api.post('/v1/support',
      { subject, text }
    ))
    return data
  } catch (error) {
    console.error(error)
  }
}
