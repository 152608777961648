<template>
  <div
    class="wrap-input"
    @click="handleClick"
  >
    <span>
      {{ props.placeholder }}
    </span>
    {{ props.modelValue }}
  </div>

  <PanePromise
    :id="props.id"
    ref="PanePromiseREF"
    fit-content
    disabled
  >
    <div class="wrapper">
      <div class="container">
        <div class="picker">
          <ul
            class="picker-day"
            @scroll="onScrollStop"
          >
            <li
              v-for="item in HOURS"
              :id="Number(item) + String(+random) + 'h'"
              :key="item"
            >
              {{ item }}
            </li>
          </ul>
          <div class="bg" />
        </div>

        <span class="double-dots">:</span>
        <div class="picker">
          <ul
            class="picker-day"
            @scroll="onScrollStop2"
          >
            <li
              v-for="item in MINUTES"
              :id="Number(item) + String(+random) + 'm'"
              :key="item"
            >
              {{ item }}
            </li>
          </ul>
          <div class="bg" />
        </div>
      </div>
      <ButtonBase @click="select">
        {{ t('common.choose') }}
      </ButtonBase>
    </div>
  </PanePromise>
</template>

<script setup>
import { ButtonBase, PanePromise } from "@/UI";
import { ref, onMounted } from "vue";

const PanePromiseREF = ref()

const props = defineProps({
  modelValue: {
    type: String,
    default: '00:00'
  },
  placeholder: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

const random = Math.floor(Math.random() * 1000);

const HOURS = new Array(24)
  .fill(0)
  .map((el, idx) => (String(idx).length === 1 ? `0${ String(idx)}` : idx));
const MINUTES = new Array(60)
  .fill(0)
  .map((el, idx) => (String(idx).length === 1 ? `0${ String(idx)}` : idx));

let isScrolling;

let h = 0;
let m = 0;
const onScrollStop = (e) => {
  clearTimeout(isScrolling);
  isScrolling = setTimeout(() => {
    h = Math.floor(e.target.scrollTop / 70.56521739130434);
  }, 150);
};

let isScrolling2;
const onScrollStop2 = (e) => {
  clearTimeout(isScrolling2);
  isScrolling2 = setTimeout(() => {
    m = Math.floor(e.target.scrollTop / 70.22033898305085);
  }, 150);
};

const select = () => {
  const Hours = String(h).length === 1 ? `0${ String(h)}` : h;
  const Minutes = String(m).length === 1 ? `0${ String(m)}` : m;
  emit('update:modelValue', `${Hours}:${Minutes}`)
  PanePromiseREF.value.close()
};

const handleClick = () => {

  PanePromiseREF.value.open({})

  const el = document.getElementById(
    `${String(Number((props.modelValue).split(":")[0]) - 1) + String(random) }h`
  );

  const el2 = document.getElementById(
    `${String(Number((props.modelValue ?? "00:00").split(":")[1]) - 1) + String(random) }m`
  );

  try {
    el.scrollIntoView();
  } catch (error) {

  }
  try {
    el2.scrollIntoView();
  } catch (error) {

  }
}

const init = async () => {

}
onMounted(() => {
  init()

})
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .wrap-input {
        background: #37363C;
        border: 1px solid #37363C;

        color: #fff;

        span {
            color: #8E92AF;
        }
    }

    .picker li {
        color: #fff;

    }

    .picker {
        &::after {
            background: linear-gradient(rgb(42 42 42), #00000000);
        }

    }

    .bg {
        background: linear-gradient(rgb(42 42 42), #00000000);
    }

}

.wrap-input {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 23px 20px;

    span {
        color: #8E92AF;
    }
}

.card {
    border-radius: 40px 40px 0px 0px;
    background: #f9fafd;
    border: 1px solid #edeffd;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.05);
}

.wrapper {
    padding: 20px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.double-dots {
    font-family: "NunitoLight";
    font-size: 50px;
    align-self: center;
    color: #555555;
    margin: 0 10px;
}

.container {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    padding-bottom: 20px;
}

.bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 35%;
    background: linear-gradient(rgba(#f9fafd, 0.7), #f9fafd);
    border-top: 2px solid #4655c4;
    pointer-events: none;
}

.picker {
    display: flex;

    position: relative;

    justify-content: center;
    width: fit-content;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 35%;
        background: linear-gradient(#f9fafd, rgba(#f9fafd, 0.7));
        border-bottom: 2px solid #4655c4;
        pointer-events: none;
    }

    ul {
        max-height: 180px;
        overflow-y: scroll;
        padding-left: 0;
        scroll-snap-type: y mandatory;
    }

    li {
        scroll-snap-align: center;
        text-align: left;
        list-style: none;

        font-family: "NunitoLight";
        font-size: 50px;
        color: #555555;
    }
}

li {
    &:first-of-type {
        margin-top: 68px;
    }

    &:last-of-type {
        margin-bottom: 68px;
    }

    height: 70px;
}

.picker-day {
    -ms-overflow-style: none; // IE 10+

    &::-webkit-scrollbar {
        display: none;
    }
}

.button-container {
    text-align: center;
    padding-top: 3px;
    width: 100%;
}
</style>

