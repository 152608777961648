<template>
  <div
    class="auth__container smooth"
    :class="{
      'is-ready': isInterfaceReady
    }"
  >
    <h3 class="auth__title">
      {{ t('auth.title') }}
    </h3>
    <div class="auth__form">
      <EnterPhoneStep v-if="authStore.step === 0">
        <template #footer>
          <SelectFullPage
            id="publicLanguagePane"
            v-model="currentLanguage"
            class="language-container"
            has-short-value
            is-tiny
            fit-content
            :items="languages"
            @update:model-value="onLanguageChange"
          >
            <template #icon>
              <img src="@/assets/icons/other/globe.svg" style="width: 20px;">
            </template>
          </SelectFullPage>
        </template>
      </EnterPhoneStep>
      <OtpConfirmationStep v-else />
    </div>
  </div>
</template>

<script setup>
import { getVersion } from './api'
import { onMounted, ref, watch } from 'vue'
import EnterPhoneStep from './steps/EnterPhoneStep.vue'
import OtpConfirmationStep from './steps/OtpConfirmationStep.vue'
import { useAuthStore } from './store'

import { SelectFullPage } from '@/UI'

import { changeLanguage } from '@/plugins/localization';
import { languages } from '@/app/common';

import { useSystem } from '@/composables/useSystem';

const version = ref('')
const authStore = useAuthStore()

const init = async () => {
  const { type } = await getVersion()
  version.value = `${import.meta.env.VITE_VERSION} \n ${type}`
}

onMounted(() => {
  init()
})

let timer
watch(
  () => authStore.timeout,
  (val) => {
    if (val > 0) {
      if (timer) {return}
      timer = setInterval(() => {
        authStore.timeout -= 1
      }, 1000)
    } else {
      clearInterval(timer)
      authStore.timeout = 0
      timer = null
    }
  },
)

const isInterfaceReady = ref(false);

const {
  currentLanguage,
  getDeviceLocale,
  initDeviceLanguage
}= useSystem()

getDeviceLocale().then(res => {
  initDeviceLanguage(res);
}).finally(() => {
  isInterfaceReady.value = true;
});

const onLanguageChange = () => {
  changeLanguage(currentLanguage.value)
}
</script>

<style lang="scss" src="./assets/styles.scss" />
