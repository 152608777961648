import { api } from "@/app/api"

export const getOrder = async ({ id }) => {
  try {
    const { data } = await api.get(`/v2/orders/${ id}`)
    return data
  } catch (error) {

    console.error(error)
  }
}

export const addToFavorite = async ({ id }) => {
  try {
    const { data } = (await api.post('/v1/favourites', {
      order_id: id
    }))
    return data
  } catch (error) {
    console.error(error)
  }
}
export const removeFromFavorite = async ({ id }) => {
  try {
    const { data } = (await api.delete('/v1/favourites', {
      data: {
        order_id: id
      }
    }))
    return data
  } catch (error) {
    console.error(error)
  }
}
