<template>
  <div class="wrapper">
    <div class="inner ">
      <HeaderFullpage :title="partnerName" />

      <SpinerBase
        v-if="isLoading"
        class="spiner"
      />
      <div
        v-else
        class="container"
      >
        <ChatMessage
          v-for="msg in sortMsgs"
          :id="msg.id"
          :key="msg.id"
          :reply-message="getMessageById(msg.reply_id)"
          :message="msg"
          :user-reply="users[getMessageById(msg.reply_id)?.account_id]"
          :user="users[msg.account_id]"
          :is-me="msg.account_id === users.me"
          @reply="(id) => replyId = id"
          @scroll-to-reply="(id) => scrollTo(id, true)"
        />
      </div>
      <div class="bottom">
        <div
          v-if="replyMessage"
          class="previewReply"
        >
          <div class="replyTitle">
            {{ t('chat.reply') }}
          </div>
          <div class="previewInner">
            <ChatMessage
              :key="replyMessage.id + 'reply'"
              draggble
              :message="replyMessage"
              :user="users[replyMessage.account_id]"
              :is-me="replyMessage.account_id === users.me"
            />
            <img
              src="@/assets/icons/other/pin.svg"
              @click="replyId = null"
            >
          </div>
        </div>

        <div class="input__wrapper">
          <input
            v-model.trim="message"
            :placeholder="t('chat.enter')"
            type="text"
            class="input"
            @focus="scrollToBottom"
            @keyup.enter="sendMsg"
          >
          <img
            src="@/assets/icons/other/pin.svg"
            @click="open"
          >
          <img
            src="@/assets/icons/other/send.svg"
            @click="sendMsg"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { HeaderFullpage, SpinerBase } from '@/UI'
import { ChatMessage } from './components/ChatMessage'
import { computed, onMounted, ref, watch } from 'vue';
import { fetchMessages, sendMessage, uploadPhoto } from './api'
import { useFileDialog } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { useSocket } from '@/composables/useSocket';
const { t } = useI18n()

const isLoading = ref(true)

const route = useRoute()
const msgs = ref([])
const users = ref(null)
const partnerName = ref('')
const message = ref('')
const replyId = ref(null)

const messageLength = computed(() => sortMsgs.value.length)

const init = async () => {
  const { id } = route.params
  const { messages, names } = await fetchMessages({ id })
  users.value = (names)

  partnerName.value = ((Object.entries(names).filter(el => el[0] !== String(names.me) && el[0] !== 'me'))?.[0]?.[1])?.name
  msgs.value = messages

  isLoading.value = false
}

const getMessageById = (id = null) => {
  if (!id) {return null}
  return sortMsgs.value.find(el => el.id === id)
}

const scrollTo = (id = null, smooth = false) => {
  if (!id) {id = (sortMsgs.value[sortMsgs.value.length - 1])?.id}
  if (!id) {return}

  document.getElementById(id)?.scrollIntoView({
    behavior: smooth ? 'smooth' : 'auto',
    block: 'center'
  })

}

const sortMsgs = computed(() => msgs.value.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)))

const sendMsg = async ({ img = null }) => {
  if (!message.value && !img) {return}

  const { data } = await sendMessage({
    chat_id: Number(route.params.id),
    message: img ? '' : message.value,
    image: img || '',
    reply_id: replyId.value
  })
  msgs.value.push(data)

  message.value = ''
  replyId.value = null

}

const fetchChats = async () => {
  const { id } = route.params
  const { messages } = await fetchMessages({ id })
  msgs.value = messages
}

const { open, onChange: onChangeFiles, reset: resetFiles } = useFileDialog({
  multiple: false,
  reset: true,
  accept: import.meta.env.VITE_ACCEPT_PHOTO
})

onChangeFiles(async (files) => {
  const photo = files[0]

  if (!photo.type.startsWith('image/')) {return}

  const formData = new FormData()
  formData.append('file', photo)
  console.log('send')
  const { paths } = await uploadPhoto({ formData })
  const { path } = paths[0]

  sendMsg({ img: path })
})

const replyMessage = computed(() => sortMsgs.value.find((el) => el.id === replyId.value))

watch(messageLength, () => {
  scrollTo()
}, {
  flush: 'post'
})

const scrollToBottom = async () => {
  await new Promise((res) => setTimeout(res, 1000))
  scrollTo(null, true)
}

const { socket } = useSocket();
socket.on("message", (msg) => {
  msgs.value.push(msg)
});

onMounted(() => {
  init()
})

</script>

<style lang="scss" scoped>
[theme="dark"] {
    .input__wrapper {
        background: #292929;
    }

    .bottom {
        background: #292929;

    }

    .input {
        background: #37363C;
        border: 1px solid #37363C;
        color: #fff;
    }
}

// #1B1B1B
.wrapper {
    height: 100vh;
    // height: 100dvh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.spiner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.replyTitle {
    text-align: center;
    color: #6B7090;
    font-size: 17px;
    font-weight: 700;
}

.previewInner {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.previewReply {
    display: grid;
    gap: 10px;
}

.inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    // max-height: 100dvh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.container {
    flex-grow: 1;
    display: grid;
    justify-items: stretch;
    align-content: end;
    padding: 20px;
    gap: 10px;
}

.cancelImg {
    width: 80px;
    height: 80px;
    transform: rotate(45deg);
}

.bottom {
    position: sticky;
    bottom: 0;
    padding: 20px;
    background: #F9FAFD;
    display: grid;
    gap: 10px;
}

.input__wrapper {
    display: grid;
    grid-template-columns: 1fr 40px 40px;
    align-items: center;
    gap: 10px;
}

.input {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    height: 40px;
    color: #000;
    padding: 20px;
}
</style>
