<template>
  <PanePromise
    id="demoMode"
    ref="demoPaneRef"
    fit-content
    @close="no"
  >
    <LayoutBase gap="30">
      <div class="title">
        {{ t('demoMode.title') }}
      </div>
      <div class="btns">
        <ButtonBase @click="yes">
          {{ t('demoMode.authButton') }}
        </ButtonBase>
        <ButtonBase variant="text" @click="no">
          {{ t('demoMode.continueButton') }}
        </ButtonBase>
      </div>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { ref, watch } from 'vue';
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ButtonBase } from '@/UI'

import { useDemoMode } from '@/composables/useDemoMode';

const demoPaneRef = ref()

const { isDemoModeDialogOpen, handleResponse } = useDemoMode();

const yes = async () => {
  handleResponse(true);
  demoPaneRef.value.close()
}

const no = () => {
  handleResponse(false);
  demoPaneRef.value.close()
}

const openContext = async () => {
  await demoPaneRef.value.open()
}

watch(isDemoModeDialogOpen, () => {
  if (isDemoModeDialogOpen.value) {
    openContext();
  }
})

defineExpose({
  open: openContext
})
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  @include font-bold;
}

.btns {
  display: grid;
  gap: 10px;
}
</style>
