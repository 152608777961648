<template>
  <PanePromise
    id="edit"
    ref="PanePromiseREF"
    fit-content
  >
    <LayoutBase gap="10">
      <ButtonBase
        v-if="props.actions.includes('edit')"
        @click="edit"
      >
        {{ t('common.edit') }}
      </ButtonBase>
      <ButtonBase
        v-if="props.actions.includes('add-showcase')"
        @click="addShowcase"
      >
        {{ t('accounts.addShowcase') }}
      </ButtonBase>
      <ButtonBase
        v-if="props.actions.includes('edit-showcase')"
        @click="editShowcase"
      >
        {{ t('accounts.editShowcase') }}
      </ButtonBase>
      <ButtonBase
        v-if="props.actions.includes('delegate')"
        @click="delegate"
      >
        {{ t('common.delegate') }}
      </ButtonBase>
      <ButtonBase
        v-if="props.actions.includes('fork')"
        @click="fork"
      >
        {{ t('component.contextPane.createFromAccount') }}
      </ButtonBase>
      <ButtonBase
        v-if="props.actions.includes('chat')"
        @click="chat"
      >
        {{ t('component.contextPane.startChat') }}
      </ButtonBase>
      <ButtonBase
        v-if="props.actions.includes('delete')"
        danger
        @click="del"
      >
        {{ t('common.delete') }}
      </ButtonBase>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { ref } from 'vue';
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ButtonBase } from '@/UI'

const props = defineProps({
  actions: {
    type: Array,
    default: () => [],
  }
})

const emit = defineEmits(['onDelete', 'onEdit', 'onChat', 'onFork', 'onDelegate', 'add-showcase', 'edit-showcase'])

const PanePromiseREF = ref(null);

const closePane = async () => {
  if (PanePromiseREF.value) {
    // await PanePromiseREF.value.close()
  }
}

const idValue = ref(0)
const edit = async () => {
  emit('onEdit', (idValue.value))
  closePane()
}

const del = () => {
  emit('onDelete', (idValue.value))
  closePane()
}
const fork = () => {
  emit('onFork', (idValue.value))
  closePane()
}
const delegate = () => {
  emit('onDelegate', (idValue.value))
  closePane()
}
const chat = () => {
  emit('onChat', (idValue.value))
  closePane()
}
const addShowcase = () => {
  emit('add-showcase', (idValue.value))
  closePane()
}
const editShowcase = () => {
  emit('edit-showcase', (idValue.value))
  closePane()
}

const openContext = async ({ id }) => {
  idValue.value = id
  await PanePromiseREF.value.open()
}

defineExpose({
  open: openContext
})
</script>
