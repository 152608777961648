
import { api } from '@/app/api'

export const fetchOrders = async (ids) => {
  try {
    const { data } = (await api.post('/v1/orders/ids', ids))
    return data
  } catch (error) {

    console.error(error)
  }
}

