import { computed, ref } from "vue";
import { useStore } from '@/states/index'

export const isDemoModeDialogOpen = ref(false);
let resolveFn = () => {};
const options = ref({});

export const useDemoMode = () => {
  const store = useStore()

  const isDemoMode = computed(() => store.isDemoMode);

  const onOpenDemoModePane = () => {
    isDemoModeDialogOpen.value = true;

    return new Promise((resolve) => {
      resolveFn = resolve;
    })
  }

  const handleResponse = (answer) => {
    isDemoModeDialogOpen.value = false;
    resolveFn(answer)
    setTimeout(() => {
      options.value = {};
    }, 200);
  }

  return {
    isDemoMode,
    isDemoModeDialogOpen,
    onOpenDemoModePane,
    handleResponse
  }
}

