<template>
  <div class="wrapper">
    <input
      v-bind="$attrs"
      ref="input"
      :placeholder="placeholder ?? t('menu.placeholder')"
      :value="modelValue"
      type="text"
      class="input"
      @input="onInput"
    >
    <SearchIcon class="search-icon" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { SearchIcon } from '@/UI/icons'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const input = ref()

defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String
  }
})

const emit = defineEmits(['update:modelValue'])

const onInput = (e) => {
  emit('update:modelValue', e.target.value)
}

defineExpose({
  focus: () => input.value?.focus(),
})
</script>

<style lang="scss" scoped>
[theme='dark'] {
  .input {
    color: #8E92AF;

    &::placeholder {
      color: #8E92AF;
    }
  }
}

.wrapper {
  position: relative;
}

.input {
  background: var(--bgBase);
  border: 1px solid #e1ebf8;
  border-radius: 50px;
  padding: 18px 20px;
  font-weight: 500;
  font-size: 14px;
  max-height: 50px;

  transition: border 0.3s;

  &:focus {
    border: 1px solid #5b43ef;
  }

  &::placeholder {
    color: #8E92AF;
  }
}

.search-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #555555;
}

body[theme='dark'] {
  .search-icon {
    color: #8E92AF;
  }

  .input {
    background: #37363c;
    border: 1px solid #37363c;
  }
}
</style>
