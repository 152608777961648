import { api } from "@/app/api"

export const uploadPhoto = async ({ formData }) => {
  try {
    const { data } = await api.post('/v1/files/upload', formData )
    return data
  } catch (error) {
    console.error(error)
  }
}
export const editAccount = async ({ form }) => {
  try {
    const { data } = await api.put('/v1/accounts', form )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getAccount = async ({ id }) => {
  try {
    const { data } = await api.get(`/v1/accounts/${id}`)
    return data
  } catch (error) {
    console.error(error)
  }
}

export const createAccount = async ({ form }) => {
  try {
    const { data } = await api.post('/v1/accounts', form )
    return data
  } catch (error) {
    console.error(error)
  }
}
