<template>
  <div class="showcases__form">
    <div class="showcases__form__row">
      <InputBase
        v-model="payload.name"
        :label="t('showcase.label.serviceName')"
        class="showcases__form__row__field"
        :errors="v$.name.$errors"
      />
    </div>
    <div class="showcases__form__row">
      <TextareaBase
        v-model="payload.description"
        :label="t('showcase.label.serviceDescription')"
        class="showcases__form__row__field"
      />
    </div>
    <div
      class="showcases__form__row"
      style="display: flex; gap: 14px;"
    >
      <div style="width: 60%;">
        <InputBaseNumber
          v-model="payload.price"
          :label="t('showcase.label.servicePrice')"
          class="showcases__form__row__field"
          :errors="v$.price.$errors"
        />
      </div>
      <div style="width: 40%;">
        <SelectFullPage
          id="currencyPane"
          v-model="payload.currency_id"
          fit-content
          has-short-value
          :label="t('showcase.label.currency')"
          :items="currencies"
        />
      </div>
    </div>

    <div class="showcases__form__row">
      <photo-gallery
        v-model="payload.files"
        button-type="text"
        activator-class-name="showcases__form__gallery"
      />
    </div>

    <div class="showcases__form__actions">
      <slot
        name="actions"
        :item="payload"
      />
      <button-base
        class="showcases__form__btn"
        :loading="loading"
        @click="submit"
      >
        {{ submitBtnText || t('common.add') }}
      </button-base>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { InputBase, InputBaseNumber, SelectFullPage, ButtonBase, TextareaBase } from '@/UI'
import { ShowcasesItemTypes } from '@/screens/Account/constants/showcases'
import { PhotoGallery } from '@/components/PhotoGallery'
import { useVuelidate } from '@vuelidate/core'
import { useValidations } from '@/helpers/vuelidate-wrapper'

import { useMainEntities } from '@/states/mainEntities'

const mainEntities = useMainEntities()
const currencies = computed(() => mainEntities.currencies);

const { required, maxValue } = useValidations()

const rules = {
  name: {
    required,
  },
  price: {
    required,
    maxValue: maxValue(100000000),
  },
}

const defaultState = {
  name: '',
  description: '',
  price: '',
  currency_id: 1,
  volume_id: '',
  files: [],
  type: ShowcasesItemTypes.services,
}

const props = defineProps({
  predefinedData: {
    type: Object,
    default: () => {},
  },
  handler: {
    type: Function,
  },
  submitBtnText: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    validate(value) {
      return ['create', 'update'].includes(value)
    },
    default: 'create',
  },
})

const payload = ref({
  name: props.predefinedData?.name ?? '',
  description: props.predefinedData?.description ?? '',
  price: Number(props.predefinedData?.price),
  currency_id: props.predefinedData?.currency_id || 1,
  volume_id: props.predefinedData?.volume_id,
  files: Array.isArray(props.predefinedData?.files)
    ? props.predefinedData?.files ?? []
    : [],
  type: ShowcasesItemTypes.services,
})
const loading = ref(false)
const v$ = useVuelidate(rules, payload)

async function submit() {
  const isValid = await v$.value.$validate()

  if (!isValid) {
    return
  }

  loading.value = true

  props.handler(payload.value).finally(() => {
    loading.value = false
  })
}

function reset() {
  payload.value = { ...defaultState }
}

defineExpose({
  reset,
})
</script>
