<template>
  <HeaderFullpage :title="t('notifications.title')" />
  <PageLoader v-if="isLoading" />
  <LayoutBase v-else>
    <div
      v-if="nofifications.length === 0"
      class="info"
    >
      {{ t('notifications.null') }}
    </div>
    <div class="pet-list">
      <div
        v-for="item in nofifications"
        class="pet"
        @click="onNotificationClick(item)"
      >
        <IconBase v-if="item.category_id" :path="`category/${item.category_id}.svg`" class="pet__img" />
        <img
          v-else
          src="./assets/chat.svg"
          class="pet__img"
        >

        <div class="body">
          <div class="title">
            {{ item.title }}
            <IconBase v-if="isLink(item)" :path="`other/link.svg`" style="width: 16px; height: 16px;" />
          </div>
          <div class="subtitle">
            {{ item.message }}
          </div>
        </div>
        <div class="time">
          {{ getDate(item.created_at) }} <br> {{ formatTime(item.created_at) }}
        </div>
      </div>
    </div>
  </LayoutBase>

  <BasePane ref="notificationPane" :title="selectedItem.title">
    <template #append>
      <span style="font-size: 12px; padding: 20px 10px 0;">Время уведомления: {{ formatTime(selectedItem.created_at) }}</span>
    </template>

    {{ selectedItem.message }}
  </BasePane>
</template>

<script setup>
import { HeaderFullpage, IconBase } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { onMounted, ref, } from 'vue';
import { PageLoader } from '@/components/PageLoader'
import BasePane from '@/components/BasePane'
import { getNotifications } from './api'
import { formatTime } from '@/helpers/formatTime'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const router =useRouter()
const { t } = useI18n()

const isLoading = ref(true)
const nofifications = ref([])

const init = async () => {
  const { messages } = (await getNotifications())
  nofifications.value = messages
  console.log(nofifications.value);
  isLoading.value = false
}

const getDate = (date) => {
  date = new Date(date)
  let dd = date.getDate();
  if (dd < 10) {dd = `0${ dd}`;}

  let mm = date.getMonth() + 1;
  if (mm < 10) {mm = `0${ mm}`;}

  let yy = date.getFullYear() % 100;
  if (yy < 10) {yy = `0${ yy}`;}

  return `${dd }.${ mm }.${ yy}`;
}

onMounted(() => {
  init()
})

const notificationPane = ref(null);
const selectedItem = ref({});
const onNotificationClick = (item) => {
  if (item.route && item.route_id) {
    router.push(`/${item.route}/${item.route_id}`)
  } else {
    selectedItem.value = item;
    notificationPane.value.open()
  }
}
const isLink = (item) => item.route && Boolean(item.route_id)

</script>

<style lang="scss" scoped>
[theme="dark"] {
    .pet {

        background: #37363C;
    }

    .pet__img {
        background: #292929;
    }

    .title {
        color: #fff
    }

    .subtitle {
        color: #9A9EB9;
    }

    .time {
        color: #9A9EB9;
    }
}

.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.pet-list {
    display: grid;
    gap: 10px;
}

.info {
    color: #C6C8DA;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}

.pet {
    &__img {
        padding: 10px;
        border-radius: 20px;
        background: #F2F8FF;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
    }

    border-radius: 26px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 10px;
}

.title {
    color: #5B5F7C;
    font-size: 15px;
    font-weight: 700;
    line-height: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subtitle {
    color: #8E92AF;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.body {
    display: grid;
    gap: 8px;
}

.time {
    color: #6B7090;
    font-size: 14px;
    font-weight: 600;
    align-self: start;
    text-align: end;
    margin-top: 16px;
}
</style>
