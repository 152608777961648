<template>
  <div
    class="tab"
    :class="{ disabled: props.disabled }"
  >
    <div
      v-for="item in props.options"
      class="tab-item"
      @click="handleClick(item.value)"
    >
      <div class="title">
        {{ item.label }}
      </div>
      <div class="checkbox">
        <div
          :class="{ 'isActive': item.value === props.modelValue }"
          class="select"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  options: {
    type: Array,
    requied: true
  },
  modelValue: {
    type: [Boolean, String, Number],
    requied: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const handleClick = (value) => {
  if (props.disabled) {return}
  emit('update:modelValue', value)
}
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .tab-item {
        background: #37363C;
        border: 1px solid #37363C;
        color: #fff;
    }

    .checkbox {
        background: #292929;
    }
}

.checkbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #E9ECF5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.select {
    width: 14px;
    height: 14px;
    border-radius: inherit;
    background: #5B43EF;
    opacity: 0;
    transition: all .3s ease;

    &.isActive {
        opacity: 1;
    }
}

.tab {
    display: flex;
    gap: 10px;
    overflow-y: auto;

    &.disabled {
        pointer-events: none;
    }
}

.tab-item {
  flex: 1;
  padding: 20px;
  border: 1px solid #E8EAFC;
  border-radius: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.title {
  color: rgb(107, 112, 144);
  font-weight: 600;
}
</style>
