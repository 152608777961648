<template>
  <div
    class="swiper-wrapper"
    :class="{
      'is-desktop': !isMobileDevice
    }"
  >
    <swiper
      :modules="[FreeMode]"
      :slides-per-view="'auto'"
      :loop="false"
      :autoplay="false"
      style="width: 100%"
      :space-between="10"
      class="swiper-container"
    >
      <swiper-slide
        v-for="item in items.slice(0, 4)"
        :key="item.id"
      >
        <showcase-product-card
          type="simple"
          :item="item"
          :to="{
            name: 'account-showcases-products-single',
            params: {
              id: accountId,
              productId: item.id,
            },
          }"
        />
      </swiper-slide>
      <swiper-slide v-if="items.length >= 4">
        <div
          class="card-wrapper"
          @click="$emit('show-more')"
        >
          <div style="display: flex; align-items: center; gap: 16px;">
            <span
              style="color: #5B5F7C;"
              class="font-bold"
            >Все товары</span>
            <ArrowRight style="position: relative; top: 2px;" />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { FreeMode } from 'swiper/modules'

import ArrowRight from '@/assets/icons/other/ArrowRight.vue'

import ShowcaseProductCard from '@/components/ShowcaseProductCard/ShowcaseProductCard.vue'

import 'swiper/css'
import 'swiper/css/free-mode'
import { useDevice } from '@/composables/useDevice';

const { isMobileDevice } = useDevice();

defineProps({
  accountId: {
    type: [Number, String],
    default: null,
  },
  items: {
    type: Array,
    default: () => {},
  }
})

defineEmits(['show-more']);

</script>

<style lang="scss" scoped>
.swiper-wrapper {
  display: flex;
  width: calc(var(--desktop-pane-width) - 40px);
  &.is-desktop {
    width: calc(var(--desktop-pane-width) - 40px);
  }

}

.swiper-container {
  width: calc(100% + 40px);
  position: relative;
  overflow: visible;
  left: -20px;
  padding-left: 20px;
}

:deep(.swiper-slide) {
  width: 50% !important;
  // width: 160px;
}

.card-wrapper {
  border: 1px solid var(--defaultInputBorder);
  background-color: #ffffff;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
}
</style>
