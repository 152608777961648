export const currenciesList = [
  { name: 'Российский рубль', id: 'R_RUB', short: '₽' },
  { name: 'Белорусский рубль', id: 'B_RUB', short: 'Br' },
]

export const languages = [
  { id: 'ru', name: 'Русский' },
  { id: 'en', name: 'English' },
  { id: 'zh', name: '華語' },
  { id: 'ar', name: 'عربي' },
]
