<template>
  <HeaderFullpage :title="t('menu.favorites')" />

  <LayoutBase gap="30">
    <div
      v-if="favorites.length === 0 && !isLoading"
      class="info"
    >
      {{ t('favorites.null') }}
    </div>
    <TabsBase
      v-model="favoriteType"
      :options="favoriteOptions"
    />

    <PageLoader v-if="isLoading" />

    <template v-else>
      <div class="pet-list">
        <router-link
          v-for="item in favorites "
          :key="item.id"
          :to="(favoriteType === 1 ? '/order/' : '/account/') + item.id"
          class="pet"
        >
          <IconBase
            :path="`category/${(item.payload?.categoryId || 1)}.svg`"
            class=" pet__img"
          />
          <div class="body">
            <div class="title">
              {{ item.payload.name }}
            </div>
            <div class="subtitle">
              {{ item.payload.address }}
            </div>
          </div>
          <img
            src="@/assets/icons/other/context.svg"
            class="context"
            @click.prevent="openContext({ id: item.id, type: favoriteType })"
          >
        </router-link>
      </div>
    </template>
  </LayoutBase>

  <FavoritesContext
    ref="FavoritesContextREF"
    @on-delete="onDelete"
  />
</template>

<script setup>
import { useStorage } from '@vueuse/core'
import { HeaderFullpage, TabsBase, IconBase } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { onMounted, ref, watch, } from 'vue';
import { PageLoader } from '@/components/PageLoader'
import { fetchFavorites, deleteFromFavorites } from './api'
import { FavoritesContext } from './components/FavoritesContext'
import { useI18n } from 'vue-i18n'
import { useConfirm } from '@/composables/useConfirm'

const { t } = useI18n()

const favoriteType = useStorage('favoriteType', 0)
const favorites = ref([])
const FavoritesContextREF = ref()

const favoriteOptions = [
  {
    value: 0,
    label: t('favorites.accounts')
  },
  {
    value: 1,
    label: t('favorites.orders')
  },
]

const isLoading = ref(true)

const init = async () => {
  isLoading.value = true

  const { favourites } = await fetchFavorites({ offer: favoriteType.value })
  console.log(favourites)
  favorites.value = favourites

  isLoading.value = false
}

const openContext = ({ type, id }) => {
  FavoritesContextREF.value.open({ type, id })
}

const { onConfirm } = useConfirm();
const onDelete = async ({ id }) => {
  const response = await onConfirm({ title: t('favorites.toast.removeConfirm') })
  if (response) {
    const { success } = await deleteFromFavorites({ id })
    if (!success) {return}

    favorites.value = favorites.value.filter(el => el.id !== id)
  }
}

watch(favoriteType, () => {
  init()
})

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .pet {

        background: #37363C;
    }

    .pet__img {
        background: #292929;
    }

    .title {
        color: #fff;
    }

    .subtitle {
        color: #B0B2C2;
    }
}

.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.pet-list {
    display: grid;
    gap: 10px;
}

.info {
    color: #C6C8DA;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}

.pet {
    &__img {
        padding: 10px;
        border-radius: 20px;
        background: #F2F8FF;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
    }

    border-radius: 26px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 10px;
}

.title {
    color: #5B5F7C;
    font-size: 15px;
    font-weight: 700;
    line-height: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subtitle {
    color: #8E92AF;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.body {
    display: grid;
    gap: 8px;
}

.time {
    color: #6B7090;
    font-size: 14px;
    font-weight: 600;
    align-self: start;
    text-align: end;
}
</style>
