<template>
  <HeaderFullpage :title="computedData?.name" />
  <PageLoader v-if="!loaded" />
  <LayoutBase
    v-else
    gap="40"
  >
    <services-form
      :handler="update"
      :predefined-data="computedData"
      :submit-btn-text="t('common.save')"
      type="update"
    >
      <template #actions>
        <DeleteRecordBtn
          class="showcases__form__btn"
          @confirm="handleSuccessDelete"
        />
      </template>
    </services-form>

    <SuccessModal
      ref="successModal"
      hide-new-event
      @close="router.back()"
    />
  </LayoutBase>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { HeaderFullpage } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { PageLoader } from '@/components/PageLoader'
import { useRouter, useRoute } from 'vue-router'
import ServicesForm from '../../workspace/ServicesForm.vue'
import { useUserStore } from '@/app/store/user.store'
import { uploadPhoto } from '@/states/api'
import showcasesApi from '@/screens/Account/api/showcases.api'
import SuccessModal from '../../workspace/SuccessModal.vue'
import DeleteRecordBtn from '@/components/DeleteRecordBtn/DeleteRecordBtn.vue'

import { useI18n } from 'vue-i18n'
import { errorToast, successToast } from '@/helpers/showToast'
const { t } = useI18n()
const userStore = useUserStore()

const route = useRoute()
const router = useRouter()
const loaded = ref(false)
const successModal = ref()

const computedData = computed(() => userStore.getShowcaseItemById(route.params.serviceId))

async function update(payload) {
  if (payload.files.length) {
    const oldFiles = payload.files.filter((el) => typeof el === 'string')
    const newFiles = payload.files.filter((el) => typeof el !== 'string')
    const { paths } = await uploadPhoto({ photos: newFiles })
    payload.files = [...oldFiles, ...paths.map((el) => el.path)]
  }

  showcasesApi
    .update(route.params.id, {
      ...payload,
      id: +route.params.serviceId,
    })
    .then((res) => {
      if (res.data.success) {
        successModal.value.openModal(t('showcase.services.editedSuccess'))
      } else {
        errorToast({
          description: t('toast.somethingWrongLong'),
        })
      }
    })
    .catch(() => {
      errorToast({
        description: t('toast.somethingWrongLong'),
      })
    })
}

const handleSuccessDelete = async () => {
  const response = await showcasesApi.delete(Number(route.params.serviceId));

  const isSuccess = response.data.success
  if (isSuccess) {
    successToast({
      description: t('showcase.services.deletedSuccess'),
    })
    router.back()
  } else {
    errorToast({ description: t('toast.somethingWrongLong') })
  }
}

onMounted(() => {
  userStore.getAllShowcases(route.params.id).then(() => {
    loaded.value = true
  })
})
</script>

<style lang="scss" src="../../assets/styles.scss"></style>
