import { api } from "@/app/api"

export const deligateToAccount = async ({ id, phoneNumber }) => {

  const { data } = await api.post(`/v1/delegates`,{
    "account_id": id,
    "phone": phoneNumber
  })
  return data
}
