<template>
  <div class="auth__container">
    <div class="auth__subtitle" style="margin: 100px 0 0;">
      {{ t('support.isAuthProblem') }}
    </div>
    <button-base
      variant="text"
      class="enter-otp-confirmation__step__resend"
      @click="onOpenSupport"
    >
      {{ t('support.contactSupport') }}
    </button-base>
  </div>

  <PanePromise
    id="supportView"
    ref="supportRef"
    fit-content
  >
    <LayoutBase
      top-gap
      padding="20px"
      :title="t('support.call')"
    >
      <div class="ambulance">
        <div style="display: grid; gap: 6px;">
          <InputBase
            v-model="subject"
            :label="t('support.ph.subject')"
            style="margin-bottom: 5px;"
          />
          <TextareaBase
            v-model="text"
            :label="t('support.ph.desc')"
            :rows="4"
            style="margin-bottom: 10px;"
          />
        </div>

        <ButtonBase @click="onSubmit">
          {{ t('support.send') }}
        </ButtonBase>
      </div>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { ref, computed } from 'vue';
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ButtonBase, InputBase, TextareaBase } from '@/UI'
import { sendMessageToSupport } from '../api';
import { useAuthStore } from '../store'

const supportRef = ref()

const onOpenSupport = () => {
  supportRef.value.open()
}

const authStore = useAuthStore()

const subject = ref('');
const text = ref('');
const phone = computed(() => authStore.phoneNumber);

const onSubmit = () => {
  sendMessageToSupport({
    subject: subject.value,
    text: text.value,
    phone: phone.value,
  })
}
</script>
