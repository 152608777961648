import { reactive, toRefs } from 'vue'
import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import {
  fetchCategory,
  fetchAnimalTypes,
  fetchServices,
  fetchProps ,
  fetchVolumes,
  fetchCurrencies,
} from './api'

import { getUser } from './api'
import { useDark } from '@vueuse/core'

import { useUser } from "@/composables/useUser";
import { updateLanguage } from '@/plugins/localization';

export const useMainEntities = defineStore('mainEntities', () => {
  const { t, te } = useI18n()
  const isDark = useDark({
    selector: 'body',
    attribute: 'theme',
    valueDark: 'dark',
    valueLight: 'light',
  })

  isDark.value = false
  const { isAuthenticated } = useUser();

  const entities = reactive({
    category: null,
    animalTypes: null,
    services: null,

    props: null,
    volumes: null,
    currencies: null,
  })

  const allFetchers = {
    initTheme: async () => {
      if (isAuthenticated.value) {
        const { user } = await getUser()
        updateLanguage(user.language || 'ru')
        isDark.value = user.dark
      }
    },
    initServices: async () => {
      const result = await fetchServices({ t, te })
      entities.services = result.types
    },
    initCategory: async () => {
      const result = await fetchCategory({ t, te })
      entities.category = result.categories
    },
    initAnimalTypes: async () => {
      const result = await fetchAnimalTypes({ t, te })
      entities.animalTypes = result.animals
    },
    initProps: async () => {
      const result = await fetchProps({ t, te })
      entities.props = result.props
    },
    initVolumes: async () => {
      const volumes = await fetchVolumes()
      const result = volumes.map(el => {
        const isExists = te(`volume.${el.name}`)
        if (isExists) {
          const title = t(`volume.${el.name}`);
          return { ...el, title }
        }

        return { ...el, title: el.name }
      })
      entities.volumes = result
    },
    initCurrencies: async () => {
      const currencies = await fetchCurrencies()
      const result = currencies.map(el => {
        const isExists = te(`currency.${el.name}`)
        if (isExists) {
          const title = t(`currency.${el.name}`);
          return { ...el, title }
        }

        return { ...el, title: el.name }
      })
      entities.currencies = result
    },
  }

  ////////////////////////////////////////
  async function initMainEntities() {
    await Promise.all(Object.values(allFetchers).map(el => el()))
  }

  const getVolumeById = (id) => entities.volumes.find(el => el.id === id);
  const getCurrencyById = (id) => entities.currencies.find(el => el.id === id);

  return {
    initMainEntities,
    ...toRefs(entities),
    ...allFetchers,
    isDark,
    getVolumeById,
    getCurrencyById,
  }

})
