import router from '@/router';
import { useStore } from '@/states/index'

import { useDemoMode } from '@/composables/useDemoMode';

export const processNonAuthError = async () => {
  const store = useStore()
  const { onOpenDemoModePane } = useDemoMode();
  if (store.isDemoMode) {
    const needsToBeAuthenticated = await onOpenDemoModePane()
    if (needsToBeAuthenticated) {
      store.$patch({ isDemoMode: false });
      router.push('/')
    }
  } else {
    store.$patch({ authToken: null });
    router.push('/')
  }
}

export const logout = async () => {
  const store = useStore()

  store.$patch({ authToken: null, isDemoMode: false });
  router.push('/')
}
