export function fromDateToAge(n) {
  n = n.toString()
  if (n.includes('.')) {
    const age = n.split('.')[2]
    n = (new Date()).getFullYear() - age
  }

  try {
    const arr = ['год', 'года', 'лет']
    return `${n } ${ arr[(n % 100 > 4 && n % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(n % 10 < 5) ? n % 10 : 5]]}`;
  } catch (e) {
    return ''
  }
}
