<template>
  <div class="input__wrapper">
    <input
      v-bind="$attrs"
      ref="inputRef"
      v-maska="maskOptions"
      class="input"
      :maxlength="props.maxLength"
      :value="props.modelValue"
      :class="{
        disable: props.disable,
        error: props.isError,
      }"
      @input="input"
    >

    <transition name="input__error-text">
      <p
        v-if="props.errors.length"
        class="input__error-text"
      >
        {{ props.errors[0].$message }}
      </p>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  disable: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: 150,
  },
  errorMessage: {
    type: String,
    default: '',
  },
  isError: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array,
    required: false,
    default: () => [],
  },
  maskOptions: {
    type: Object,
    default: () => {},
  },
})
const emit = defineEmits(['update:modelValue'])
const inputRef = ref(null)

const input = ({ target }) => {
  emit('update:modelValue', target.value)
}
</script>

<style scoped lang="scss">
[theme='dark'] {
  .input {
    background: #37363c;
    border: 1px solid #37363c;
    color: #fff;
  }
}

.input {
  padding: 20px;
  border: 1px solid #e8eafc;
  background: #fff;
  font-size: 16px;
  border-radius: 20px;
  transition: border 0.3s;

  &:focus {
    border: 1px solid #5b43ef;
  }

  &__wrapper {
    position: relative;
    color: #6b7090;
    font-size: 15px;
    font-weight: 600;
    // TODO проверить это. Почти во всех местах это правило лишнее
    // margin-bottom: 25px;

    .input__error-text {
      position: absolute;
      left: 8px;
      top: calc(100% + 5px);
      color: #ff5858;
      font-size: 12px;
      transition: all 0.3s;
      margin: 0;

      &-enter-active,
      &-leave-active {
        transition: opacity 0.5s ease;
      }

      &-enter-from,
      &-leave-to {
        opacity: 0;
      }
    }
  }

  &.error {
    border: 1px solid rgb(204, 8, 8);
  }

  &.disable {
    pointer-events: none;
  }
}
</style>
