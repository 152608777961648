<template>
  <RouterLink
    draggable="false"
    :to="to"
  >
    <div class="menu-item">
      <span class="title">{{ title }}</span>
      <img :src="imagePath">
    </div>
  </RouterLink>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  to: {
    type: String,
    default: '/',
  },
  title: {
    type: String,
    default: '',
  },
  image: {
    type: String,
    default: '',
  },
  background: {
    type: String,
    default: '#ffffff',
  },
})

const imagePath = computed(() => new URL(`../../assets/images/${props.image}.svg`, import.meta.url).href)
</script>

<style lang="scss" scoped>
[theme="dark"] {
  .menu-item {
    background: #37363C;
    .title {
      color: #ffffff;
    }
  }
}

.menu-item {
  background: v-bind(background);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  position: relative;
  height: 84px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;

  @media (max-height: 665px) {
    height: 70px;
  }

  img {
    position: absolute;
    right: 0;
    bottom: 0;

  }

  .title {
    color: #232638;
    @include font-bold;
    font-size: 14px;
    padding: 17px 15px 15px;
    position: relative;
    z-index: 5;
  }
}
</style>
