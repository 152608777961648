<template>
  <HeaderFullpage :title="computedData?.name" />
  <PageLoader v-if="!loaded" />
  <LayoutBase
    v-else
    gap="40"
  >
    <PricesForm
      :handler="updatePrice"
      :predefined-data="computedData"
      type="update"
    >
      <template #actions>
        <DeleteRecordBtn
          class="showcases__form__btn"
          @confirm="handleSuccessDelete"
        />
      </template>
    </PricesForm>

    <SuccessModal
      ref="successModal"
      hide-new-event
      @close="router.back()"
    />
  </LayoutBase>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { HeaderFullpage } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { PageLoader } from '@/components/PageLoader'
import { useRouter, useRoute } from 'vue-router'

import DeleteRecordBtn from '@/components/DeleteRecordBtn/DeleteRecordBtn.vue'
import PricesForm from '../../workspace/PricesForm.vue'
import { useUserStore } from '@/app/store/user.store'
import showcasesApi from '@/screens/Account/api/showcases.api'
import SuccessModal from '../../workspace/SuccessModal.vue'

import { useI18n } from 'vue-i18n'
import { errorToast, successToast } from '@/helpers/showToast'
const { t } = useI18n()
const userStore = useUserStore()

const route = useRoute()
const router = useRouter()
const loaded = ref(false)
const successModal = ref()

const computedData = computed(() => userStore.getShowcaseItemById(Number(route.params.serviceId)))

const updatePrice = async (payload) => {
  const response = await showcasesApi.update(route.params.id, {
    ...payload,
    id: Number(route.params.serviceId),
  })

  const isSuccess = response.data.success
  if (!isSuccess) {
    errorToast({ description: t('toast.somethingWrongLong') })
  }
  if (isSuccess) {
    successModal.value.openModal(t('showcase.prices.updateSuccess'))
  }
}

const handleSuccessDelete = async () => {
  const response = await showcasesApi.delete(Number(route.params.serviceId));

  const isSuccess = response.data.success
  if (isSuccess) {
    successToast({
      description: t('showcase.prices.deletedSuccess'),
    })
    router.back()
  } else {
    errorToast({ description: t('toast.somethingWrongLong') })
  }
}

onMounted(() => {
  userStore.getAllShowcases(route.params.id).then(() => {
    loaded.value = true
  })
})
</script>

<style lang="scss" src="../../assets/styles.scss"></style>
