import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

export const getDevice = async () => {
  const info = await Device.getInfo();
  const { identifier } = await Device.getId();
  const uuid = localStorage.getItem('_capuid') || identifier

  const response = { ...info, uuid, nativePlatform: 'capacitor' };

  const isAvailable = Capacitor.isPluginAvailable('App');

  if (isAvailable) {
    try {
      const app = await App.getInfo();
      return { ...response, ...app }
    } catch (error) {
      console.warn('App module is not found')
    }
  }

  return response
};
