import { api } from "@/app/api"

export const getAccounts = async () => {
  try {
    const { data } = (await api.get('/v2/accounts'))
    return data
  } catch (error) {

    console.error(error)
  }
}

export const deleteAccount = async ({ id }) => {
  try {
    const { data } = (await api.delete('/v1/accounts', {
      data: {
        id,
      }
    }))
    return data
  } catch (error) {

    console.error(error)
  }
}
